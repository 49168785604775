var admin_email = "oanapopescu93@gmail.com"
var word_bank = {
    salon: "Salon",
    about: "About",
    questions: "Questions",
    terms_cond: "Terms and conditions",
    policy_privacy: "Policy privacy",
    contact: "Contact",
    career: "Career",
    info: "Info",
    back: "Back",
    bet_type: "Bet type",
    and: "and",
    or: "or",
    i_agree_to: "I agree to",

    // games
    games: "Games",
    roulette: "Roulette",
    blackjack: "Blackjack",
    slots: "Slots",
    craps: "Craps",
    race: "Race",
    keno: "Keno",
    poker: "Poker",
    european: "European",
    american: "American",
    reel_3: "3 Reels",
    reel_5: "5 Reels",
    poker_texas_holdem: "Texas hold'em",
    poker_5_card_draw: "5 Card Draw",

    error: "Error",
    all_rights_reserved: "All rights reserved",   
    no_career: "No jobs available. But feel free to send us your CV.",

    cookies_modal_title: "Cookies Notification",
    cookies_modal_text: "In order to offer you the most relevant information and for optimal system performance, we use cookies that collect information from your game activity.",

    sign_in: "Sign in",
    sign_up: "Sign up",
    signin_error: "The user doesn't exist",
    signup_error: "There has been an error signing you up. Please email this error to " + admin_email + " or try again later.",
    email_yes_user_no_error: "This email has already been used",
    email_yes_user_yes_error: "The user already exists",
    user: "User",
    password: "Password",
    sign_in_email_empty: "Email is empty",
    sign_in_user_empty: "User is empty",
    sign_in_pass_empty: "Password is empty",
    sign_in_email_error: "Email is incorrect",
    sign_in_pass_error: "Password is incorrect",
    signin_forgot_password: "I forgot password",
    subtitle: "Dare catch the rabbit",
    country: "Country",
    city: "City",
    your_streak: "How many days in a row you have played",

    exit_salon: "Exit salon",
    salon_subtitle: "Welcome to the salon",
    exit_game: "Exit game",

    donation: "Donations",
    crypto: "Crypto",
    paypal: "Paypal",
    bank: "Bank",
    crypto_donation_title: "Donate via Cryptocurrency",
    crypto_donation_text: "Please send your cryptocurrency donation to the following addresses. Once the donation is sent, it may take some time to confirm on the blockchain network.",
    paypal_donation_title: "Donate via Paypal",
    paypal_donation_text: "Please use the following links to make a donation via PayPal. Once the donation is completed, it may take some time to process.",
    bank_donation_title: "Donate via Bank Transfer",
    bank_donation_text: "Please send your donation to the following bank account. Once the donation is transferred, it may take some time to reflect in the account.",
    donation_footer_text: "Thank you for your generosity!",
    donation_ukraine: "Donation for Ukraine",
    donate_with_nowpayments: "Donate with NOWPayments",

    all: "All",
    other: "Other",
    title: "Title",
    location: "Location",
    requirements: "Requirements",
    responsabilities: "Responsabilities",  
    marketing: "Marketing",   

    under_construction: "Under construction",

    no_data: "No info",
    no_bets: "You didn't place any bets",
    no_user: "No user was found",
    no_money: "You don't have enough carrots. You can buy them from the market.",
    no_payment_methods: "No payment methods yet",
    no_cart: "Cart is empty",
    no_history: "No history",
    no_jobs: "No jobs available yet. But feel free to send us your CV.",
    no_participation_today: "No participation today",
    no_order: "No orders",
    no_selections: "Select at least a Keno Spot to start the game",
    no_match: "No match",
    keno_too_many_selected: "Too many spots selected",

    isMinor_title: "Age verification",
    isMinor_text: "Are you over 18?",
    yes: "Yes",
    no: "No",
    isMinor_sign: "You can't enter because you are underage.",
    
    forgot_password_title: "Forgot your password",
    forgot_password_text: "Please enter the email address used to register. We will send you your new password to that address.",
    send: "Send",
    email_send_mailtrap: "Email has been sent. Check your Mailtrap account.",
    email_send: "Email has been sent.",
    email_no_send: "Email has not been sent.",
    subject: "Subject",
    message: "Message",
    sending: "Sending...",

    incorrect_email: "The email is incorrect",
    empty_input_subject: "The subject input is empty",
    empty_input_about: "The about input is empty",
    empty_input_message: "The message input is empty",
    empty_input_agree: "You didn't agree with the Terms and contitions and the Policy privacy",
    account_issues: "Account issues", 
    payments_and_withdrawals: "Payments and withdrawals",     
    game_issues: "Game issues", 
    hiring: "Hiring", 

    market: "Market",
    settings: "Settings",
    how_to_play: "How to play",
    logout: "Logout",
    joined_the_chat: "joined the chat",
    left_the_chat: "left the chat",
    buy: "Buy",
    play: "Play",
    play_whack_a_rabbit: "Play Whack a rabbit to get free carrots!",

    language: "Language",
    date_calendar: "Date",
    currency: "Currency", 
    theme: "Theme",
    red: "Red",
    orange: "Orange",
    yellow: "Yellow",
    green: "Green",
    blue: "Blue",
    brown: "Brown",
    purple: "Purple",
    black: "Black",
    grey: "Grey",
    white: "White",
    
    dashboard: "Dashboard",
    choose_profile_pic: "Choose profile pic",
    animal: "Animal",
    carrots: "Carrots",
    change_username: "Change username",
    change_password: "Change password",
    buy_carrots: "Buy carrots",
    choose: "Choose",
    change: "Change",
    profile_grey: "To have this pic you need to have more than 1000 carrots",
    empty_input_change_username: "The username can't be empty",
    password_rule01: "At least one uppercase English letter",
    password_rule02: "At least one lowercase English letter",
    password_rule03: "At least one digit",
    password_rule04: "At least one special character",
    password_rule05: "Minimum eight characters in length",

    //cart
    value: "Value",
    price: "Price",
    qty: "Quantity",
    total_price: "Total price",
    cart: "Cart",
    cart_empty: "Cart is empty",
    order_summary: "Order summary",
    subtotal: "Subtotal",
    shipping: "Shipping",
    promo_code: "Discount code",
    promo_discount: "Discount",
    total: "Total",
    checkout: "Checkout",
    apply_coupon: "Apply coupon",
    remove_all: "Remove all",
    continue_shopping: "Continue shopping",
    coupon_not_valid: "Your coupon is not valid",

    //checkout"
    customer_info: "Customer info",
    name: "Name",
    email: "Email",
    phone: "Phone",
    payment_info: "Payment info",
    cash_on_delivery: "cash on delivery",
    pay_card: "Credit or Debit card",
    pay_paypal: "Paypal",
    pay_crypto: "Crypto",
    card_number: "Card number",
    month: "Month",
    year: "Year",  
    cvv: "CVV",
    bitcoin_wallet: "Bitcoin Address",
    error_charge: "We are sorry, but we can't charge you due to an error on our behalf. We will repair this error as soon as possible.",
    amount_too_low: "The amount is too low.",
    createSource_error: "Failed to attach the card to the customer.",
    addNewCard_error: "Failed to create a card token.", 
    createNewCustomer_error: "Failed to create a new customer.",
    submit: "Submit",
    pay: "Pay",
    min_amount: "Minimum amount",
    fiat_equivalent: "Fiat equivalent",
    your_amount_in_fiat_equivalent: "Your amount in fiat equivalent",
    amount_too_small_transaction: "The amount is too small to make a transaction",
    payment_details: "Payment_details",
    payment_methode: "Payment methode",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    fill_field: "Please fill out this field",
    validate_message_name: "This is not a name",
    validate_message_email: "This is not a valid email",
    validate_message_phone: "This is not a valid phone number",
    validate_message_country: "This is not a valid country",
    validate_message_city: "This is not a valid city",
    validate_message_cardNumber: "This is not a valid card number",
    validate_message_month: "This is not a valid month",
    validate_message_year: "This is not a valid year",
    validate_message_cvv: "This is not a valid CVV",
    validate_message_bitcoinAddress: "This is not a valid Bitcoin address",
    validate_message_iban: "This is not an IBAN",
    continue: "Continue",
    save: "Save",
    payment_success: "Payment successful",
    payment_success_text: "You have completed your payment",
    payment_cancel: "Payment canceled",
    payment_cancel_text: "You have canceled your payment",
    order_info: "Order Info",
    payment_id: "Payment ID",
    date: "Date",
    description: "Description",
    customer_id: "Customer ID",
    items: "Items",
    amount: "Amount",
    requires_payment_method: "Requires Payment Method",
    requires_confirmation: "Requires Confirmation",
    requires_action: "Requires Action",
    processing: "Processing",
    requires_capture: "Requires Capture",
    canceled: "Canceled",
    succeeded: "Succeeded",

    // order
    order: "Order",
    orders: "Orders",
    order_message: "Your order was made successfully!",
    order_details: "Order details",
    customer: "Customer",
    payment_method: "Payment method",
    status: "Status",
    status_fullfilled: "Fulfilled",
    status_unfullfilled: "Unfulfilled",
    order_link: "Link receipt",
    order_description: "Order description",
    order_date: "Order date",
    postal_code: "Postal code",
    id: "ID",
    method: "Method",

    // withdraw
    withdrawal: "Withdrawal",
    withdrawals: "Withdrawals",
    withdraw: "Withdraw",    
    no_withdrawal: "No withdrawals",
    not_enough_money_withdrawal: "You don't have enough money to make a withdrawal.",
    withdraw_instructions: "Withdraw instructions will come here",
    withdraw_success: "Withdraw success",
    withdraw_failed: "Withdraw failed",

    // blackjack
    start: "Start",
    hit: "Hit",
    stand: "Stand",
    double_down: "Double down",
    surrender: "Surrender",
    not_current_player: "It is not your turn",
    hit_explanation: "Take another card to increase your hand.",
    stand_explanation: "Keep your current hand and end your turn.",
    double_down_explanation: "Double your bet, receive one more card.",
    surrender_explanation: "Forfeit your bet to end your hand.",
    table_1: "Table 01",
    table_2: "Table 02",
    table_3: "Table 03",
    table_4: "Table 04",
    table_5: "Table 05",
    table_6: "Table 06",
    table_7: "Table 07",
    table_8: "Table 08",

    // race
    breed: "Breed",
    delay: "Delay",
    health: "Health",
    bet: "Bet",
    place: "Place",
    place_01: "1st place",
    place_02: "2nd place",
    place_03: "3rd place",

    // Keno
    keno_instructions: "Pick up to 10 Keno Spots or choose Quick Pick (it will randomly choose max. 10 Keno Spots for you)",
    price_per_game: "Price per game",
    no_of_games: "No. of games",
    quick_pick: "Quick pick",
    keno_prizes: "Keno Prizes",
    keno_numbers_played: "How many no. played", 
    keno_numbers_matched: "How many no. guessed",
    keno_win: "Win",
    your_numbers: "Your numbers",
    lucky_numbers: "Lucky numbers", 

    // slots
    slots_prizes: "Slot prizes",

    // poker
    total_pot: "Total Pot",
    check: "Check",
    check_explanation : "Make no bet, stay in round.",
    fold: "Fold",
    fold_explanation : "Give up your hand now.",
    call: "Call",
    call_explanation : "Match the current highest bet.",
    raise: "Raise",
    raise_explanation : "Explanation raise here",
    showdown: "Showdown",
    showdown_explanation : "Reveal hands to determine winner.",
    draw: "Draw",
    draw_explanation : "Exchange cards for new ones.",
    cancel: "Cancel",
    cancel_explanation : "You don't want to exchange cards.",
    bet_explanation : "Place an initial wager amount.",
    invalid_raise: "Invalid raise",
    not_enough_money: "Not enough money",

    // results
    results: "Results",
    result: "Result",
    game: "Game",
    game_type: "Game type",
    you_win: "You won",
    you_lose: "You lost",

    welcome: "Welcome",
    welcome_gift: "Welcome gift",
    welcome_text: "Free 100 carrots!",
    prize: "Prize",
    prizes: "Prizes",
    our_casino: "our Casino",

    //401
    page_not_found_text: "Sorry, the page you are looking for does not exist. It might have been moved or deleted, or you might have typed the URL incorrectly.",

    // ChatBot
    anonymous: "Anonymous",
    greetings01: "Hello",
    greetings02: "I am here to respond to all your questions regarding BunnyBet.",
    error_chatbot: "I don't understand your question.",
}

export const wordsEng = (info)=>{
    return word_bank[info]
}