var admin_email = "oanapopescu93@gmail.com"
var word_bank = {
    salon: "Salon",
    about: "À propos",
    questions: "Questions",
    terms_cond: "Termes et conditions",
    policy_privacy: "Politique de confidentialité",
    contact: "Contact",
    career: "Carrière",
    info: "Info",
    back: "Retour",
    bet_type: "Type de pari",
    and: "et",
    or: "ou",
    i_agree_to: "Je suis d'accord avec",

    // games
    games: "Jeux",
    roulette: "Roulette",
    blackjack: "Blackjack",
    slots: "Slots",
    craps: "Craps",
    race: "Course",
    keno: "Keno",
    poker: "Poker",
    european: "Européen",
    american: "Américain",
    reel_3: "3 Rouleaux",
    reel_5: "5 Rouleaux",
    poker_texas_holdem: "Texas hold'em",
    poker_5_card_draw: "5 Card Draw",

    error: "Erreur",
    all_rights_reserved: "Tous droits réservés",
    no_career: "Aucun poste disponible. Mais n'hésitez pas à nous envoyer votre CV.",

    cookies_modal_title: "Notification de cookies",
    cookies_modal_text: "Afin de vous offrir les informations les plus pertinentes et des performances système optimales, nous utilisons des cookies qui collectent des informations sur votre activité de jeu.",

    sign_in: "Se connecter",
    sign_up: "S'inscrire",
    signin_error: "L'utilisateur n'existe pas",
    signup_error: "Il y a eu une erreur lors de votre inscription. Veuillez envoyer cette erreur à " + admin_email + " ou réessayez plus tard.",
    email_yes_user_no_error: "Cet email a déjà été utilisé",
    email_yes_user_yes_error: "L'utilisateur existe déjà",
    user: "Utilisateur",
    password: "Mot de passe",
    sign_in_email_empty: "L'email est vide",
    sign_in_user_empty: "L'utilisateur est vide",
    sign_in_pass_empty: "Le mot de passe est vide",
    sign_in_email_error: "L'email est incorrect",
    sign_in_pass_error: "Le mot de passe est incorrect",
    signin_forgot_password: "J'ai oublié mon mot de passe",
    subtitle: "Osez attraper le lapin",
    country: "Pays",
    city: "Ville",
    your_streak: "Combien de jours d'affilée as-tu joué ",

    exit_salon: "Quitter le salon",
    salon_subtitle: "Bienvenue au salon",
    exit_game: "Quitter le jeu",

    donation: "Dons",
    crypto: "Crypto",
    paypal: "Paypal",
    bank: "Banque",
    crypto_donation_title: "Donner via Cryptomonnaie",
    crypto_donation_text: "Veuillez envoyer votre don en cryptomonnaie aux adresses suivantes. Une fois le don envoyé, il peut falloir un certain temps pour qu'il soit confirmé sur le réseau blockchain.",
    paypal_donation_title: "Donner via PayPal",
    paypal_donation_text: "Veuillez utiliser les liens suivants pour faire un don via PayPal. Une fois le don terminé, il peut falloir un certain temps pour le traiter.",
    bank_donation_title: "Donner via Virement Bancaire",
    bank_donation_text: "Veuillez envoyer votre don au compte bancaire suivant. Une fois le don transféré, il peut falloir un certain temps pour qu'il soit reflété sur le compte.",
    donation_footer_text: "Merci pour votre générosité!",
    donation_ukraine: "Don pour l'Ukraine",
    donate_with_nowpayments: "Faire un don avec NOWPayments",

    all: "Tous",
    other: "Autre",
    title: "Titre",
    location: "Emplacement",
    requirements: "Exigences",
    responsabilities: "Responsabilités",
    marketing: "Marketing", 

    under_construction: "En construction",

    no_data: "Aucune information",
    no_bets: "Vous n'avez pas placé de paris",
    no_user: "Aucun utilisateur trouvé",
    no_money: "Vous n'avez pas assez de carottes. Vous pouvez les acheter sur le marché.",
    no_payment_methods: "Aucun moyen de paiement disponible pour le moment",
    no_cart: "Le panier est vide",
    no_history: "Aucun historique",
    no_jobs: "Aucun poste disponible pour le moment. Mais n'hésitez pas à nous envoyer votre CV.",
    no_participation_today: "Aucune participation aujourd'hui",
    no_order: "Aucune commande",
    no_selections: "Sélectionnez au moins un spot Keno pour commencer le jeu",
    no_match: "Aucune correspondance",
    keno_too_many_selected: "Trop de spots sélectionnés",

    isMinor_title: "Vérification de l'âge",
    isMinor_text: "Êtes-vous majeur ?",
    yes: "Oui",
    no: "Non",
    isMinor_sign: "Vous ne pouvez pas entrer car vous êtes mineur.",

    forgot_password_title: "Mot de passe oublié",
    forgot_password_text: "Veuillez entrer l'adresse e-mail utilisée lors de l'inscription. Nous vous enverrons votre nouveau mot de passe à cette adresse.",
    send: "Envoyer",
    email_send_mailtrap: "L'e-mail a été envoyé. Vérifiez votre compte Mailtrap.",
    email_send: "L'e-mail a été envoyé.",
    email_no_send: "L'email n'a pas été envoyé.",
    subject: "Sujet",
    message: "Message",
    sending: "Envoi en cours...",

    incorrect_email: "L'email est incorrect",
    empty_input_subject: "Le champ du sujet est vide",
    empty_input_about: "Le champ à propos est vide",
    empty_input_message: "Le champ du message est vide",
    empty_input_agree: "Vous n'avez pas accepté les conditions générales d'utilisation et la politique de confidentialité",
    account_issues: "Problèmes de compte", 
    payments_and_withdrawals: "Paiements et retraits", 
    game_issues: "Problèmes de jeu", 
    hiring: "Recrutement", 

    market: "Marché",
    settings: "Paramètres",
    how_to_play: "Comment jouer",
    logout: "Déconnexion",
    joined_the_chat: "a rejoint la discussion",
    left_the_chat: "a quitté la discussion",
    buy: "Acheter",
    play: "Jouer",
    play_whack_a_rabbit: "Jouez à Whack a Rabbit pour obtenir des carottes gratuites !",

    language: "Langue",
    date_calendar: "Date",
    currency: "Devise",
    theme: "Thème",
    red: "Rouge",
    orange: "Orange",
    yellow: "Jaune",
    green: "Vert",
    blue: "Bleu",
    brown: "Marron",
    purple: "Violet",
    black: "Noir",
    grey: "Gris",
    white: "Blanc",

    dashboard: "Dashboard",
    choose_profile_pic: "Choisir une photo de profil",
    animal: "Animal",
    carrots: "Carottes",
    change_username: "Changer de nom d'utilisateur",
    change_password: "Changer de mot de passe",
    buy_carrots: "Acheter des carottes",
    choose: "Choisir",
    change: "Changer",
    pprofile_grey: "Pour avoir cette photo, vous devez avoir plus de 1000 carottes",
    empty_input_change_username: "Le nom d'utilisateur ne peut pas être vide",
    password_rule01: "Au moins une lettre majuscule",
    password_rule02: "Au moins une lettre minuscule",
    password_rule03: "Au moins un chiffre",
    password_rule04: "Au moins un caractère spécial",
    password_rule05: "Au moins huit caractères de long",

    //cart
    value: "Valeur",
    price: "Prix",
    qty: "Quantité",
    total_price: "Prix total",
    cart: "Panier",
    cart_empty: "Le panier est vide",
    order_summary: "Résumé de la commande",
    subtotal: "Sous-total",
    shipping: "Livraison",
    promo_code: "Code de réduction",
    promo_discount: "Réduction",
    total: "Total",
    checkout: "Paiement",
    apply_coupon: "Appliquer le coupon",
    remove_all: "Tout supprimer",
    continue_shopping: "Continuer les achats",
    coupon_not_valid: "Votre coupon n'est pas valide",

    //checkout"
    customer_info: "Informations client",
    name: "Nom",
    email: "Email",
    phone: "Téléphone",
    payment_info: "Informations de paiement",
    cash_on_delivery: "Paiement à la livraison",
    pay_card: "Carte de crédit ou de débit",
    pay_paypal: "Paypal",
    pay_crypto: "Crypto",
    card_number: "Numéro de carte",
    month: "Mois",
    year: "Année",
    cvv: "CVV",
    bitcoin_wallet: "Adresse Bitcoin",
    error_charge: "Nous sommes désolés, mais nous ne pouvons pas vous facturer en raison d'une erreur de notre part. Nous réparerons cette erreur dès que possible.",
    amount_too_low: "Le montant est trop bas.",
    createSource_error: "Échec de l'ajout de la carte au client.",
    addNewCard_error: "Échec de la création d'un jeton de carte.",
    createNewCustomer_error: "Échec de la création d'un nouveau client.",
    submit: "Envoyer",
    pay: "Paiement",
    min_amount: "Montant minimum",
    fiat_equivalent: "Équivalent en monnaie fiduciaire",
    your_amount_in_fiat_equivalent: "Votre montant en équivalent fiat",
    amount_too_small_transaction: "Le montant est trop faible pour effectuer une transaction",
    payment_details: "Détails du paiement",
    payment_methode: "Mode de paiement",
    january: "Janvier",
    february: "Février",
    march: "Mars",
    april: "Avril",
    may: "Mai",
    june: "Juin",
    july: "Juillet",
    august: "Août",
    september: "Septembre",
    october: "Octobre",
    november: "Novembre",
    december: "Décembre",
    fill_field: "Veuillez remplir ce champ",
    validate_message_name: "Ce n'est pas un nom",
    validate_message_email: "Ce n'est pas un email valide",
    validate_message_phone: "Ce n'est pas un numéro de téléphone valide",
    validate_message_country: "Ce n'est pas un pays valide",
    validate_message_city: "Ce n'est pas une ville valide",
    validate_message_cardNumber: "Ce n'est pas un numéro de carte valide",
    validate_message_month: "Ce n'est pas un mois valide",
    validate_message_year: "Ce n'est pas une année valide",
    validate_message_cvv: "Ce n'est pas un CVV valide",
    validate_message_bitcoinAddress: "Ce n'est pas une adresse Bitcoin valide",
    validate_message_iban: "Ce n'est pas un IBAN",
    continue: "Continuer",
    save: "Enregistrer",
    payment_success: "Paiement réussi",
    payment_success_text: "Vous avez terminé votre paiement",
    payment_cancel: "Paiement annulé",
    payment_cancel_text: "Vous avez annulé votre paiement",
    order_info: "Informations sur la commande",
    payment_id: "ID de paiement",
    date: "Date",
    description: "Description",
    customer_id: "ID client",
    items: "Articles",
    amount: "Montant",
    requires_payment_method: "Méthode de paiement requise",
    requires_confirmation: "Confirmation requise",
    requires_action: "Action requise",
    processing: "En cours",
    requires_capture: "Capture requise",
    canceled: "Annulé",
    succeeded: "Réussi",

    // order
    order: "Commande",
    orders: "Commandes",
    order_message: "Votre commande a été passée avec succès !",
    order_details: "Détails de la commande",
    customer: "Client",
    payment_method: "Méthode de paiement",
    status: "Statut",
    status_fullfilled: "Fulfillé",
    status_unfullfilled: "Non fulfillé",
    order_link: "Lien du reçu",
    order_description: "Description de la commande",
    order_date: "Date de commande",
    postal_code: "Code postal",
    id: "ID",
    method: "Méthode",

    // withdraw
    withdrawal: "Retrait",
    withdraw: "Retirer",
    withdrawals: "Retraits",
    no_withdrawal: "Pas de retraits",
    not_enough_money_withdrawal: "Vous n'avez pas assez d'argent pour effectuer un retrait.",
    withdraw_instructions: "Withdraw instructions will come here",
    withdraw_success: "Retrait réussi",
    withdraw_failed: "Échec du retrait",

    // blackjack
    start: "Démarrer",
    hit: "Tirer",
    stand: "Rester",
    double_down: "Doubler",
    surrender: "Abandonner",
    not_current_player: "Ce n'est pas votre tour",
    hit_explanation: "Prenez une autre carte pour augmenter votre main.",
    stand_explanation: "Gardez votre main actuelle et terminez votre tour.",
    double_down_explanation: "Doublez votre mise, recevez une carte supplémentaire.",
    surrender_explanation: "Abandonnez votre mise pour terminer votre main.",
    table_1: "Table 01", 
    table_2: "Table 02", 
    table_3: "Table 03", 
    table_4: "Table 04", 
    table_5: "Table 05", 
    table_6: "Table 06", 
    table_7: "Table 07", 
    table_8: "Table 08", 

    // race
    breed: "Race",
    delay: "Délai",
    health: "Santé",
    bet: "Pari",
    place: "Place",
    place_01: "1ère place",
    place_02: "2ème place",
    place_03: "3ème place",

    // Keno
    keno_instructions: "Choisissez jusqu'à 10 spots Keno ou choisissez le choix rapide (il choisira au hasard jusqu'à 10 spots Keno pour vous)",
    price_per_game: "Prix par partie",
    no_of_games: "Nombre de parties",
    quick_pick: "Choix rapide",
    keno_prizes: "Prix Keno",
    keno_numbers_played: "Combien de numéros joués",
    keno_numbers_matched: "Combien de numéros devinés",
    keno_win: "Gagnant",
    your_numbers: "Vos numéros",
    lucky_numbers: "Numéros chanceux",

    // slots
    slots_prizes: "Prix des machines à sous",

    // poker
    total_pot: "Pot total",
    check: "Suivre",
    check_explanation: "Ne pas miser, rester dans le tour.",
    fold: "Se coucher",
    fold_explanation: "Abandonner votre main maintenant.",
    call: "Suivre",
    call_explanation: "Suivre la mise la plus élevée actuelle.",
    raise: "Relancer",
    raise_explanation: "Augmenter la mise actuelle.",
    showdown: "Confrontation",
    showdown_explanation: "Révéler les mains pour déterminer le gagnant.",
    draw: "Échanger",
    draw_explanation: "Échanger des cartes contre de nouvelles.",
    cancel: "Annuler",
    cancel_explanation: "Vous ne voulez pas échanger de cartes.",
    bet_explanation: "Placer un montant de mise initial.",
    invalid_raise: "Relance invalide",
    not_enough_money: "Pas assez d'argent",

    // results
    results: "Résultats",
    result: "Résultat",
    game: "Jeu",
    game_type: "Type de jeu",
    you_win: "Vous avez gagné",
    you_lose: "Vous avez perdu",

    welcome: "Bienvenue",
    welcome_gift: "Cadeau de bienvenue",
    welcome_text: "100 carottes gratuites!",
    prize: "Prix",
    prizes: "Prix",
    our_casino: "notre casino",
    
    //401
    page_not_found_text: "Désolé, la page que vous recherchez n'existe pas. Elle a peut-être été déplacée ou supprimée, ou vous avez peut-être saisi incorrectement l'URL.",

    // ChatBot
    anonymous: "Anonyme",
    greetings01: "Bonjour",
    greetings02: "Je suis ici pour répondre à toutes vos questions concernant BunnyBet.",
    error_chatbot: "Je ne comprends pas votre question.",
}

export const wordsFr = (info)=>{
    return word_bank[info]
}