var info = {
    casino_name: "BunnyBet",
    email: "oanapopescu93@gmail.com",
    phone_text: "(+40) 0729.699.148",
    phone: "+400729699148",
}
let array = [
    // General Information
{
    "type": 1, 
    "queries": ["什么是" + info.casino_name + "?", "告诉我关于" + info.casino_name + "的事", "什么是" + info.casino_name + "的目的?"],
    "responses": [
        info.casino_name + " 是由爱好者创建的独特在线赌场体验，专注于提供一个优质的游戏环境，没有商业压力。",
        "在" + info.casino_name + "，我们的目标是提供一个有趣的、非商业化的游戏体验，由对赌场游戏的热情和喜爱驱动。",
        "我们创建" + info.casino_name + "是为了分享我们对游戏的热情，并提供一个独特的平台，让玩家在没有商业压力的情况下享受游戏。"
    ]
},
{
    "type": 1, 
    "queries": ["" + info.casino_name + "是如何开始的?", "关于" + info.casino_name + "的历史是什么?", "如何创建了" + info.casino_name + "?"],
    "responses": [
        info.casino_name + " 起初是一个个人项目，受到我们对赌场游戏的热爱启发，旨在创建一个没有商业利益的空间。",
        "我们与" + info.casino_name + "的旅程开始于一个爱好项目。我们想要创建一个玩家可以在没有商业压力的情况下享受游戏的地方。",
        "创建" + info.casino_name + "的动力来自于我们对赌场游戏的热情。这是一个激情项目，旨在提供卓越的游戏体验。"
    ]
},
{
    "type": 1, 
    "queries": ["可以玩哪些游戏?", "我可以玩哪些类型的赌场游戏?", "列出" + info.casino_name + "提供的游戏"],
    "responses": [
        info.casino_name + " 提供一系列游戏，包括轮盘、二十一点、老虎机、掷骰子、扑克、赛马和肯诺。",
        "你可以在" + info.casino_name + "玩各种游戏，如轮盘、二十一点、老虎机、掷骰子、扑克、赛马和肯诺。",
        "在" + info.casino_name + "提供的游戏包括轮盘、二十一点、老虎机、掷骰子、扑克、赛马和肯诺。"
    ]
},
{
    "type": 1, 
    "queries": ["我可以免费玩游戏吗?", "是否有免费的游戏?", "我需要付费才能玩游戏吗?"],
    "responses": [
        "是的，所有在" + info.casino_name + "上的游戏都可以免费玩。",
        "你可以在" + info.casino_name + "享受所有游戏而无需任何费用。",
        "在" + info.casino_name + "，你可以免费玩游戏。没有花钱的要求。"
    ]
},
{
    "type": 1, 
    "queries": ["" + info.casino_name + "是否适合手机使用?", "我可以在手机上访问" + info.casino_name + "吗?", "有手机版本的" + info.casino_name + "吗?"],
    "responses": [
        "是的，" + info.casino_name + "已经优化了移动设备。你可以在智能手机或平板电脑上玩。",
        "我们的网站是移动友好的，你可以在手机或平板电脑上享受游戏。",
        "你可以从移动设备访问" + info.casino_name + "，在智能手机和平板电脑上无缝玩游戏。"
    ]
},
{
    "type": 1, 
    "queries": ["你们接受哪些货币?", "在" + info.casino_name + "上使用什么货币?", "我可以使用不同的货币吗?"],
    "responses": [
        "目前，" + info.casino_name + " 不支持真实货币交易，因此不接受任何货币。",
        "作为一个非商业项目，" + info.casino_name + " 不处理任何真实货币交易。",
        "目前，" + info.casino_name + " 不进行货币交易。未来计划可能会包括支持各种货币。"
    ]
},
{
    "type": 1, 
    "queries": ["玩游戏的最低年龄是多少?", "我必须多大年龄才能玩?", "" + info.casino_name + "有年龄限制吗?"],
    "responses": [
        "你必须至少年满18岁或符合你所在司法辖区的法定赌博年龄，以便在" + info.casino_name + "上玩游戏。",
        "在" + info.casino_name + "玩的最低年龄要求是18岁或你所在地区的法定赌博年龄，以较高者为准。",
        "要在" + info.casino_name + "玩游戏，你需要年满18岁或满足你所在地点的法定赌博年龄要求。"
    ]
},
{
    "type": 1, 
    "queries": ["如何联系" + info.casino_name + "?", "联系信息是什么?", "我如何与你们联系?"],
    "responses": [
        "你可以通过电子邮件" + info.email + "或电话" + info.phone + "联系我们。",
        "通过电子邮件" + info.email + "或拨打" + info.phone + "来联系我们。",
        "如有查询，请通过电子邮件" + info.email + "或拨打" + info.phone + "联系我们。"
    ]
},
{
    "type": 1, 
    "queries": ["" + info.casino_name + "使用安全吗?", "" + info.casino_name + "的安全性如何?", "有哪些安全措施?"],
    "responses": [
        "是的，" + info.casino_name + " 遵循最佳实践，以确保安全和可靠的游戏环境。",
        "我们致力于维护一个安全的平台，并保护你的隐私。",
        "安全是" + info.casino_name + "的重中之重，我们实施措施来保护你的信息。"
    ]
},
{
    "type": 1, 
    "queries": ["我可以与其他人分享我的账户吗?", "一个账户可以有多个用户吗?", "其他人可以使用我的账户吗?"],
    "responses": [
        "不可以，账户共享是不允许的。每个账户只能由注册的个人使用。",
        "账户仅限个人使用，不能与他人共享。",
        "禁止与他人共享你的账户。每个人必须有自己的账户。"
    ]
},
{
    "type": 1, 
    "queries": ["游戏更新的频率是多少?", "你们定期更新游戏吗?", "何时添加新游戏?"],
    "responses": [
        "游戏会定期更新，以确保玩家获得新鲜和激动人心的体验。",
        "我们经常更新游戏内容，并添加新游戏，以保持趣味性。",
        "我们会定期添加新游戏和更新，以保持游戏体验的新鲜感。"
    ]
},
{
    "type": 1, 
    "queries": ["我可以从不同的设备玩游戏吗?", "我可以切换设备继续玩游戏吗?", "" + info.casino_name + "是否兼容不同的设备?"],
    "responses": [
        "是的，" + info.casino_name + " 支持从各种设备上玩，包括电脑、智能手机和平板电脑。",
        "你可以切换设备继续玩游戏，因为我们的平台兼容多个设备。",
        "我们的平台设计为可从不同设备访问，因此你可以从电脑、手机或平板电脑上玩游戏。"
    ]
},
{
    "type": 1, 
    "queries": ["你们有任何合作伙伴或联盟吗?", "是否与其他公司有合作?", "你们的合作伙伴是谁?"],
    "responses": [
        "目前，" + info.casino_name + " 独立运营，没有正式的合作伙伴或联盟。",
        "我们是一个独立项目，目前没有任何合作伙伴或联盟。",
        "作为一个独立项目，" + info.casino_name + " 目前没有任何正式的合作或伙伴关系。"
    ]
},
{
    "type": 1, 
    "queries": ["我如何提供反馈?", "在哪里可以留下我的评论?", "我可以提交建议或反馈吗?"],
    "responses": [
        "你可以通过我们的联系表单或发送电子邮件至" + info.email + "来提供反馈。",
        "通过我们网站上的联系表单或发送电子邮件来提交你的评论和建议。",
        "反馈可以通过我们的联系表单或发送电子邮件至" + info.email + "来提交。"
    ]
},
{
    "type": 1, 
    "queries": ["" + info.casino_name + "的使命是什么?", "" + info.casino_name + "为何创建?", "" + info.casino_name + "的目标是什么?"],
    "responses": [
        info.casino_name + "的创建目的是提供一个有趣的、非商业化的游戏体验，基于我们对赌场游戏的热情。",
        "我们的使命是提供一个独特而愉快的游戏环境，没有商业压力。",
        "我们旨在创建一个玩家可以在没有商业利益约束的情况下享受赌场游戏的空间。"
    ]
},
{
    "type": 

1, 
    "queries": ["有年龄限制吗?", "年龄要求是什么?", "我必须多大年龄才能使用" + info.casino_name + "?"],
    "responses": [
        "你必须至少年满18岁或符合你所在司法辖区的法定赌博年龄，以便使用" + info.casino_name + "。",
        "最低年龄要求是18岁或你所在地区的法定赌博年龄，以较高者为准。",
        "玩家需要年满18岁或满足其所在地的法定赌博年龄要求。"
    ]
},
{
    "type": 1, 
    "queries": ["" + info.casino_name + "面向哪些类型的玩家?", "" + info.casino_name + "适合谁?", "" + info.casino_name + "服务什么样的游戏社区?"],
    "responses": [
        info.casino_name + " 面向那些在非商业环境中享受赌场游戏的游戏爱好者。",
        "我们的平台是为那些欣赏轻松、非商业化游戏体验的玩家设计的。",
        "我们服务的是对赌场游戏充满热情、喜欢非商业化环境的玩家社区。"
    ]
},
{
    "type": 1, 
    "queries": ["是什么让" + info.casino_name + "与其他赌场不同?", "" + info.casino_name + "有什么独特之处?", "为什么我应该选择" + info.casino_name + "?"],
    "responses": [
        info.casino_name + " 因其非商业化的方式而脱颖而出，专注于纯粹享受游戏而没有盈利动机。",
        "我们的独特卖点是我们提供的由热情驱动的非商业游戏体验。",
        "与其他赌场不同，" + info.casino_name + " 由对游戏的热情驱动，而不是商业利益。"
    ]
},
{
    "type": 1, 
    "queries": ["我可以邀请朋友加入吗?", "可以推荐其他人到" + info.casino_name + "吗?", "我可以与朋友分享" + info.casino_name + "吗?"],
    "responses": [
        "是的，你可以邀请朋友来享受" + info.casino_name + "的游戏。只需分享我们的网站链接。",
        "可以随意推荐朋友到" + info.casino_name + "。他们可以免费加入并玩游戏。",
        "你可以与朋友分享" + info.casino_name + "。他们可以免费体验游戏。"
    ]
},

    // Games and Software

    {
        "type": 2, 
        "queries": ["游戏使用了什么软件？", "游戏的平台是什么？", "游戏背后使用了什么技术？"],
        "responses": [
            "在" + info.casino_name + "，游戏由一系列先进的游戏平台提供支持，以确保流畅的游戏体验。",
            "我们使用最先进的游戏技术，以确保高质量的游戏体验。",
            "我们的游戏建立在领先的游戏软件平台上，以提供顺畅和愉快的游戏体验。"
        ]
    },
    {
        "type": 2, 
        "queries": ["多久会添加新游戏？", "你们什么时候发布新游戏？", "你们会定期更新游戏库吗？"],
        "responses": [
            "我们会定期添加新游戏，以保持游戏体验的新鲜感和兴奋感。",
            "我们经常更新游戏库，添加新标题，以提供多样性并保持玩家的参与。",
            "我们的游戏库会定期扩展，确保总有新游戏可以尝试。"
        ]
    },
    {
        "type": 2, 
        "queries": ["游戏是否公平？", "你们如何确保游戏的公平性？", "游戏是否经过公平性测试？"],
        "responses": [
            "是的，所有游戏都会定期进行公平性测试，以确保公平的游戏体验。",
            "我们使用经过认证的随机数生成器（RNG）来保证所有游戏的公平性和公正性。",
            "我们的游戏经过严格的公平性测试，以确保每位玩家都有平等的获胜机会。"
        ]
    },
    {
        "type": 2, 
        "queries": ["可以离线玩游戏吗？", "游戏是否可以在没有互联网连接的情况下运行？", "是否有离线版本的游戏？"],
        "responses": [
            "不，游戏需要互联网连接才能进行，因为它们是在线托管的。",
            "我们的游戏设计为在线游戏，不支持离线模式。",
            "要访问和玩游戏，" + info.casino_name + " 需要互联网连接。"
        ]
    },
    {
        "type": 2, 
        "queries": ["游戏中有没有特殊功能？", "你的游戏有什么独特之处？", "游戏是否有特殊奖励或功能？"],
        "responses": [
            "我们的游戏包含各种特殊奖励和独特功能，以提升游戏体验。",
            "每个游戏都包括特殊功能，例如奖励、乘数和独特的游戏元素。",
            "我们融入了各种特殊功能和奖励，使每款游戏都独一无二且引人入胜。"
        ]
    },
    {
        "type": 2, 
        "queries": ["你们是否提供游戏的演示版本？", "我可以在玩之前试用游戏吗？", "游戏是否有演示模式？"],
        "responses": [
            "是的，我们提供游戏的演示版本，您可以在真实游戏之前尝试。",
            "您可以玩大多数游戏的演示版本，以体验它们，然后再使用真钱。",
            "我们为许多游戏提供演示模式，让您在投入真钱之前免费试用。"
        ]
    },
    {
        "type": 2, 
        "queries": ["我可以自定义游戏设置吗？", "是否可以调整游戏偏好设置？", "我可以更改游戏中的设置吗？"],
        "responses": [
            "是的，许多游戏允许您调整声音、图形和游戏偏好设置等选项。",
            "您可以自定义游戏的各种方面，以符合您的偏好，包括音频和视觉设置。",
            "大多数游戏提供可调节的设置，以根据您的偏好提升游戏体验。"
        ]
    },
    {
        "type": 2, 
        "queries": ["最受欢迎的游戏类型是什么？", "哪些游戏最受欢迎？", "在" + info.casino_name + "上最受欢迎的游戏是什么？"],
        "responses": [
            "我们最受欢迎的游戏包括轮盘、二十一点和老虎机，这些游戏吸引了大量玩家。",
            "轮盘、二十一点和老虎机是我们玩家最喜欢的游戏，还有其他一些游戏。",
            "最受欢迎的游戏包括轮盘、二十一点和老虎机，深受我们社区的喜爱。"
        ]
    },
    {
        "type": 2, 
        "queries": ["是否有累积奖金游戏？", "你们提供带有奖金的游戏吗？", "我可以玩带有累积奖金的游戏吗？"],
        "responses": [
            "目前，" + info.casino_name + " 不提供累积奖金游戏。",
            "我们目前没有累积奖金游戏。",
            "我们的游戏选择中不包括累积奖金游戏，但我们提供其他令人兴奋的游戏类型。"
        ]
    },
    {
        "type": 2, 
        "queries": ["可以与其他人一起玩游戏吗？", "是否有多人游戏可供选择？", "你们是否提供可以与其他人竞争的游戏？"],
        "responses": [
            "我们的大多数游戏是单人游戏，但您可以与其他玩家一起在线享受这些游戏。",
            "目前，我们主要提供单人游戏，但您可以通过我们的平台与其他玩家互动。",
            "虽然我们的游戏主要是单人游戏，但您可以与其他玩家享受社区游戏体验。"
        ]
    },
    {
        "type": 2, 
        "queries": ["玩游戏的系统要求是什么？", "我需要特定的硬件来玩游戏吗？", "我需要什么来玩" + info.casino_name + "的游戏？"],
        "responses": [
            "您可以在大多数现代设备上玩我们的游戏，只需有互联网连接和一个网页浏览器。",
            "要享受游戏，您需要一台具有互联网访问功能的设备和一个当前的网页浏览器。",
            "我们的游戏设计支持各种设备，包括带有互联网连接的计算机、平板电脑和智能手机。"
        ]
    },
    {
        "type": 2, 
        "queries": ["游戏是否有教程？", "我可以学习如何玩游戏吗？", "你们提供游戏指南或教程吗？"],
        "responses": [
            "我们为许多游戏提供教程和指南，以帮助您入门。",
            "游戏教程和指南可用，以帮助您学习如何玩每款游戏。",
            "您可以访问大多数游戏的指南和教程，以了解如何玩和赢得游戏。"
        ]
    },
    {
        "type": 2, 
        "queries": ["我可以建议新游戏吗？", "如何请求添加新游戏？", "有没有办法向" + info.casino_name + "建议游戏？"],
        "responses": [
            "您可以通过我们的反馈表单或电子邮件来建议新游戏。",
            "请通过电子邮件或我们的联系表单向我们发送游戏建议。",
            "我们欢迎游戏建议，您可以通过电子邮件或我们的反馈表单提交。"
        ]
    },
    {
        "type": 2, 
        "queries": ["最近添加了哪些新游戏？", "最近添加了哪些新游戏？", "能告诉我最新的游戏吗？"],
        "responses": [
            "我们最近添加了新的老虎机、二十一点变体和其他令人兴奋的标题。",
            "我们最近添加了新的游戏，如老虎机、扑克变体和更新的经典游戏。",
            "查看我们最新的游戏添加，包括最近更新的热门老虎机和桌面游戏。"
        ]
    },
    {
        "type": 2, 
        "queries": ["你们是否提供独占游戏？", "在" + info.casino_name + "上是否有独特的游戏？", "你们有什么独特内容？"],
        "responses": [
            "我们提供了一些专门为" + info.casino_name + "创建的独占游戏。",
            "在" + info.casino_name + "上，有些游戏是我们平台专属的，为玩家提供独特的体验。",
            "探索我们在" + info.casino_name + "上独有的游戏，这些游戏旨在为我们的玩家提供特别的内容。"
        ]
    },
    {
        "type": 2, 
        "queries": ["游戏是否支持多种语言？", "我可以用不同的语言玩游戏吗？", "游戏是否有语言选项？"],
        "responses": [
            "目前，游戏主要以英语提供，但我们正在努力添加更多语言选项。",
            "游戏在" + info.casino_name + "上主要是英语，我们计划将来支持更多语言。",
            "我们的大多数游戏是用英语提供的，但我们计划尽快添加多种语言选项。"
        ]
    },
    {
        "type": 2, 
        "queries": ["我可以保存游戏进度吗？", "是否有办法稍后继续游戏？", "你的游戏是否支持保存进度？"],
        "responses": [
            "我们的大多数游戏不保存进度，因为它们设计为即时游戏。",
            "目前，游戏进度不会保存，因此每次游戏时需要重新开始。",
            "游戏设计为立即玩，不保存进度，但我们致力于未来添加此功能。"
        ]
    },
    {
        "type": 2, 
        "queries": ["如何了解更多关于游戏的信息？", "哪里可以找到每个游戏的详细信息？", "是否有关于每款游戏的信息？"],
        "responses": [
            "您可以在我们网站上每款游戏的专属页面上找到详细信息。",
            "每款游戏都有一个专门的页面，提供规则和功能等信息，可在我们的网站上访问。",
            "有关每款游戏的详细信息，包括规则和功能，均可在各自的游戏页面上找到。"
        ]
    },
    {
        "type": 2, 
        "queries": ["是否有季节性或特别活动游戏？", "你们提供特殊场合的游戏吗？", "是否有节日或活动的游戏？"],
        "responses": [
            "我们偶尔会提供季节性或特别活动的游戏，请随时关注更新。",
            "节日和特殊场合期间可能会有特别活动和季节性游戏。",
            "请关注全年推出的主题游戏和特别活动促销。"
        ]
    },
    {
        "type": 2, 
        "queries": ["我可以调整游戏难度吗？", "游戏是否有难度设置？", "我可以改变游戏的挑战水平吗？"],
        "responses": [
            "游戏难度通常是固定的，但您可以在提供的不同模式或级别中进行选择。",
            "大多数游戏没有可调节的难度设置，但有些游戏提供不同的挑战等级。",
            "难度等级是预设的，但一些游戏提供不同的模式或选项来改变体验。"
        ]
    },

    // Account Management
    
    {
        "type": 3, 
        "queries": ["如何创建账户？", "注册的流程是什么？", "你能指导我如何创建账户吗？"],
        "responses": [
            "要创建账户，请点击我们主页上的 '注册' 按钮，并填写注册表单。",
            "只需点击 '注册' 按钮，然后按照指示完成新账户创建。",
            "创建账户很简单：点击 '注册' 并完成注册流程。"
        ]
    },
    {
        "type": 3, 
        "queries": ["我如何重置密码？", "如果我忘记密码怎么办？", "我如何恢复账户密码？"],
        "responses": [
            "如果您忘记了密码，请点击登录页面上的 '忘记密码' 链接，并按照说明重置密码。",
            "使用登录页面上的 '忘记密码' 链接来重置您的密码。",
            "请按照我们网站上的 '忘记密码' 说明来恢复和重置您的密码。"
        ]
    },
    {
        "type": 3, 
        "queries": ["我如何更新账户信息？", "我可以更改个人信息吗？", "在哪里更新账户设置？"],
        "responses": [
            "您可以通过登录并访问 '账户设置' 部分来更新您的账户信息。",
            "要更改个人信息，请登录并导航到 '账户设置' 以进行更新。",
            "登录后进入 '账户设置' 来更新您的个人信息和偏好设置。"
        ]
    },
    {
        "type": 3, 
        "queries": ["我如何关闭我的账户？", "我可以删除我的账户吗？", "关闭账户的流程是什么？"],
        "responses": [
            "要关闭您的账户，请通过电子邮件或我们的联系表单联系支持团队。",
            "账户关闭请求可以通过直接联系支持团队来处理。",
            "要删除账户，请联系支持团队寻求帮助。"
        ]
    },
    {
        "type": 3, 
        "queries": ["我可以拥有多个账户吗？", "创建多个账户是允许的吗？", "我可以注册多个账户吗？"],
        "responses": [
            "不允许拥有多个账户。每个用户只能拥有一个账户。",
            "创建多个账户违反我们的政策。请使用一个账户。",
            "我们不允许一个人拥有多个账户。每个用户只能拥有一个账户。"
        ]
    },
    {
        "type": 3, 
        "queries": ["我如何验证我的账户？", "验证流程是什么？", "我需要验证我的账户吗？"],
        "responses": [
            "账户验证通过电子邮件完成。注册后，请检查您的收件箱中的验证链接。",
            "要验证您的账户，请点击注册后发送到您电子邮件中的验证链接。",
            "需要电子邮件验证。请按照发送到您电子邮件中的链接完成验证过程。"
        ]
    },
    {
        "type": 3, 
        "queries": ["如果我无法登录该怎么办？", "我在登录账户时遇到问题。怎么办？", "为什么我无法访问我的账户？"],
        "responses": [
            "如果您无法登录，请确保您使用了正确的用户名和密码。如果问题仍然存在，请尝试重置密码。",
            "检查您是否输入了正确的登录信息，如果问题持续，请尝试重置密码。",
            "确认您的登录凭据，如果仍然无法访问账户，请考虑重置密码。"
        ]
    },
    {
        "type": 3, 
        "queries": ["我如何更改我的电子邮件地址？", "我可以更新与账户关联的电子邮件吗？", "更改电子邮件的流程是什么？"],
        "responses": [
            "要更改电子邮件地址，请登录到您的账户并在 '账户设置' 部分更新您的电子邮件。",
            "通过登录后访问 '账户设置' 来更新您的电子邮件地址。",
            "在 '账户设置' 中更改您的电子邮件地址，输入新的电子邮件。"
        ]
    },
    {
        "type": 3, 
        "queries": ["我可以更改用户名吗？", "如何更新我的用户名？", "是否可以修改用户名？"],
        "responses": [
            "目前，用户名不能更改。如果您需要不同的用户名，您需要创建一个新账户。",
            "用户名是永久的，不能更改。如果您需要不同的用户名，请考虑创建新账户。",
            "用户名不可更改。您可能需要设置一个新账户以使用您首选的用户名。"
        ]
    },
    {
        "type": 3, 
        "queries": ["我如何设置双重认证？", "我可以为我的账户启用双重认证吗？", "添加双重认证的流程是什么？"],
        "responses": [
            "要启用双重认证，请进入 '账户设置' 并按照 '安全设置' 中的说明进行操作。",
            "通过导航到 '账户设置' 并按照安全设置指南设置双重认证。",
            "在 '账户设置' 中启用双重认证，按照 '安全设置' 中的步骤进行操作。"
        ]
    },
    {
        "type": 3, 
        "queries": ["如果我的账户被暂停该怎么办？", "我如何解决账户暂停问题？", "为什么我的账户被暂停了，我该如何修复？"],
        "responses": [
            "如果您的账户被暂停，请联系支持团队以了解原因并解决问题。",
            "联系支持团队解决账户暂停问题并获取帮助。",
            "联系支持团队询问账户暂停情况，并按照必要步骤解决问题。"
        ]
    },
    {
        "type": 3, 
        "queries": ["我如何添加头像？", "我可以为账户上传照片吗？", "设置头像的流程是什么？"],
        "responses": [
            "要添加头像，请进入 '账户设置' 并在个人资料部分上传您的照片。",
            "通过访问 '账户设置' 并选择更改照片的选项来上传头像。",
            "通过导航到 '账户设置' 并上传您选择的图片来设置头像。"
        ]
    },
    {
        "type": 3, 
        "queries": ["我如何管理账户偏好？", "我可以自定义账户设置吗？", "在哪里调整账户偏好？"],
        "responses": [
            "通过 '账户设置' 管理您的账户偏好，在那里您可以自定义各种选项。",
            "在 '账户设置' 中自定义您的账户设置，以调整您的偏好。",
            "通过访问 '账户设置' 来调整您的账户偏好和设置。"
        ]
    },
    {
        "type": 3, 
        "queries": ["我如何删除账户数据？", "我可以删除与账户相关的所有数据吗？", "删除账户信息的流程是什么？"],
        "responses": [
            "要删除账户数据，请联系支持团队并请求数据删除。",
            "联系支持团队请求删除您的账户数据。",
            "删除账户数据的请求可以通过直接联系支持团队来完成。"
        ]
    },
    {
        "type": 3, 
        "queries": ["我可以查看我的账户活动历史吗？", "如何查看我的账户历史？", "有没有办法查看账户活动？"],
        "responses": [
            "您可以通过访问 '账户历史' 部分查看您的账户活动历史。",
            "在设置菜单下的 '账户历史' 中检查您的账户活动历史。",
            "您的账户活动历史在账户设置的 '账户历史' 部分中可以查看。"
        ]
    },
    {
        "type": 3, 
        "queries": ["我如何管理电子邮件通知？", "我可以控制账户的电子邮件通知吗？", "在哪里调整电子邮件偏好？"],
        "responses": [
            "通过 '账户设置' 管理您的电子邮件通知，调整您的通知偏好。",
            "在 '账户设置' 中调整电子邮件通知设置，以控制您收到的通知。",
            "您可以通过更新 '账户设置' 部分中的偏好来控制电子邮件通知。"
        ]
    },
    {
        "type": 3, 
        "queries": ["账户验证的流程是什么？", "我如何验证账户身份？", "你能解释一下账户验证步骤吗？"],
        "responses": [
            "账户验证通过电子邮件确认完成。注册后点击发送到您电子邮件中的验证链接。",
            "通过点击发送到您电子邮件地址的链接来验证您的账户。",
            "按照发送到您电子邮件中的账户验证说明完成验证，获取完整访问权限。"
        ]
    },
    
    // Deposits and Withdrawals

    {
        "type": 4, 
        "queries": ["你们接受哪些支付方式？", "有哪些支付选项？", "我如何存款？"],
        "responses": [
            "目前，我们不接受任何支付方式，因为这个网站是一个兴趣项目。未来，我们计划接受包括信用卡/借记卡、银行转账和流行电子钱包在内的各种支付方式。",
            "目前没有设置支付方式。未来我们会支持包括信用卡、银行转账和电子钱包在内的各种方法。"
        ]
    },
    {
        "type": 4, 
        "queries": ["我可以提款吗？", "我如何提取我的奖金？", "有提款选项吗？"],
        "responses": [
            "由于这是一个非商业项目，目前不支持提款。未来计划包括增加提款选项。",
            "目前没有可用的提款选项。我们计划在未来添加这个功能。"
        ]
    },
    {
        "type": 4, 
        "queries": ["是否有最低存款金额？", "我需要存入最低金额吗？", "存款的最低金额是多少？"],
        "responses": [
            "目前没有最低存款金额，因为我们还不接受存款。",
            "由于我们目前不处理存款，因此没有最低金额要求。"
        ]
    },
    {
        "type": 4, 
        "queries": ["存款和提款是否有费用？", "你们对交易收取费用吗？", "存款和提款的费用是多少？"],
        "responses": [
            "由于我们目前不处理存款或提款，因此没有交易费用。",
            "目前没有费用适用，因为我们不处理任何存款或提款。"
        ]
    },
    {
        "type": 4, 
        "queries": ["你们什么时候开始接受存款？", "存款功能会很快上线吗？", "未来会启用存款功能吗？"],
        "responses": [
            "我们计划在未来开始接受存款，但目前没有固定的日期。",
            "存款功能将在未来的更新中启用，但我们没有确切的时间表。"
        ]
    },
    {
        "type": 4, 
        "queries": ["我可以使用加密货币进行存款吗？", "你们会接受比特币吗？", "加密货币是否是交易选项？"],
        "responses": [
            "未来，我们计划接受比特币等加密货币进行交易。",
            "我们计划在未来的更新中支持包括比特币在内的加密货币。"
        ]
    },
    {
        "type": 4, 
        "queries": ["我如何查看我的账户余额？", "在哪里可以查看余额？", "我如何查看当前余额？"],
        "responses": [
            "您可以通过登录账户并导航到 '账户' 部分来查看余额。",
            "您的余额会显示在账户资料中的 '账户' 部分。"
        ]
    },
    {
        "type": 4, 
        "queries": ["我的交易安全吗？", "我的支付信息安全吗？", "交易的安全性如何？"],
        "responses": [
            "一旦启用，所有交易将通过安全加密的方式处理，以确保您的安全。",
            "我们计划在未来实施强大的安全措施以保护您的支付信息。"
        ]
    },
    {
        "type": 4, 
        "queries": ["我可以取消存款吗？", "如何停止存款？", "可以撤销存款吗？"],
        "responses": [
            "由于目前不处理存款，因此该功能不适用。",
            "未来的更新将包括管理存款的选项，包括可能的取消功能。"
        ]
    },
    {
        "type": 4, 
        "queries": ["你们将接受哪些货币？", "会支持多种货币吗？", "有哪些可用的货币？"],
        "responses": [
            "未来，我们计划接受包括欧元、美元、英镑、瑞士法郎和罗马尼亚列伊在内的多种货币。",
            "我们的未来计划包括支持各种货币，如欧元、美元和英镑。"
        ]
    },
    {
        "type": 4, 
        "queries": ["提款需要多长时间？", "提款处理时间是多少？", "我可以多快提取资金？"],
        "responses": [
            "一旦启用提款功能，我们将提供处理时间。预计将遵循标准银行处理时间。",
            "未来的更新将详细说明提款处理时间，预计与标准银行处理时间类似。"
        ]
    },
    {
        "type": 4, 
        "queries": ["我可以设置存款限额吗？", "是否可以限制存款金额？", "我可以控制我的存款金额吗？"],
        "responses": [
            "未来的更新将包括设置存款限额的功能，以帮助管理您的开支。",
            "我们计划在未来的版本中提供设置存款限额的工具。"
        ]
    },
    {
        "type": 4, 
        "queries": ["你们提供存款奖金吗？", "是否有存款奖金？", "存款时会获得奖金吗？"],
        "responses": [
            "一旦存款功能激活，我们计划推出各种存款奖金和促销活动。",
            "未来计划包括提供存款奖金，以提升您的游戏体验。"
        ]
    },
    {
        "type": 4, 
        "queries": ["你们会接受PayPal吗？", "我可以使用PayPal进行交易吗？", "PayPal是支付选项之一吗？"],
        "responses": [
            "PayPal 将是我们未来计划支持的支付方式之一。",
            "我们计划在即将发布的更新中包括PayPal作为接受的支付方式。"
        ]
    },
    {
        "type": 4, 
        "queries": ["我如何更新支付信息？", "我可以更改支付方式吗？", "我如何修改支付详情？"],
        "responses": [
            "一旦支付选项可用，您可以通过账户设置更新您的支付信息。",
            "未来的更新将允许您通过账户设置管理和更新您的支付方式。"
        ]
    },
    {
        "type": 4, 
        "queries": ["我可以使用多个支付方式吗？", "是否可以添加多个支付选项？", "我可以有多个支付方式吗？"],
        "responses": [
            "未来，我们计划支持多种支付方式，以方便您的使用。",
            "您将能够在未来的更新中添加和管理多个支付选项。"
        ]
    },
    {
        "type": 4, 
        "queries": ["是否有提款限额？", "我可以提款的最高金额是多少？", "提款是否有限制？"],
        "responses": [
            "提款限额将在启用功能后详细说明，以确保公平使用。",
            "未来的更新将包括提款限额的信息，以有效管理交易。"
        ]
    },
    {
        "type": 4, 
        "queries": ["我可以设置自动存款吗？", "是否提供自动存款功能？", "如何自动化我的存款？"],
        "responses": [
            "自动存款功能可能会包含在未来的更新中，以提供更多便利。",
            "我们计划在即将发布的版本中提供自动存款选项，以简化存款过程。"
        ]
    },
    {
        "type": 4, 
        "queries": ["你们提供即时提款吗？", "是否可以进行即时提款？", "我可以多快提取我的钱？"],
        "responses": [
            "即时提款选项将在未来的更新中考虑，以提供更快的资金访问。",
            "我们计划在未来的更新中包括即时提款功能，以实现快速交易。"
        ]
    },
    {
        "type": 4, 
        "queries": ["我可以通过手机存款吗？", "是否支持移动存款？", "我可以用手机进行存款吗？"],
        "responses": [
            "移动存款将在未来的更新中支持，让您随时随地进行交易。",
            "我们计划启用移动存款功能，以便您通过智能手机方便地管理交易。"
        ]
    },

    // Technical Issues

    {
        "type": 5, 
        "queries": ["为什么游戏无法加载？", "游戏无法启动，我该怎么办？", "为什么我无法启动游戏？"],
        "responses": [
            "如果游戏无法加载，请尝试刷新页面或清除浏览器缓存。",
            "确保您的互联网连接稳定，如果游戏无法启动，请尝试重启设备。",
            "检查您的浏览器设置，刷新页面，或尝试使用不同的浏览器启动游戏。"
        ]
    },
    {
        "type": 5, 
        "queries": ["为什么在游戏过程中会出现延迟？", "游戏延迟是什么原因？", "我如何解决延迟问题？"],
        "responses": [
            "游戏过程中出现延迟可能是由于互联网连接慢。尝试重启路由器或切换到其他网络。",
            "检查是否有其他应用程序占用了过多带宽，关闭这些应用程序可以改善游戏性能。",
            "更新您的浏览器或设备，并确保没有后台应用程序运行，可以帮助减少延迟。"
        ]
    },
    {
        "type": 5, 
        "queries": ["为什么我的游戏崩溃？", "游戏不断崩溃，我该怎么办？", "为什么我的游戏会冻结和崩溃？"],
        "responses": [
            "游戏崩溃可能是由于软件过时。确保您的浏览器或应用程序是最新的。",
            "清除缓存和 cookies 看看是否能解决崩溃问题。",
            "如果游戏继续崩溃，请尝试使用不同的设备或浏览器。"
        ]
    },
    {
        "type": 5, 
        "queries": ["我如何报告错误？", "我可以提交错误报告吗？", "在哪里报告技术问题？"],
        "responses": [
            "要报告错误，请访问我们的“联系我们”页面，并通过错误报告表单提交详细信息。",
            "您可以通过联系支持团队来报告技术问题，并描述问题。",
            "使用游戏菜单中的“报告错误”选项，将您遇到的问题的详细信息发送给我们。"
        ]
    },
    {
        "type": 5, 
        "queries": ["为什么声音没有工作？", "游戏没有声音，我该怎么办？", "为什么没有音频？"],
        "responses": [
            "检查您的设备音量是否打开，并确保游戏设置中启用了声音。",
            "确保您的浏览器或应用程序权限允许声音，并尝试重启游戏。",
            "如果问题仍然存在，请尝试使用不同的设备或更新您的软件。"
        ]
    },
    {
        "type": 5, 
        "queries": ["为什么我无法登录我的账户？", "登录问题，我该怎么办？", "为什么我无法访问我的账户？"],
        "responses": [
            "确保您使用了正确的用户名和密码。如果忘记了，请使用“忘记密码”选项。",
            "检查您的互联网连接，并在几分钟后尝试再次登录。",
            "清除浏览器缓存或尝试使用不同的浏览器，如果登录问题仍然存在。"
        ]
    },
    {
        "type": 5, 
        "queries": ["为什么游戏屏幕是空白的？", "游戏屏幕为空，我该怎么办？", "为什么我看到空白屏幕？"],
        "responses": [
            "空白屏幕可能是由于加载问题。尝试刷新页面或清除浏览器缓存。",
            "确保您的互联网连接稳定，并确保浏览器是最新的。",
            "如果问题持续存在，尝试使用不同的浏览器或设备访问游戏。"
        ]
    },
    {
        "type": 5, 
        "queries": ["为什么游戏运行缓慢？", "游戏性能缓慢，我该怎么办？", "为什么游戏会出现延迟？"],
        "responses": [
            "缓慢的性能可能是由于互联网连接差。尝试重启路由器或切换网络。",
            "确保没有其他应用程序占用过多带宽，必要时关闭它们。",
            "更新您的设备和浏览器可以帮助改善游戏性能。"
        ]
    },
    {
        "type": 5, 
        "queries": ["为什么我无法进行游戏内购买？", "游戏内购买无法完成，我该怎么办？", "为什么我的购买失败了？"],
        "responses": [
            "确保您的支付方式有效且有足够的资金。尝试重新输入支付详情。",
            "检查您的账户或支付方式是否有任何限制。",
            "如果问题仍然存在，请联系支持团队寻求购买方面的帮助。"
        ]
    },
    {
        "type": 5, 
        "queries": ["我如何更新游戏？", "我可以获得最新版本的游戏吗？", "在哪里可以下载游戏更新？"],
        "responses": [
            "请检查应用商店或游戏网站，获取最新版本并按照更新说明进行操作。",
            "确保您的设备设置中启用了自动更新，以便接收最新的游戏更新。",
            "如果您使用的是浏览器，请刷新页面或清除缓存，以加载最新版本。"
        ]
    },
    {
        "type": 5, 
        "queries": ["为什么我无法连接到服务器？", "服务器连接问题，我该怎么办？", "为什么我无法连接到游戏服务器？"],
        "responses": [
            "检查您的互联网连接，如果无法连接到服务器，请尝试重启路由器。",
            "确保游戏服务器没有进行维护，可以通过查看我们的状态页面或公告来确认。",
            "尝试在几分钟后重新连接，或使用不同的网络。"
        ]
    },
    {
        "type": 5, 
        "queries": ["为什么我的游戏内物品丢失了？", "游戏内物品丢失了，我该怎么办？", "为什么我的物品消失了？"],
        "responses": [
            "如果您的游戏内物品丢失了，尝试重启游戏或刷新页面。",
            "检查物品是否在库存的其他部分，或是否有更新影响了它们。",
            "联系支持团队并提供有关丢失物品的详细信息，以获取进一步帮助。"
        ]
    },
    {
        "type": 5, 
        "queries": ["我如何修复图形故障？", "游戏中的图形问题，我该怎么办？", "为什么会有视觉错误？"],
        "responses": [
            "确保您的图形驱动程序是最新的，并重启设备以修复图形故障。",
            "尝试降低游戏中的图形设置，看看是否能解决问题。",
            "如果问题仍然存在，联系支持团队并提供视觉错误的详细信息。"
        ]
    },
    {
        "type": 5, 
        "queries": ["为什么游戏无法保存我的进度？", "进度无法保存，我该怎么办？", "为什么我无法保存游戏？"],
        "responses": [
            "检查您是否已登录账户，因为作为访客玩游戏可能无法保存进度。",
            "确保您有稳定的互联网连接，因为某些游戏需要它来保存进度。",
            "尝试重启游戏，看是否能解决进度保存的问题。"
        ]
    },
    {
        "type": 5, 
        "queries": ["为什么游戏没有响应？", "游戏冻结了，我该怎么办？", "为什么游戏卡住了？"],
        "responses": [
            "如果游戏没有响应，尝试刷新页面或重启应用程序。",
            "确保您的设备和浏览器是最新的，以防止冻结问题。",
            "如果游戏继续冻结，尝试使用不同的浏览器或设备。"
        ]
    },
    {
        "type": 5, 
        "queries": ["我如何排除连接问题？", "连接问题，我该怎么办？", "为什么我会遇到连接问题？"],
        "responses": [
            "重启路由器，确保您的互联网连接稳定。",
            "检查其他设备是否能连接互联网，并尝试切换网络。",
            "如果问题仍然存在，请联系您的互联网服务提供商寻求进一步帮助。"
        ]
    },
    {
        "type": 5, 
        "queries": ["为什么我无法下载游戏？", "下载问题，我该怎么办？", "为什么游戏无法下载？"],
        "responses": [
            "确保您的设备上有足够的存储空间，然后再次尝试下载游戏。",
            "检查您的互联网连接，并重启设备以解决下载问题。",
            "如果您仍然无法下载游戏，请联系支持团队寻求帮助。"
        ]
    },
    {
        "type": 5, 
        "queries": ["我如何重置游戏设置？", "我可以恢复默认设置吗？", "如何将游戏设置恢复为默认？"],
        "responses": [
            "前往游戏设置菜单，寻找重置为默认设置的选项。",
            "通常可以通过游戏中的设置或选项菜单恢复默认设置。",
            "如果找不到该选项，请联系支持团队以获得重置设置的帮助。"
        ]
    },
    {
        "type": 5, 
        "queries": ["为什么我无法访问某些功能？", "功能被锁定，我该怎么办？", "为什么有些功能不可用？"],
        "responses": [
            "某些功能可能需要您达到特定的等级或完成某些任务。",
            "确保您已安装最新的游戏更新，因为新功能可能包含在更新中。",
            "如果功能仍然被锁定，请联系支持团队以获得进一步帮助。"
        ]
    },
    {
        "type": 5, 
        "queries": ["我如何清除游戏缓存？", "我可以删除游戏缓存吗？", "清除游戏缓存的过程是什么？"],
        "responses": [
            "要清除游戏缓存，请前往浏览器设置，找到清除浏览数据的选项。",
            "在设备设置中，找到应用程序管理器，找到游戏并清除其缓存。",
            "清除游戏缓存可以解决许多问题。请检查设备或浏览器设置中的相关选项。"
        ]
    },

    // Responsible Gambling

    {
        "type": 6, 
        "queries": ["什么是负责任的赌博？", "能解释一下负责任的赌博吗？", "负责任的赌博是什么意思？"],
        "responses": [
            "负责任的赌博是指以安全和在您的财务能力范围内的方式进行游戏。",
            "这意味着保持对赌博活动的控制，并确保它不会对您的生活产生负面影响。",
            "负责任的赌博是指享受游戏而不冒险损失超出您能承受的范围。"
        ]
    },
    {
        "type": 6, 
        "queries": ["你们有负责任赌博的措施吗？", "是否有负责任赌博的工具？", "我如何管理我的赌博活动？"],
        "responses": [
            "目前我们没有负责任赌博的措施，但我们计划在未来推出存款限制和自我排除等工具。",
            "我们正在努力提供各种工具和资源，以帮助您负责任地管理赌博活动。",
            "未来的更新将包括如存款限制、自我排除和访问支持组织等功能。"
        ]
    },
    {
        "type": 6, 
        "queries": ["如何设置存款限制？", "是否可以限制我的存款？", "我能控制我存款的金额吗？"],
        "responses": [
            "存款限制将在未来的更新中提供，以帮助您管理支出。",
            "一旦该功能实施，您将能够设置和调整存款限制。",
            "我们的未来计划包括设置存款限制选项，以鼓励负责任的赌博。"
        ]
    },
    {
        "type": 6, 
        "queries": ["什么是自我排除？", "我可以自我排除吗？", "自我排除是如何工作的？"],
        "responses": [
            "自我排除是一种工具，允许您暂时或永久阻止自己访问赌博活动。",
            "这是一个帮助那些可能需要暂时离开赌博以防止潜在伤害的措施。",
            "未来的更新将包括自我排除选项，以支持负责任的赌博实践。"
        ]
    },
    {
        "type": 6, 
        "queries": ["如何寻求赌博成瘾的帮助？", "在哪里可以找到问题赌博的支持？", "赌博问题有哪些资源可以利用？"],
        "responses": [
            "如果您或您认识的人正在挣扎于赌博成瘾，至关重要的是寻求帮助。像Gamblers Anonymous和国家问题赌博委员会这样的组织可以提供支持。",
            "支持可以通过各种组织获得，例如Gamblers Anonymous和国家问题赌博委员会。",
            "您可以向支持小组和专业服务寻求帮助，包括Gamblers Anonymous和类似的组织。"
        ]
    },
    {
        "type": 6, 
        "queries": ["是否有负责任赌博的资源？", "有哪些管理赌博的工具？", "我可以访问负责任赌博的信息吗？"],
        "responses": [
            "我们计划提供一系列负责任赌博的资源和工具，包括信息文章和自我评估测试。",
            "未来的更新将包括访问推广负责任赌博的资源，如教育材料和管理工具。",
            "一旦这些资源和工具实施，您将能够访问各种负责任赌博的资源。"
        ]
    },
    {
        "type": 6, 
        "queries": ["赌博成瘾的迹象有哪些？", "我怎么知道自己是否有赌博问题？", "我应该关注哪些赌博问题的迹象？"],
        "responses": [
            "赌博成瘾的迹象包括在赌博上花费比计划更多的钱和时间、忽视责任，以及借钱赌博。",
            "如果您发现自己对赌博有过度关注、追逐损失，或对个人和职业生活产生负面影响，这些可能是赌博问题的迹象。",
            "赌博成瘾的常见迹象包括增加赌注、无法停止和撒谎关于赌博活动。"
        ]
    },
    {
        "type": 6, 
        "queries": ["我可以限制赌博时间吗？", "有没有办法控制我的赌博时间？", "我如何管理我的赌博时长？"],
        "responses": [
            "赌博会话的时间限制将在未来的更新中提供，以帮助您管理游戏时间。",
            "我们计划推出允许您设置和管理赌博活动时间限制的功能。",
            "未来的更新将包括设置时间限制的选项，以鼓励负责任的赌博。"
        ]
    },
    {
        "type": 6, 
        "queries": ["我怎么知道自己是否在负责任地赌博？", "负责任的赌博是什么样的？", "我如何确保我的赌博是安全的？"],
        "responses": [
            "负责任的赌博意味着对时间和金钱的花费设置限制，并确保这不会对您的生活产生负面影响。",
            "它涉及保持在您的财务能力范围内、不追逐损失，并留意赌博所花费的时间。",
            "为了负责任地赌博，请设定限制，保持对花费的关注，并确保赌博始终是一项有趣且受控的活动。"
        ]
    },
    {
        "type": 6, 
        "queries": ["是否有对投注金额的限制？", "我可以控制我的投注金额吗？", "可以设置投注限制吗？"],
        "responses": [
            "未来的更新将包括投注限制选项，以帮助您负责任地管理赌博活动。",
            "您将能够在即将更新中设置投注限制，以控制支出。",
            "投注限制将被引入以支持负责任的赌博，并管理您的开支。"
        ]
    },
    {
        "type": 6, 
        "queries": ["我可以收到关于赌博活动的通知吗？", "你们会提供关于赌博行为的警报吗？", "我如何监控我的赌博习惯？"],
        "responses": [
            "我们计划在未来的更新中包括发送关于您的赌博活动的通知和警报的功能。",
            "未来的更新将提供通知，以帮助您保持对赌博习惯和活动的关注。",
            "您将能够收到关于赌博行为的警报和通知，以促进负责任的游戏。"
        ]
    },
    {
        "type": 6, 
        "queries": ["如何帮助朋友解决赌博问题？", "如果我认识的人有赌博问题，我应该怎么做？", "我如何支持有赌博困扰的人？"],
        "responses": [
            "如果您怀疑某人有赌博问题，鼓励他们寻求支持组织的帮助，并提供您的支持。",
            "与您的朋友谈谈，并建议他们寻求Gamblers Anonymous等组织的专业帮助。",
            "给予支持，并引导他们寻求赌博成瘾支持小组和专业人士的帮助。"
        ]
    },
    {
        "type": 6, 
        "queries": ["是否有关于负责任赌博的教育材料？", "我可以获取有关负责任赌博的信息吗？", "在哪里可以找到赌博方面的教育资源？"],
        "responses": [
            "我们计划在未来的更新中提供关于负责任赌博的教育材料，包括文章和指南。",
            "未来的更新将包括访问教育资源，以推广负责任的赌博实践。",
            "一旦这些教育材料实施，您将能够找到各种关于负责任赌博的资源。"
        ]
    },
    {
        "type": 6, 
        "queries": ["如果超出赌博限制会发生什么？", "超出限制是否会有惩罚？", "超过赌博限制的后果是什么？"],
        "responses": [
            "未来的更新将包括防止您超出设定限制的机制，并在需要时提供支持。",
            "超出限制将触发提醒，并可能实施临时限制以帮助管理您的赌博行为。",
            "我们计划实施措施，提醒您并帮助您保持在赌博限制内。"
        ]
    },
    {
        "type": 6, 
        "queries": ["如何访问负责任赌博工具？", "在哪里可以找到负责任赌博的工具？", "我可以使用哪些工具来管理我的赌博？"],
        "responses": [
            "负责任赌博的工具将在未来的更新中通过您的账户设置提供。",
            "我们计划在即将更新中包括专门的负责任赌博工具和资源部分。",
            "您将能够通过您的账户访问和使用各种负责任赌博工具。"
        ]
    },
    {
        "type": 6, 
        "queries": ["我可以选择特定的自我排除期限吗？", "我可以自我排除多长时间？", "是否有临时自我排除选项？"],
        "responses": [
            "特定期限的自我排除选项将在未来的更新中提供，以满足您的需求。",
            "我们计划提供临时自我排除选项，以帮助您在需要时暂时停止赌博。",
            "未来的更新将包括灵活的自我排除期限，以支持负责任的赌博实践。"
        ]
    },
    {
        "type": 6, 
        "queries": ["你们如何推广负责任的赌博？", "你们采取了哪些措施来确保负责任的赌博？", "你们如何鼓励安全赌博？"],
        "responses": [
            "我们计划通过工具、资源和定期提醒来推广负责任的赌博。",
            "我们的未来更新将包括各种旨在鼓励负责任赌博的措施和功能。",
            "推广负责任赌博将通过教育资源、工具和支持选项来实现。"
        ]
    },
    {
        "type": 6, 
        "queries": ["我可以获得赌博活动报告吗？", "是否可以跟踪我的赌博历史？", "我如何监控我的赌博行为？"],
        "responses": [
            "未来的更新将包括活动报告，以帮助您跟踪和监控赌博行为。",
            "我们计划提供有关您赌博活动的详细报告，以支持负责任的游戏。",
            "您将能够通过您的账户访问和查看赌博历史，了解未来的更新。"
        ]
    },
    {
        "type": 6, 
        "queries": ["如果我无法控制我的赌博怎么办？", "在哪里可以获得对无法控制赌博的帮助？", "我如何管理强迫性赌博？"],
        "responses": [
            "如果您发现自己难以控制赌博，请寻求像Gamblers Anonymous这样的专业组织的帮助。",
            "向支持小组和专业服务寻求帮助，以处理强迫性赌博问题。",
            "如果您在控制赌博方面遇到困难，重要的是向专门的组织和专业人士寻求帮助。"
        ]
    },

    // Customer Support

    {
        "type": 7, 
        "queries": ["我如何联系客户支持？", "客户支持的电子邮件是什么？", "我如何联系你的支持团队？"],
        "responses": [
            "您可以通过发送电子邮件至 " + info.email + " 联系我们的客户支持团队。",
            "如需支持，请通过电子邮件联系 " + info.email + "。",
            "请通过电子邮件联系 " + info.email + " 与我们的支持团队沟通。"
        ]
    },
    {
        "type": 7, 
        "queries": ["是否有客户支持的电话号码？", "我可以打电话寻求帮助吗？", "你们提供电话支持吗？"],
        "responses": [
            "您可以通过拨打 " + info.phone + " 联系我们的支持团队。",
            "如需电话支持，请拨打 " + info.phone + "。",
            "请通过拨打 " + info.phone + " 联系我们的客户支持团队获取帮助。"
        ]
    },
    {
        "type": 7, 
        "queries": ["客户支持的工作时间是什么？", "客户支持什么时候可用？", "我可以在什么时间联系支持？"],
        "responses": [
            "我们的客户支持服务全天候提供。",
            "您可以随时联系客户支持，我们的服务是24/7的。",
            "客户支持服务全天候提供，24小时不间断。"
        ]
    },
    {
        "type": 7, 
        "queries": ["从支持团队那里得到回复需要多长时间？", "客户支持的响应时间是多少？", "支持团队会多快回复？"],
        "responses": [
            "我们的客户支持团队通常会在24小时内回复。",
            "您可以期待在24小时内收到我们的支持团队的回复。",
            "我们致力于在24小时内回复所有支持请求。"
        ]
    },
    {
        "type": 7, 
        "queries": ["我可以与客户支持进行在线聊天吗？", "你们提供实时聊天支持吗？", "是否有在线聊天的客户支持选项？"],
        "responses": [
            "目前我们不提供实时聊天支持，但您可以通过电子邮件联系 " + info.email + "。",
            "目前没有实时聊天支持。请通过电子邮件与我们联系。",
            "我们现在没有实时聊天支持。请通过电子邮件与我们联系。"
        ]
    },
    {
        "type": 7, 
        "queries": ["我如何提交支持请求？", "是否有支持票据系统？", "我可以打开支持票吗？"],
        "responses": [
            "要提交支持请求，请将您的问题详细信息通过电子邮件发送至 " + info.email + "。",
            "我们使用基于电子邮件的支持系统。请将您的询问发送至 " + info.email + "。",
            "如需支持，请将您的问题通过电子邮件发送至 " + info.email + "，我们的团队将协助您。"
        ]
    },
    {
        "type": 7, 
        "queries": ["我在支持请求中应该包含哪些信息？", "我应该如何向支持团队描述我的问题？", "支持请求需要哪些详细信息？"],
        "responses": [
            "请在支持请求中包含您的账户信息、问题描述以及任何相关的截图。",
            "联系支持时，请提供您的账户信息、问题的清晰描述以及任何相关的截图。",
            "请在支持请求中包含您的账户信息、问题的详细解释以及任何相关的截图。"
        ]
    },
    {
        "type": 7, 
        "queries": ["我可以通过客户支持申请退款吗？", "我如何获得退款？", "是否可以申请退款？"],
        "responses": [
            "目前退款不可用，因为我们不处理支付。未来的更新将会处理此问题。",
            "由于我们目前不处理支付，退款不可用。这可能会在未来的更新中改变。",
            "由于我们现在不处理支付，退款不可用。未来的更新将提供更多选项。"
        ]
    },
    {
        "type": 7, 
        "queries": ["我如何升级我的支持问题？", "我可以与经理沟通吗？", "如果我需要更多帮助怎么办？"],
        "responses": [
            "如果您需要升级问题，请通过电子邮件联系 " + info.email + " 并请求进一步协助。",
            "如需升级，请通过电子邮件联系 " + info.email + " 并请求更高级别的协助。",
            "请通过电子邮件联系 " + info.email + " 并请求升级，如果您的问题需要额外支持。"
        ]
    },
    {
        "type": 7, 
        "queries": ["你们有FAQ部分吗？", "在哪里可以找到常见问题的答案？", "是否有常见问题的知识库？"],
        "responses": [
            "目前我们没有FAQ部分，但我们的支持团队可以通过电子邮件回答您的问题。",
            "我们正在制作FAQ部分。现在，请通过电子邮件联系 " + info.email + " 解决任何问题。",
            "FAQ部分计划在未来更新中推出。与此同时，请通过电子邮件与支持团队联系。"
        ]
    },
    {
        "type": 7, 
        "queries": ["客户支持提供哪些语言服务？", "支持是否提供多种语言？", "我可以用其他语言获得支持吗？"],
        "responses": [
            "目前，支持服务仅提供英语。我们计划在未来添加更多语言。",
            "我们的支持团队目前提供英语服务。未来的更新可能会包括更多语言。",
            "目前提供英语支持。我们计划在未来支持更多语言。"
        ]
    },
    {
        "type": 7, 
        "queries": ["我可以对服务提供反馈吗？", "我如何提供反馈？", "我可以在哪里提交我的建议？"],
        "responses": [
            "我们欢迎您的反馈！请将您的建议通过电子邮件发送至 " + info.email + "。",
            "请随时将您的反馈和建议发送至 " + info.email + "。",
            "您的反馈对我们很重要。请通过电子邮件将您的意见发送至 " + info.email + "。"
        ]
    },
    {
        "type": 7, 
        "queries": ["我如何更新我的联系信息？", "我可以更改我的电子邮件或电话号码吗？", "我如何修改我的联系详情？"],
        "responses": [
            "要更新您的联系信息，请通过电子邮件将您的新信息发送至 " + info.email + "。",
            "您可以通过发送电子邮件至 " + info.email + " 更改您的联系信息。",
            "请通过电子邮件联系 " + info.email + " 来更新您的联系信息。"
        ]
    },
    {
        "type": 7, 
        "queries": ["我可以追踪我的支持请求的状态吗？", "我如何知道我的问题是否正在解决？", "是否有办法跟进我的支持请求？"],
        "responses": [
            "您可以通过电子邮件联系 " + info.email + " 来跟进您的支持请求，并提及您的初始询问。",
            "如需了解支持请求的状态，请发送电子邮件至 " + info.email + " 并提及您的原始问题。",
            "通过联系 " + info.email + " 并提供您的初始询问详情，您可以追踪您的支持请求。"
        ]
    },
    {
        "type": 7, 
        "queries": ["我如何关闭我的账户？", "我可以删除我的账户吗？", "我如何永久删除我的账户？"],
        "responses": [
            "要关闭您的账户，请将您的请求通过电子邮件发送至 " + info.email + "。",
            "您可以通过发送请求至 " + info.email + " 删除您的账户。",
            "请通过电子邮件联系 " + info.email + " 来永久删除您的账户。"
        ]
    },
    {
        "type": 7, 
        "queries": ["如果我忘记了密码怎么办？", "我如何重置密码？", "我可以恢复忘记的密码吗？"],
        "responses": [
            "如果您忘记了密码，请发送电子邮件至 " + info.email + " 寻求重置密码的帮助。",
            "要重置您的密码，请联系支持团队，电子邮件地址是 " + info.email + "。",
            "忘记密码了？请发送电子邮件至 " + info.email + " 寻求恢复帮助。"
        ]
    },
    {
        "type": 7, 
        "queries": ["你们提供技术问题的支持吗？", "你们能帮忙解决技术问题吗？", "如果我遇到技术问题该怎么办？"],
        "responses": [
            "是的，我们的支持团队可以协助解决技术问题。请将问题详情通过电子邮件发送至 " + info.email + "。",
            "如需技术支持，请联系 " + info.email + " 并描述您的问题。",
            "我们提供技术问题的帮助。请将您的技术问题通过电子邮件发送至 " + info.email + "。"
        ]
    },
    {
        "type": 7, 
        "queries": ["是否可以对应用程序提供反馈？", "我如何建议改进？", "我可以在哪里提交应用反馈？"],
        "responses": [
            "我们很感激您对应用程序的反馈。请将您的建议通过电子邮件发送至 " + info.email + "。",
            "将您的应用改进建议发送至 " + info.email + "。",
            "我们重视您的意见。请通过电子邮件将您的应用反馈发送至 " + info.email + "。"
        ]
    },
    {
        "type": 7, 
        "queries": ["我可以申请新功能吗？", "我如何建议新功能？", "是否可以提出应用的新功能？"],
        "responses": [
            "要建议新功能，请将您的想法通过电子邮件发送至 " + info.email + "。",
            "我们欢迎功能请求。请将您的建议发送至 " + info.email + "。",
            "通过电子邮件将您的新功能提案发送至 " + info.email + "。"
        ]
    },
    {
        "type": 7, 
        "queries": ["我如何报告一个漏洞？", "我可以报告应用中的问题吗？", "我应该在哪里提交漏洞报告？"],
        "responses": [
            "要报告一个漏洞，请将问题的详细描述通过电子邮件发送至 " + info.email + "。",
            "通过发送电子邮件至 " + info.email + " 报告任何问题或漏洞。",
            "如需报告漏洞，请将问题详细信息发送至 " + info.email + "。"
        ]
    },

    // Roulette

    {
        "type": 8, 
        "queries": ["什么是轮盘？", "你能解释一下轮盘吗？", "轮盘怎么玩？", "轮盘"],
        "responses": [
            "轮盘是一种赌场游戏，玩家下注球会落在旋转轮盘上的哪个数字或颜色上。",
            "在轮盘中，玩家下注一个数字、一组数字或颜色，然后将一个球旋转在轮盘上，以确定获胜的结果。",
            "轮盘游戏通过对轮盘上的数字或颜色进行投注来进行。球的落点决定赢家。"
        ]
    },
    {
        "type": 8, 
        "queries": ["轮盘中有哪些不同的投注类型？", "你能解释一下轮盘中的投注选项吗？", "在轮盘中可以进行哪些类型的投注？"],
        "responses": [
            "在轮盘中，您可以进行内部投注（特定数字或小组数字）和外部投注（较大组数字、颜色、奇偶）。",
            "轮盘投注包括内部投注，如单一数字、分割、街区和角落，以及外部投注，如红/黑、奇/偶和高/低。",
            "您可以对特定数字或组合进行内部投注，对较大数字组、颜色或奇偶进行外部投注。"
        ]
    },
    {
        "type": 8, 
        "queries": ["欧洲轮盘和美国轮盘有什么区别？", "欧洲轮盘和美国轮盘有什么不同？", "你能解释一下欧洲轮盘和美国轮盘的区别吗？"],
        "responses": [
            "欧洲轮盘有一个单零，而美国轮盘有一个单零和一个双零。",
            "主要区别在于欧洲轮盘有37个口袋（1-36 和单零），而美国轮盘有38个口袋（1-36、单零和双零）。",
            "欧洲轮盘有一个零，使得赌场优势低于美国轮盘，美国轮盘有额外的双零。"
        ]
    },
    {
        "type": 8, 
        "queries": ["轮盘中的赌场优势是多少？", "轮盘中的赌场优势如何计算？", "轮盘中的赌场优势是多少？"],
        "responses": [
            "欧洲轮盘的赌场优势约为2.7%，因为有一个单零，而美国轮盘的赌场优势约为5.26%，因为有一个双零。",
            "在欧洲轮盘中，赌场优势大约为2.7%，而在美国轮盘中，由于额外的双零，赌场优势约为5.26%。",
            "轮盘中的赌场优势取决于版本：欧洲轮盘的优势为2.7%，而美国轮盘的优势为5.26%。"
        ]
    },
    {
        "type": 8, 
        "queries": ["如何在轮盘中下注？", "你能解释一下如何在轮盘中下注吗？", "轮盘中的下注流程是什么？"],
        "responses": [
            "要在轮盘中下注，选择筹码大小并点击与您所需投注相对应的投注区域。",
            "在轮盘中，您可以通过选择筹码大小并点击您希望投注的具体数字、组或颜色来下注。",
            "要在轮盘中下注，选择您的筹码大小并将其放置在投注布局上，以表示您的选择的数字或选项。"
        ]
    },
    {
        "type": 8, 
        "queries": ["什么是轮盘中的内部投注？", "你能描述一下轮盘中的内部投注吗？", "轮盘中的内部投注有哪些类型？"],
        "responses": [
            "轮盘中的内部投注是对投注布局上的特定数字或小组数字进行的投注。",
            "内部投注的例子包括单一数字、分割（两个数字）、街区（三个数字）和角落（四个数字）。",
            "内部投注涉及对轮盘上的特定数字或组合进行投注，如单一数字、分割、街区和角落。"
        ]
    },
    {
        "type": 8, 
        "queries": ["什么是轮盘中的外部投注？", "你能描述一下轮盘中的外部投注吗？", "轮盘中的外部投注有哪些类型？"],
        "responses": [
            "轮盘中的外部投注是对数字网格外的大组数字、颜色或奇偶进行的投注。",
            "外部投注的例子包括红/黑、奇/偶、高/低、12组和列。",
            "外部投注涉及对更广泛的类别进行投注，如颜色、奇偶，以及如12组或列等数字组。"
        ]
    },
    {
        "type": 8, 
        "queries": ["什么是轮盘中的单一数字投注？", "你能解释一下轮盘中的单一数字投注吗？", "轮盘中的单一数字投注是什么意思？"],
        "responses": [
            "轮盘中的单一数字投注是对单个数字的投注。它的赔率为35比1。",
            "在轮盘中，单一数字投注是将筹码直接放在一个特定的数字上。如果球落在那个数字上，您将赢得35倍的投注额。",
            "单一数字投注涉及对轮盘上的一个特定数字进行投注。这种投注的赔率是35比1。"
        ]
    },
    {
        "type": 8, 
        "queries": ["什么是轮盘中的分割投注？", "你能解释一下轮盘中的分割投注吗？", "轮盘中的分割投注是什么意思？"],
        "responses": [
            "轮盘中的分割投注是对两个相邻数字进行的投注。分割投注的赔率为17比1。",
            "在轮盘中，分割投注是将筹码放在两个数字之间的线上，投注球将落在其中一个数字上。赔率为17比1。",
            "分割投注涉及对两个相邻数字进行投注，将筹码放在它们之间的线上的投注。赔率为17比1。"
        ]
    },
    {
        "type": 8, 
        "queries": ["什么是轮盘中的街区投注？", "你能解释一下轮盘中的街区投注吗？", "轮盘中的街区投注是什么意思？"],
        "responses": [
            "轮盘中的街区投注是对一行中的三个连续数字进行的投注。街区投注的赔率为11比1。",
            "在轮盘中，街区投注是将筹码放在一行三个数字的边缘上，投注球将落在其中一个数字上。赔率为11比1。",
            "街区投注是对横向排列的三个数字进行投注。这种投注的赔率是11比1。"
        ]
    },
    {
        "type": 8, 
        "queries": ["什么是轮盘中的角落投注？", "你能解释一下轮盘中的角落投注吗？", "轮盘中的角落投注是什么意思？"],
        "responses": [
            "轮盘中的角落投注是对形成正方形的四个数字进行的投注。角落投注的赔率为8比1。",
            "在轮盘中，角落投注是将筹码放在四个数字交点上，投注球将落在其中任何一个数字上。赔率为8比1。",
            "角落投注是对四个相邻的数字进行投注，这些数字形成一个正方形。这种投注的赔率是8比1。"
        ]
    },
    {
        "type": 8, 
        "queries": ["什么是轮盘中的线投注？", "你能解释一下轮盘中的线投注吗？", "轮盘中的线投注是什么意思？"],
        "responses": [
            "轮盘中的线投注是对六个连续的数字进行的投注，这些数字形成两行。线投注的赔率为5比1。",
            "在轮盘中，线投注是将筹码放在两行每行三个数字的边缘上，投注球将落在其中任何一个数字上。赔率为5比1。",
            "线投注是对六个形成两行的数字进行投注。这种投注的赔率是5比1。"
        ]
    },
    {
        "type": 8, 
        "queries": ["什么是轮盘中的红/黑投注？", "你能解释一下轮盘中的红/黑投注吗？", "轮盘中的红/黑投注是什么意思？"],
        "responses": [
            "轮盘中的红/黑投注是对球会落在红色还是黑色数字上进行的外部投注。赔率为1比1。",
            "在轮盘中，红/黑投注是对球落在红色或黑色数字上的颜色进行投注。赔率为1比1。",
            "红/黑投注是对获胜数字的颜色进行投注。这种投注的赔率为1比1。"
        ]
    },
    {
        "type": 8, 
        "queries": ["什么是轮盘中的奇/偶投注？", "你能解释一下轮盘中的奇/偶投注吗？", "轮盘中的奇/偶投注是什么意思？"],
        "responses": [
            "轮盘中的奇/偶投注是对获胜数字是奇数还是偶数进行的外部投注。赔率为1比1。",
            "在轮盘中，奇/偶投注是对球落在奇数或偶数数字上进行的投注。赔率为1比1。",
            "奇/偶投注是对获胜数字是奇数还是偶数进行投注。这种投注的赔率为1比1。"
        ]
    },
    {
        "type": 8, 
        "queries": ["什么是轮盘中的高/低投注？", "你能解释一下轮盘中的高/低投注吗？", "轮盘中的高/低投注是什么意思？"],
        "responses": [
            "轮盘中的高/低投注是对获胜数字在低范围（1-18）还是高范围（19-36）进行的外部投注。赔率为1比1。",
            "在轮盘中，高/低投注是对球落在低数字（1-18）还是高数字（19-36）进行的投注。赔率为1比1。",
            "高/低投注是对获胜数字在低范围（1-18）还是高范围（19-36）进行投注。这种投注的赔率为1比1。"
        ]
    },
    {
        "type": 8, 
        "queries": ["什么是轮盘中的12组投注？", "你能解释一下轮盘中的12组投注吗？", "轮盘中的12组投注是什么意思？"],
        "responses": [
            "轮盘中的12组投注是对三组12个数字中的一个进行的外部投注：1-12、13-24或25-36。赔率为2比1。",
            "在轮盘中，12组投注是对三个12个数字的集合中的一个进行投注。赔率为2比1。",
            "12组投注是对三个12个连续数字组中的一个进行投注。这种投注的赔率为2比1。"
        ]
    },
    {
        "type": 8, 
        "queries": ["什么是轮盘中的列投注？", "你能解释一下轮盘中的列投注吗？", "轮盘中的列投注是什么意思？"],
        "responses": [
            "轮盘中的列投注是对投注布局上三个垂直列中的一个进行的外部投注。赔率为2比1。",
            "在轮盘中，列投注是对三个垂直列中的一个进行投注。赔率为2比1。",
            "列投注是对投注布局上某一列的所有十二个数字进行投注。这种投注的赔率为2比1。"
        ]
    },
    {
        "type": 8, 
        "queries": ["什么是轮盘中的拉帕塔日规则？", "你能解释一下轮盘中的拉帕塔日规则吗？", "拉帕塔日规则是如何工作的？"],
        "responses": [
            "拉帕塔日规则允许玩家在球落在零上时取回他们的一半偶数投注。",
            "在轮盘中，拉帕塔日规则意味着如果球落在零上，玩家将失去一半的偶数投注，另一半则退还给玩家。",
            "拉帕塔日是欧洲轮盘中的一项规则，玩家在球落在零上时可以取回他们的一半偶数投注。"
        ]
    },
    {
        "type": 8, 
        "queries": ["什么是轮盘中的监禁规则？", "你能解释一下轮盘中的监禁规则吗？", "监禁规则是如何工作的？"],
        "responses": [
            "监禁规则允许玩家在球落在零上时将他们的偶数投注保留到下一轮。如果下一轮获胜，玩家将取回他们的投注。",
            "在轮盘中，监禁规则意味着如果球落在零上，偶数投注将被“监禁”到下一轮。如果投注在下一轮获胜，玩家将取回投注。",
            "监禁规则是欧洲轮盘中的一项规则，如果球落在零上，偶数投注将保留在桌上进行下一轮，给玩家另一个获胜机会。"
        ]
    },
    {
        "type": 8, 
        "queries": ["什么是美国轮盘中的五个数字投注？", "你能解释一下美国轮盘中的五个数字投注吗？", "美国轮盘中的五个数字投注是什么意思？"],
        "responses": [
            "美国轮盘中的五个数字投注是对0、00、1、2和3进行的投注。该投注的赔率为6比1。",
            "在美国轮盘中，五个数字投注是对0、00、1、2和3进行的投注。赔率为6比1。",
            "五个数字投注是对美国轮盘中的0、00、1、2和3进行的投注。该投注的赔率为6比1。"
        ]
    },
    {
        "type": 8, 
        "queries": ["我如何在轮盘中获胜？", "轮盘中有哪些获胜策略？", "你能给些轮盘获胜的建议吗？"],
        "responses": [
            "在轮盘中获胜涉及运气，但像投注外部投注（红/黑、奇/偶）这样的策略可以提高获得小额赢利的机会。",
            "没有保证获胜的轮盘策略，但管理好您的资金并进行外部投注可以帮助您在游戏中坚持更久。",
            "轮盘主要是一个运气游戏。虽然有像马丁格尔系统这样的策略，但没有方法能保证获胜。"
        ]
    },

    // Blackjack

    {
        "type": 9,
        "queries": ["什么是黑杰克？", "你能解释一下黑杰克吗？", "如何玩黑杰克？", "黑杰克"],
        "responses": [
            "黑杰克是一种卡牌游戏，玩家试图使手牌的点数尽可能接近21点，但不得超过21点。",
            "在黑杰克中，目标是通过拥有比庄家的手牌点数更高而不超过21点来击败庄家。",
            "黑杰克的玩法是尽力将手牌总点数达到21点或更接近21点，而不超过21点。"
        ]
    },
    {
        "type": 9,
        "queries": ["黑杰克的基本规则是什么？", "你能解释一下黑杰克的规则吗？", "如何玩标准的黑杰克游戏？"],
        "responses": [
            "在黑杰克中，每位玩家会被发两张牌，他们可以选择要牌（再抽一张牌）或停牌（保留当前手牌），以尽可能接近21点。",
            "庄家也会参与游戏，但必须遵循特定规则：庄家点数未达到17点时必须要牌。玩家赢得的条件是手牌点数接近21点但不得超过21点，并且比庄家的点数更高。",
            "黑杰克的玩法包括玩家收到两张牌，然后决定是要牌还是停牌。庄家必须在手牌达到17点或以上时停牌。目标是击败庄家的手牌而不超过21点。"
        ]
    },
    {
        "type": 9,
        "queries": ["什么是自然黑杰克？", "你能解释一下什么是自然黑杰克吗？", "什么叫自然黑杰克？"],
        "responses": [
            "自然黑杰克是指玩家在首两张牌中被发到一张A和一张10点值的牌，总点数为21。",
            "在黑杰克中，自然黑杰克指的是玩家首两张牌中有一张A和一张10点牌、杰克、皇后或国王，总点数为21。",
            "自然黑杰克意味着初始手牌为一张A和一张10点值的牌，总点数为21。"
        ]
    },
    {
        "type": 9,
        "queries": ["黑杰克中的牌值是多少？", "你能解释一下黑杰克中牌的值吗？", "黑杰克中牌的点数如何计算？"],
        "responses": [
            "在黑杰克中，数字牌的点数等于其面值，面牌（杰克、皇后、国王）点数为10，A的点数可以是1或11。",
            "黑杰克中牌的点数如下：2-10点的牌按面值计算，面牌点数为10，A可以是1点或11点。",
            "黑杰克中的牌值为：2-10点的牌按其面值计算，面牌的点数为10，A可以根据玩家的选择计为1点或11点。"
        ]
    },
    {
        "type": 9,
        "queries": ["黑杰克中的要牌是什么意思？", "你能解释一下黑杰克中的要牌吗？", "黑杰克中的要牌是什么？"],
        "responses": [
            "在黑杰克中，要牌意味着请求再发一张牌以增加手牌的总点数。",
            "在黑杰克中，要牌是指向庄家请求一张额外的牌，以期改善手牌的点数。",
            "在黑杰克中，要牌就是向庄家请求一张额外的牌来增加手牌的总点数。"
        ]
    },
    {
        "type": 9,
        "queries": ["黑杰克中的停牌是什么意思？", "你能解释一下黑杰克中的停牌吗？", "黑杰克中的停牌是什么？"],
        "responses": [
            "在黑杰克中，停牌意味着保留当前手牌并结束你的回合。",
            "在黑杰克中，停牌是决定不再要牌，保持当前的手牌总点数。",
            "在黑杰克中，停牌就是选择不再要牌，保留你当前的手牌。"
        ]
    },
    {
        "type": 9,
        "queries": ["黑杰克中的加倍下注是什么意思？", "你能解释一下黑杰克中的加倍下注吗？", "黑杰克中的加倍下注是什么？"],
        "responses": [
            "在黑杰克中，加倍下注允许你将初始赌注加倍，以换取只发一张额外的牌。",
            "在黑杰克中，加倍下注是指将你的赌注加倍，并在决定停牌前再抽一张牌。",
            "在黑杰克中，加倍下注意味着将原始赌注增加100%，并承诺再抽一张额外的牌。"
        ]
    },
    {
        "type": 9,
        "queries": ["黑杰克中的分牌是什么意思？", "你能解释一下黑杰克中的分牌吗？", "黑杰克中的分牌是什么？"],
        "responses": [
            "在黑杰克中，分牌允许你将一对牌分成两手，每手有自己的赌注。",
            "在黑杰克中，分牌是指将一对相同的牌分成两手，并分别打两手牌，每手有独立的赌注。",
            "在黑杰克中，分牌是将两张相同的牌分开成两手，然后分别玩每手牌，每手牌的赌注相等。"
        ]
    },
    {
        "type": 9,
        "queries": ["黑杰克中的保险是什么意思？", "你能解释一下黑杰克中的保险吗？", "黑杰克中的保险是什么？"],
        "responses": [
            "在黑杰克中，保险是一种边注，当庄家的明牌是A时，可以下注以防庄家有自然黑杰克。",
            "在黑杰克中，保险是一种最多为你原始赌注一半的边注，赌庄家的暗牌会给庄家带来黑杰克。",
            "在黑杰克中，选择保险意味着下注庄家的暗牌是10点值的牌，从而形成黑杰克。"
        ]
    },
    {
        "type": 9,
        "queries": ["黑杰克中的和局是什么意思？", "你能解释一下黑杰克中的和局吗？", "黑杰克中的和局是什么？"],
        "responses": [
            "在黑杰克中，和局是指玩家的手牌和庄家的手牌总点数相同，导致平局。",
            "在黑杰克中，和局意味着回合以玩家和庄家之间的平局结束，玩家的赌注会被退回。",
            "在黑杰克中，和局是指玩家和庄家的手牌点数相同，没有赢或输。"
        ]
    },
    {
        "type": 9,
        "queries": ["黑杰克中的庄家规则是什么？", "你能解释一下黑杰克中的庄家规则吗？", "庄家在黑杰克中必须遵循什么规则？"],
        "responses": [
            "庄家必须要牌直到手牌总点数达到17点或更高，并且在所有17点时停牌。",
            "在黑杰克中，庄家必须在手牌总点数为16点或以下时要牌，并且在手牌总点数为17点或以上时停牌。",
            "庄家的规则包括要牌直到手牌总点数达到17点或更多，并在17点时停牌。"
        ]
    },
    {
        "type": 9,
        "queries": ["黑杰克中的软手是什么意思？", "你能解释一下黑杰克中的软手吗？", "黑杰克中的软手是什么？"],
        "responses": [
            "在黑杰克中，软手包含一张被计算为11点的A，使手牌更加灵活。",
            "在黑杰克中，软手包括一张A，可以计为1点或11点，如A-6为软17。",
            "软手意味着手牌包含一张计为11点的A，使其总点数更加灵活，可以根据需要调整。"
        ]
    },
    {
        "type": 9,
        "queries": ["黑杰克中的硬手是什么意思？", "你能解释一下黑杰克中的硬手吗？", "黑杰克中的硬手是什么？"],
        "responses": [
            "在黑杰克中，硬手不包含被计算为11点的A，或者A被计算为1点以避免爆牌。",
            "在黑杰克中，硬手是指没有A或者A被计算为1点以防手牌总点数超过21点。",
            "硬手意味着手牌没有A被计算为11点，使得总点数固定，不可调整。"
        ]
    },
    {
        "type": 9,
        "queries": ["黑杰克中的爆牌是什么意思？", "你能解释一下黑杰克中的爆牌吗？", "黑杰克中的爆牌是什么？"],
        "responses": [
            "在黑杰克中，爆牌是指手牌的总点数超过21点，导致自动输掉。",
            "在黑杰克中，爆牌是指你的手牌点数超过21点，使你立即输掉这一轮。",
            "爆牌意味着手牌的总点数超过21点，导致立即输掉这一轮。"
        ]
    },
    {
        "type": 9,
        "queries": ["黑杰克中的投降选项是什么？", "你能解释一下黑杰克中的投降选项吗？", "黑杰克中的投降是什么意思？"],
        "responses": [
            "投降选项允许你放弃手牌，以换取返还一半的赌注。",
            "在黑杰克中，投降是指放弃你的手牌，并收回你原始赌注的一半。",
            "在黑杰克中，投降意味着提前放弃手牌并获得一半的赌注返还。"
        ]
    },
    {
        "type": 9,
        "queries": ["黑杰克中的硬17是什么？", "你能解释一下黑杰克中的硬17吗？", "黑杰克中的硬17是什么意思？"],
        "responses": [
            "硬17在黑杰克中是指总点数为17而没有A被计算为11点的手牌，如10-7。",
            "在黑杰克中，硬17意味着手牌总点数为17而没有A作为11点，例如9-8。",
            "硬17是指总点数为17的手牌，没有使用A作为11点，因此总点数固定。"
        ]
    },
    {
        "type": 9,
        "queries": ["黑杰克中的软17是什么？", "你能解释一下黑杰克中的软17吗？", "黑杰克中的软17是什么意思？"],
        "responses": [
            "软17在黑杰克中是指包含一张计算为11点的A的手牌，如A-6。",
            "在黑杰克中，软17包括一张计算为11点的A，如A-6，使手牌更加灵活。",
            "软17意味着手牌包含一张A被计算为11点，总点数为17，可以根据抽到的新牌进行调整。"
        ]
    },
    {
        "type": 9,
        "queries": ["黑杰克中的最佳策略是什么？", "你能解释一下黑杰克中的最佳策略吗？", "在黑杰克中应该使用什么策略？"],
        "responses": [
            "黑杰克的最佳策略包括根据庄家的明牌了解何时要牌、停牌、加倍下注或分牌。",
            "有效的黑杰克策略包括使用基础策略图表，根据你的手牌和庄家的明牌做出最佳决策。",
            "要提高在黑杰克中的胜率，可以使用策略如基础策略图表、管理你的筹码，并理解何时投保或投降。"
        ]
    },
    {
        "type": 9,
        "queries": ["黑杰克中的计牌是什么？", "你能解释一下黑杰克中的计牌吗？", "黑杰克中的计牌是如何工作的？"],
        "responses": [
            "计牌在黑杰克中是一种策略，玩家跟踪高点牌和低点牌，以预测有利牌出现的可能性。",
            "在黑杰克中，计牌涉及给牌分配点数并保持一个运行总数，以估算剩余牌中的高点牌和低点牌的比例。",
            "计牌是一种技术，用于跟踪牌的分布，以改进决策和赌注的大小。"
        ]
    },
    {
        "type": 9,
        "queries": ["黑杰克中的计牌是否合法？", "你能解释一下黑杰克中的计牌的合法性吗？", "在黑杰克中计牌被允许吗？"],
        "responses": [
            "计牌并不违法，但赌场可以禁止他们怀疑正在计牌的玩家。",
            "在黑杰克中，计牌是合法的，但赌场保留拒绝服务或要求怀疑计牌的玩家离开的权利。",
            "虽然计牌不违法，但赌场通常会采取措施防止计牌，并可能移除被发现计牌的玩家。"
        ]
    },

    // Slots

    {
        "type": 10, 
        "queries": ["什么是老虎机游戏？", "你能解释一下老虎机游戏是什么吗？", "老虎机游戏是如何工作的？", "老虎机"],
        "responses": [
            "老虎机游戏是赌场游戏，涉及旋转带有各种符号的转轴。玩家通过匹配特定的符号组合来赢取奖金。",
            "在老虎机游戏中，玩家旋转转轴，旨在匹配支付线上的符号以赢得奖品。",
            "老虎机游戏通过旋转带有不同符号的转轴工作，玩家通过在活动支付线上获得匹配符号来赢得奖励。"
        ]
    },
    {
        "type": 10, 
        "queries": ["老虎机中的支付线是什么？", "你能解释一下老虎机中的支付线是什么吗？", "支付线在老虎机中是如何工作的？"],
        "responses": [
            "老虎机中的支付线是穿过转轴的线，决定了基于匹配符号的获胜组合。",
            "在老虎机游戏中，支付线是符号必须按照特定模式排列才能获胜的线路，可以是水平、垂直或对角线。",
            "老虎机中的支付线是符号必须出现在上面的线路上才能获胜，它们的数量和模式因游戏而异。"
        ]
    },
    {
        "type": 10, 
        "queries": ["什么是3轴老虎机？", "你能解释一下什么是3轴老虎机游戏吗？", "3轴老虎机是如何工作的？"],
        "responses": [
            "3轴老虎机是传统的老虎机游戏，具有三个旋转的转轴，通常支付线较少。",
            "在3轴老虎机中，玩家旋转三个转轴，尝试在有限数量的支付线上匹配符号，通常类似于经典老虎机。",
            "3轴老虎机游戏具有三个转轴，设计更简单，通常提供较少的支付线和直接的游戏玩法。"
        ]
    },
    {
        "type": 10, 
        "queries": ["什么是5轴老虎机？", "你能解释一下什么是5轴老虎机游戏吗？", "5轴老虎机是如何工作的？"],
        "responses": [
            "5轴老虎机是现代老虎机游戏，具有五个旋转的转轴，通常拥有更多的支付线和复杂的功能。",
            "在5轴老虎机中，玩家旋转五个转轴，通常有更多的支付线和奖金功能，提供更具吸引力的游戏体验。",
            "5轴老虎机游戏具有五个转轴，通常提供更多的支付线、奖金轮次和特殊符号，比3轴老虎机更多。"
        ]
    },
    {
        "type": 10, 
        "queries": ["老虎机中的符号是什么？", "你能解释一下老虎机中的符号吗？", "老虎机中的不同符号是什么意思？"],
        "responses": [
            "老虎机中的符号包括标准符号、野符号、散布符号和奖金符号，每种符号都有不同的功能。",
            "在老虎机中，标准符号组成获胜组合，野符号替代其他符号，散布符号触发免费旋转，奖金符号激活特殊功能。",
            "老虎机中的不同符号具有不同的作用：标准符号创建获胜，野符号作为替代，散布符号触发奖金，奖金符号启动特殊回合。"
        ]
    },
    {
        "type": 10, 
        "queries": ["什么是老虎机中的野符号？", "你能解释一下老虎机中的野符号是什么吗？", "野符号在老虎机中是如何工作的？"],
        "responses": [
            "老虎机中的野符号可以替代其他符号，以帮助形成获胜组合。",
            "在老虎机游戏中，野符号充当万能符号，替代其他符号（除散布符号和奖金符号外）来创建获胜线。",
            "老虎机中的野符号可以代替其他符号，使完成获胜支付线变得更容易。"
        ]
    },
    {
        "type": 10, 
        "queries": ["什么是老虎机中的散布符号？", "你能解释一下老虎机中的散布符号是什么吗？", "散布符号在老虎机中是如何工作的？"],
        "responses": [
            "老虎机中的散布符号通常在转轴上的任意位置出现时触发奖金功能或免费旋转。",
            "在老虎机游戏中，散布符号无需在支付线上出现即可获胜，并且通常激活特殊功能或奖金轮次。",
            "散布符号在老虎机中可以出现在任何位置，通常当出现足够数量时会触发免费旋转或奖金游戏。"
        ]
    },
    {
        "type": 10, 
        "queries": ["什么是老虎机中的奖金轮次？", "你能解释一下老虎机中的奖金轮次是什么吗？", "奖金轮次在老虎机中是如何工作的？"],
        "responses": [
            "老虎机中的奖金轮次是提供额外获胜机会的特殊功能，通常具有独特的游戏机制。",
            "在老虎机游戏中，奖金轮次通过特定符号或组合触发，可能包括免费旋转、选择奖励游戏或倍数。",
            "老虎机中的奖金轮次提供额外的游戏机会，例如免费旋转或迷你游戏，通常通过出现特定符号触发。"
        ]
    },
    {
        "type": 10, 
        "queries": ["什么是老虎机中的免费旋转？", "你能解释一下老虎机中的免费旋转是什么吗？", "免费旋转在老虎机中是如何工作的？"],
        "responses": [
            "老虎机中的免费旋转是奖金轮次，玩家可以在不使用自己信用的情况下旋转转轴，通常有机会赢得真实奖金。",
            "在老虎机游戏中，免费旋转通过特定的符号组合获得，让玩家可以免费旋转并保留赢得的奖励。",
            "老虎机中的免费旋转是一种奖金功能，给予玩家额外的旋转机会而不需要支付费用，通常由散布符号触发。"
        ]
    },
    {
        "type": 10, 
        "queries": ["什么是老虎机中的RTP？", "你能解释一下老虎机中的RTP是什么吗？", "RTP在老虎机中是什么意思？"],
        "responses": [
            "老虎机中的RTP代表玩家回报率，是一个百分比，表示老虎机在长期内预计将返还给玩家的投注金额。",
            "在老虎机游戏中，RTP是游戏的理论支付率，代表玩家投注的期望回报率。",
            "RTP或玩家回报率是衡量老虎机长期支付给玩家金额的指标，以百分比形式表示。"
        ]
    },
    {
        "type": 10, 
        "queries": ["什么是老虎机中的波动性？", "你能解释一下老虎机中的波动性是什么吗？", "波动性在老虎机中是什么意思？"],
        "responses": [
            "老虎机中的波动性指的是游戏的风险水平，表示玩家可以期望赢得的频率和金额。",
            "在老虎机游戏中，波动性测量支付的频率和规模：高波动性意味着更大的但更少频繁的赢利，低波动性则意味着较小但更频繁的赢利。",
            "老虎机中的波动性表示支付的性质，高波动性提供较少但更大的赢利，低波动性提供更多但较小的赢利。"
        ]
    },
    {
        "type": 10, 
        "queries": ["老虎机中的累积大奖如何工作？", "你能解释一下老虎机中的累积大奖是什么吗？", "什么是老虎机中的累积大奖？"],
        "responses": [
            "老虎机中的累积大奖是一个不断增加的大奖，随着每个投注而增长，直到有人赢得它。",
            "在老虎机游戏中，累积大奖将每个投注的一部分汇集到一个公共奖池中，奖池持续增加，直到被赢得。",
            "老虎机中的累积大奖是一个累积的奖池，每次投注都会增加，幸运的玩家有机会赢得巨额奖金。"
        ]
    },
    {
        "type": 10, 
        "queries": ["玩老虎机的最佳策略是什么？", "你能解释一下玩老虎机的最佳策略吗？", "玩老虎机时应该使用什么策略？"],
        "responses": [
            "有效的老虎机策略包括管理你的筹码，选择RTP高和波动性低的游戏，并了解支付表。",
            "在老虎机游戏中，好的策略包括设定预算，选择具有良好RTP的老虎机，了解游戏的功能和支付表。",
            "玩老虎机的策略侧重于筹码管理，选择高RTP的老虎机，并熟悉游戏规则和奖金。"
        ]
    },
    {
        "type": 10, 
        "queries": ["什么是老虎机锦标赛？", "你能解释一下老虎机锦标赛是什么吗？", "老虎机锦标赛是如何工作的？"],
        "responses": [
            "老虎机锦标赛是一个比赛，玩家在设定的时间内尝试在老虎机上赢得最多的积分或奖金。",
            "在老虎机锦标赛中，玩家相互竞争，以在指定的老虎机上积累最多的赢利或积分，通常在一定时间内进行。",
            "老虎机锦标赛涉及玩家在特定的老虎机上玩，以获得最高的分数或赢利，竞争奖品和排名。"
        ]
    },
    {
        "type": 10, 
        "queries": ["什么是视频老虎机？", "你能解释一下什么是视频老虎机吗？", "视频老虎机是如何工作的？"],
        "responses": [
            "视频老虎机是现代老虎机游戏，具有高级图形、动画和奖金功能，通常在数字屏幕上玩。",
            "在视频老虎机中，游戏涉及数字转轴，通常包括互动奖金轮次、增强的视觉效果和多条支付线。",
            "视频老虎机是传统老虎机的数字版本，提供复杂的功能、互动奖金和高质量的图形。"
        ]
    },
    {
        "type": 10, 
        "queries": ["什么是老虎机中的倍数？", "你能解释一下老虎机中的倍数是什么吗？", "倍数在老虎机中是如何工作的？"],
        "responses": [
            "老虎机中的倍数增加了获胜组合的奖金金额，例如2倍或3倍。",
            "在老虎机游戏中，倍数提高奖金的价值，通常在奖金轮次或免费旋转期间出现。",
            "老虎机中的倍数增强了获胜线路的奖金，将奖金乘以指定的倍数值。"
        ]
    },
    {
        "type": 10, 
        "queries": ["什么是老虎机中的级联转轴？", "你能解释一下老虎机中的级联转轴是什么吗？", "级联转轴在老虎机中是如何工作的？"],
        "responses": [
            "老虎机中的级联转轴会将获胜符号替换为从上方掉落的新符号，从而可能在一次旋转中创造额外的获胜机会。",
            "在老虎机游戏中，级联转轴会移除获胜符号，让新的符号落入位置，从而提供连续的获胜机会。",
            "级联转轴在老虎机中意味着获胜符号消失，新符号下落，创造多次获胜的机会。"
        ]
    },
    {
        "type": 10, 
        "queries": ["什么是老虎机中的赌场优势？", "你能解释一下老虎机中的赌场优势是什么吗？", "赌场优势在老虎机中是如何工作的？"],
        "responses": [
            "老虎机中的赌场优势是指赌场预期在长期内保留的每笔投注的一定百分比，与RTP相对。",
            "在老虎机游戏中，赌场优势是赌场的优势，代表了从玩家投注中获得的预期利润。",
            "老虎机中的赌场优势表示赌场从投注中保留的百分比，剩余百分比则支付给玩家作为奖金。"
        ]
    },
    {
        "type": 10, 
        "queries": ["什么是老虎机中的奖金购买功能？", "你能解释一下老虎机中的奖金购买功能是什么吗？", "奖金购买功能在老虎机中是如何工作的？"],
        "responses": [
            "老虎机中的奖金购买功能允许玩家直接购买进入奖金轮次的机会，而不需要等待自然触发。",
            "在老虎机游戏中，奖金购买功能让玩家支付固定金额以立即激活奖金游戏或免费旋转。",
            "奖金购买功能在老虎机中提供了以指定费用直接进入游戏奖金功能的选项。"
        ]
    },
    {
        "type": 10, 
        "queries": ["什么是老虎机中的粘性野符号？", "你能解释一下老虎机中的粘性野符号是什么吗？", "粘性野符号在老虎机中是如何工作的？"],
        "responses": [
            "老虎机中的粘性野符号在多个旋转中保持在原位，增加了形成获胜组合的机会。",
            "在老虎机游戏中，粘性野符号会在设定数量的旋转中保持在转轴上，提供更多的获胜机会。",
            "粘性野符号在老虎机中意味着野符号在多个旋转中保持在其位置，提高了获胜潜力。"
        ]
    },

    // Craps

    {
        "type": 11, 
        "queries": ["什么是掷骰子游戏？", "你能解释一下掷骰子游戏是什么吗？", "如何玩掷骰子？", "掷骰子"],
        "responses": [
            "掷骰子是一种骰子游戏，玩家下注于两个骰子的结果。游戏包含各种类型的下注，赔率各不相同。",
            "在掷骰子中，玩家轮流掷两个骰子并下注结果。游戏提供了多种下注选项，赔率各异。",
            "掷骰子是一种赌场游戏，使用两个骰子。玩家对骰子掷出的结果进行下注，有多种下注方式可供选择。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中的pass line注是什么？", "你能解释一下pass line注吗？", "pass line注在掷骰子中是如何运作的？"],
        "responses": [
            "掷骰子中的pass line注是一种下注，押注投掷者会在开始掷骰时掷出7或11获胜，或者设定一个点数后再掷出该点数而不是7。",
            "在掷骰子中，pass line注的胜利条件是，如果开始掷骰时掷出7或11则赢，掷出2、3或12则输。如果设定了点数，则需在掷出7之前再次掷出该点数以获胜。",
            "pass line注是在开始掷骰之前下注的。它在开始掷骰时如果掷出7或11则获胜，如果掷出2、3或12则输，如果在设定点数后掷出该点数而不是7则获胜。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中的don't pass注是什么？", "你能解释一下don't pass注吗？", "don't pass注在掷骰子中是如何运作的？"],
        "responses": [
            "掷骰子中的don't pass注是一种下注，押注投掷者会在开始掷骰时掷出2或3获胜，7或11则输。12则推迟，设定点数后如果掷出7则获胜。",
            "在掷骰子中，don't pass注的胜利条件是，如果开始掷骰时掷出2或3则赢，掷出7或11则输，掷出12则推迟。如果设定点数，则需在掷出7之前掷出7以获胜。",
            "don't pass注与pass line注相反，在开始掷骰时掷出2或3获胜，掷出7或11则输，掷出12则推迟。如果设定了点数，则需在掷出7之前掷出7以获胜。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中的come注是什么？", "你能解释一下come注吗？", "come注在掷骰子中是如何运作的？"],
        "responses": [
            "掷骰子中的come注类似于pass line注，但是在开始掷骰之后下注。如果掷出7或11则获胜，掷出2、3或12则输，否则设定一个新的点数。",
            "在掷骰子中，come注是在设定了初始点数之后下注。如果掷出7或11则获胜，掷出2、3或12则输，如果掷出其他数字则设定一个新的点数。",
            "come注的运作方式类似于pass line注，但是在点数设定期间下注。它在掷出7或11时获胜，掷出2、3或12时输，如果掷出其他数字则设定一个新的点数。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中的don't come注是什么？", "你能解释一下don't come注吗？", "don't come注在掷骰子中是如何运作的？"],
        "responses": [
            "掷骰子中的don't come注是在开始掷骰之后下注，如果掷出2或3则获胜，7或11则输，掷出12则推迟。如果设定点数，则需在掷出7之前掷出7以获胜。",
            "在掷骰子中，don't come注类似于don't pass注，但在点数设定之后下注。它在掷出2或3时获胜，掷出7或11时输，掷出12时推迟，如果设定了come点数，则需在掷出7之前掷出7以获胜。",
            "don't come注是come注的相反下注，在点数设定期间下注。它在掷出2或3时获胜，掷出7或11时输，掷出12时推迟，如果设定了come点数，则需在掷出7之前掷出7以获胜。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中的赔率注是什么？", "你能解释一下赔率注吗？", "赔率注在掷骰子中是如何运作的？"],
        "responses": [
            "掷骰子中的赔率注是放在pass、don't pass、come或don't come注后面的附加下注。它们按照真实赔率支付，没有赌场优势。",
            "在掷骰子中，赔率注是与pass或come等其他下注一起进行的，提供真实赔率的支付，这对玩家来说是有利的。",
            "赔率注是附加在pass或come下注上的，提供真实赔率的支付，没有赌场优势。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中的field注是什么？", "你能解释一下field注吗？", "field注在掷骰子中是如何运作的？"],
        "responses": [
            "掷骰子中的field注是一种单次掷骰下注，下注结果为2、3、4、9、10、11或12时获胜，其他结果则输。",
            "在掷骰子中，field注涵盖了多个数字，赔率为平注或更高，如果下一次掷骰是2、3、4、9、10、11或12则获胜。",
            "掷骰子中的field注是一种单次掷骰下注，下注结果为2、3、4、9、10、11或12时获胜，其他结果则输。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中的place注是什么？", "你能解释一下place注吗？", "place注在掷骰子中是如何运作的？"],
        "responses": [
            "掷骰子中的place注是一种下注，押注特定数字（4、5、6、8、9或10）会在掷出7之前出现。不同的数字有不同的赔率。",
            "在掷骰子中，place注是对4、5、6、8、9或10等数字进行下注，如果所选的数字在掷出7之前出现则获胜。每个数字的赔率不同。",
            "place注涉及对特定数字下注，押注这些数字会在掷出7之前出现，不同的数字有不同的赔率。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中的proposition注是什么？", "你能解释一下proposition注吗？", "proposition注在掷骰子中是如何运作的？"],
        "responses": [
            "掷骰子中的proposition注是放在桌子中央的单次掷骰下注，涵盖了特定的结果，如hard ways、any 7或特定的骰子组合。",
            "在掷骰子中，proposition注提供高赔率，但赌场优势也更高。它们包括hard ways、any 7以及单独的骰子结果等下注。",
            "proposition注是对特定掷骰结果进行的下注，如hard ways或any 7，提供较高的赔率但风险也较高。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中的hard ways是什么？", "你能解释一下hard ways吗？", "hard ways注在掷骰子中是如何运作的？"],
        "responses": [
            "掷骰子中的hard ways注是押注一对相同的点数（2-2、3-3、4-4、5-5）会在掷出7或更容易组合之前出现。",
            "在掷骰子中，hard ways注获胜的条件是掷出一对（如2-2、3-3）而不是掷出7或更容易的组合（如6-4代表8）。",
            "hard ways注在掷骰子中涉及对一对点数下注（例如4-4代表8），押注这些点数会在掷出7或任何更容易的组合之前出现。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中的big 6和big 8注是什么？", "你能解释一下big 6和big 8注吗？", "big 6和big 8注在掷骰子中是如何运作的？"],
        "responses": [
            "big 6和big 8注在掷骰子中是押注6或8会在掷出7之前出现。这些注的赔率为平注。",
            "在掷骰子中，big 6和big 8注在掷出6或8之前获胜。它们是简单的平注下注。",
            "big 6和big 8注涉及押注6或8会在掷出7之前出现，赔率为平注。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中的horn注是什么？", "你能解释一下horn注吗？", "horn注在掷骰子中是如何运作的？"],
        "responses": [
            "掷骰子中的horn注是一种单次掷骰下注，将下注分配在2、3、11和12上。每个数字有不同的赔率。",
            "在掷骰子中，horn注覆盖四个数字（2、3、11、12）进行单次掷骰。对于像2或12这样不容易出现的结果，赔率较高。",
            "horn注涉及对2、3、11和12进行单次掷骰下注，如果掷出这些数字中的任何一个，将有不同的赔率。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中最好的下注是什么？", "你能解释一下掷骰子中最好的下注吗？", "哪些下注在掷骰子中有最低的赌场优势？"],
        "responses": [
            "掷骰子中赌场优势最低的最佳下注是pass line、don't pass、come、don't come以及在这些下注上采取赔率注。",
            "在掷骰子中，赌场优势最低的下注包括pass line、don't pass、come、don't come和赔率下注。",
            "为了降低掷骰子的赌场优势，重点下注如pass line、don't pass、come、don't come和赔率下注。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中的一次掷骰是什么？", "你能解释一下掷骰子中的一次掷骰吗？", "一次掷骰在掷骰子中是如何运作的？"],
        "responses": [
            "掷骰子中的一次掷骰指的是掷两个骰子的行为，以决定在桌上下注的结果。",
            "在掷骰子中，一次掷骰是指掷出两个骰子以产生结果，从而影响玩家所做的各种下注。",
            "掷骰子中的一次掷骰是游戏的基本动作，其中两个骰子被掷出以决定桌上的下注结果。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中的come-out roll是什么？", "你能解释一下come-out roll吗？", "come-out roll在掷骰子中是如何运作的？"],
        "responses": [
            "掷骰子中的come-out roll是一个新的投注轮的初始掷骰，决定点数或解决pass/don't pass下注。",
            "在掷骰子中，come-out roll开始了一个新的轮次。掷出7或11会使pass下注获胜，掷出2、3或12则使pass下注失败，其他数字设定点数。",
            "come-out roll是轮次的第一次掷骰，为设定点数或立即解决pass line下注奠定基础。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中的点数是什么？", "你能解释一下掷骰子中的点数吗？", "点数在掷骰子中是如何运作的？"],
        "responses": [
            "掷骰子中的点数是在come-out roll结果为4、5、6、8、9或10时设定的。投掷者必须在掷出7之前再次掷出该点数以获胜。",
            "在掷骰子中，点数是come-out roll的结果（4、5、6、8、9或10）。目标是在掷出7之前再次掷出该点数以赢得pass line下注。",
            "掷骰子中的点数是在come-out roll时设定的目标数字（4、5、6、8、9或10），必须在掷出7之前再次掷出该点数以获胜。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中的'seven out'是什么意思？", "你能解释一下'seven out'吗？", "在掷骰子中，'seven out'是如何运作的？"],
        "responses": [
            "'Seven out'在掷骰子中指的是在点数设定后掷出7，导致pass line下注失败并结束投掷者的回合。",
            "在掷骰子中，'seven out'发生在点数设定后掷出7，结果使pass line下注失败，并且投掷者将传递骰子。",
            "'Seven out'意味着在设定点数后掷出7，导致pass line下注失败并结束投掷者的回合。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中的shoot是什么意思？", "你能解释一下掷骰子中的shoot吗？", "在掷骰子中，shooter的角色如何运作？"],
        "responses": [
            "掷骰子中的shooter是掷骰子的玩家。这个角色在桌子上轮流进行，让每个玩家都有机会掷骰。",
            "在掷骰子中，shooter是指掷骰子的个人。shooter的角色会在'seven out'或决定传递骰子后发生变化。",
            "shooter是掷骰子的玩家。这个角色在玩家之间轮流，通常在每次seven-out后进行更换。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中的place注和buy注有什么区别？", "你能解释一下place注和buy注的区别吗？", "place注和buy注在掷骰子中有何不同？"],
        "responses": [
            "掷骰子中的place注是对数字4、5、6、8、9或10下注，赔率固定。Buy注也涵盖这些数字，但提供真实赔率并收取佣金。",
            "在掷骰子中，place注对于4、5、6、8、9或10这些数字有固定的赔率和支付，而buy注则支付真实赔率，但需支付佣金。",
            "掷骰子中place注和buy注的区别在于，place注有固定赔率，而buy注提供真实赔率但需支付佣金。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中的pass line注的赔率是多少？", "你能解释一下pass line注的赔率吗？", "pass line注的赔率在掷骰子中是如何运作的？"],
        "responses": [
            "掷骰子中的pass line注的赔率是平注（1:1）。如果下注获胜，你会获得与原下注金额相等的回报。",
            "在掷骰子中，pass line注的赔率为平注，这意味着如果下注获胜，你将获得与原下注金额相等的回报。",
            "pass line注的赔率在掷骰子中是1:1，因此赢得的下注将返还你的初始下注金额和同等金额的奖金。"
        ]
    },
    {
        "type": 11, 
        "queries": ["掷骰子中的don't pass注的赔率是多少？", "你能解释一下don't pass注的赔率吗？", "don't pass注的赔率在掷骰子中是如何运作的？"],
        "responses": [
            "掷骰子中的don't pass注的赔率是平注（1:1）。如果下注获胜，你会获得与原下注金额相等的回报。",
            "在掷骰子中，don't pass注的赔率为平注，这意味着如果下注获胜，你将获得与原下注金额相等的回报。",
            "don't pass注的赔率在掷骰子中是1:1，因此赢得的下注将返还你的初始下注金额和同等金额的奖金。"
        ]
    },

    // Poker

    {
        "type": 12,
        "queries": ["扑克是什么？", "你能解释一下扑克吗？", "你怎么玩扑克？", "扑克"],
        "responses": [
            "扑克是一种受欢迎的纸牌游戏，涉及下注和策略，玩家的目标是形成最佳的手牌或通过虚张声势让对手弃牌，从而赢得奖池。",
            "扑克是一种技巧和运气的游戏，玩家根据手牌的强度下注，目标是通过拥有最佳手牌或说服其他玩家弃牌来赢得筹码。",
            "扑克是一种纸牌游戏，具有多种变体，玩家竞争以形成最佳的五张牌手牌或通过虚张声势赢得对手的筹码。"
        ]
    },
    {
        "type": 12,
        "queries": ["五张牌抽牌", "什么是五张牌抽牌？", "你能解释一下五张牌抽牌吗？", "你怎么玩五张牌抽牌？"],
        "responses": [
            "五张牌抽牌是一种经典的扑克游戏，每位玩家会被发到五张牌，他们可以交换最多三张牌以组成最佳手牌。",
            "在五张牌抽牌中，玩家会得到五张牌，并有机会弃掉并抽取新牌以改进他们的手牌。",
            "五张牌抽牌是一种扑克变体，玩家的目标是在一次抽牌后组成最佳的五张牌手牌。"
        ]
    },
    {
        "type": 12,
        "queries": ["德州扑克牌", "什么是德州扑克牌？", "你能解释一下德州扑克牌吗？", "你怎么玩德州扑克牌？"],
        "responses": [
            "德州扑克牌是一种受欢迎的扑克游戏，玩家会得到两张私人牌，并使用五张公共牌来组成最佳手牌。",
            "在德州扑克牌中，玩家将两张底牌与五张公共牌结合起来，形成最佳的五张牌手牌。",
            "德州扑克牌是一种扑克变体，玩家试图使用两张个人牌和五张共享牌来组成最佳手牌。"
        ]
    },
    {
        "type": 12,
        "queries": ["五张牌抽牌的规则是什么？", "你能解释一下五张牌抽牌的规则吗？", "五张牌抽牌的规则是怎样的？"],
        "responses": [
            "在五张牌抽牌中，每位玩家被发到五张牌，可以交换最多三张牌，然后进行最终的下注回合，之后是摊牌阶段。",
            "五张牌抽牌的规则包括发给每位玩家五张牌，允许一个抽牌阶段以改进手牌，然后进行最终的下注回合。",
            "五张牌抽牌的规则包括发给每位玩家五张牌、一个抽牌阶段以改进手牌，以及摊牌前的最终下注回合。"
        ]
    },
    {
        "type": 12,
        "queries": ["德州扑克牌的规则是什么？", "你能解释一下德州扑克牌的规则吗？", "德州扑克牌的规则是怎样的？"],
        "responses": [
            "德州扑克牌的规则包括给每位玩家发两张底牌，然后发五张公共牌，分为翻牌、转牌和河牌三个阶段。",
            "在德州扑克牌中，玩家使用两张底牌和五张公共牌组成最佳手牌，下注回合发生在翻牌、转牌和河牌之前和之后。",
            "德州扑克牌的规则包括给每位玩家发两张私人牌和五张公共牌，并有四个下注回合。"
        ]
    },
    {
        "type": 12,
        "queries": ["五张牌抽牌和德州扑克牌的区别是什么？", "五张牌抽牌和德州扑克牌有什么不同？", "你能解释一下五张牌抽牌和德州扑克牌的区别吗？"],
        "responses": [
            "五张牌抽牌和德州扑克牌都是受欢迎的扑克游戏，但它们主要在发牌和交换牌的方式上有所不同。在五张牌抽牌中，每位玩家会被发到五张牌，并可以弃掉最多三张牌以抽取新牌。在德州扑克牌中，玩家会得到两张私人牌，并使用所有玩家共享的五张公共牌来组成最佳手牌。",
            "五张牌抽牌和德州扑克牌的主要区别在于发牌过程。在五张牌抽牌中，玩家会被发到五张牌，并可以选择交换一些或全部牌。而德州扑克牌则涉及给每位玩家发两张底牌和五张社区牌，这些牌在几轮下注中逐步揭示。",
            "五张牌抽牌允许玩家交换牌来改进手牌，而德州扑克牌使用所有玩家共享的公共牌。在德州扑克牌中，策略涉及个人牌和公共牌，而五张牌抽牌则侧重于通过交换牌来改进手牌的能力。"
        ]
    },
    {
        "type": 12,
        "queries": ["德州扑克牌中的最佳起始手牌是什么？", "你能解释一下德州扑克牌中的最佳起始手牌吗？", "德州扑克牌中的最强起始手牌是什么？"],
        "responses": [
            "德州扑克牌中最佳的起始手牌是一对王牌（AA）。",
            "在德州扑克牌中，最强的起始手牌是口袋王牌。",
            "一对王牌是德州扑克牌中的最佳起始手牌。"
        ]
    },
    {
        "type": 12,
        "queries": ["德州扑克牌中的公共牌是什么？", "你能解释一下德州扑克牌中的公共牌吗？", "德州扑克牌中的公共牌是怎样运作的？"],
        "responses": [
            "德州扑克牌中的公共牌是五张面朝上的牌，放在桌子的中央，所有玩家都可以使用这些牌来组成手牌。",
            "在德州扑克牌中，公共牌是所有玩家共享的牌，包括翻牌（三张牌）、转牌（一张牌）和河牌（一张牌）。",
            "德州扑克牌中的公共牌包括翻牌（三张牌）、转牌（一张牌）和河牌（一张牌），所有玩家都可以使用这些牌来组成手牌。"
        ]
    },
    {
        "type": 12,
        "queries": ["德州扑克牌中的翻牌是什么？", "你能解释一下德州扑克牌中的翻牌吗？", "翻牌在德州扑克牌中是如何运作的？"],
        "responses": [
            "德州扑克牌中的翻牌是指在初始下注回合后，发出的前三张面朝上的公共牌。",
            "在德州扑克牌中，翻牌是指放在桌子中央的三张面朝上的公共牌。",
            "翻牌在德州扑克牌中是指在第一次下注回合之后揭示的三张公共牌。"
        ]
    },
    {
        "type": 12,
        "queries": ["德州扑克牌中的转牌是什么？", "你能解释一下德州扑克牌中的转牌吗？", "转牌在德州扑克牌中是如何运作的？"],
        "responses": [
            "德州扑克牌中的转牌是指在翻牌和第二轮下注之后发出的第四张面朝上的公共牌。",
            "在德州扑克牌中，转牌是指翻牌之后揭示的第四张公共牌，位于河牌之前。",
            "转牌在德州扑克牌中是指在翻牌之后发出的第四张公共牌。"
        ]
    },
    {
        "type": 12,
        "queries": ["德州扑克牌中的河牌是什么？", "你能解释一下德州扑克牌中的河牌吗？", "河牌在德州扑克牌中是如何运作的？"],
        "responses": [
            "德州扑克牌中的河牌是指在转牌和第三轮下注之后发出的第五张面朝上的公共牌。",
            "在德州扑克牌中，河牌是指在转牌之后揭示的最后一张公共牌，在最后一轮下注之前。",
            "河牌在德州扑克牌中是指在转牌之后发出的第五张公共牌。"
        ]
    },
    {
        "type": 12,
        "queries": ["扑克牌中的虚张声势是什么？", "你能解释一下扑克牌中的虚张声势吗？", "虚张声势在扑克牌中是如何运作的？"],
        "responses": [
            "在扑克牌中，虚张声势是指用一手弱牌进行下注或加注，以说服对手弃掉更强的手牌。",
            "在扑克牌中，虚张声势涉及通过积极下注假装拥有强牌，以使其他玩家弃牌。",
            "虚张声势在扑克牌中是指让对手相信你有比实际更好的手牌，通常通过下注或加注来实现。"
        ]
    },
    {
        "type": 12,
        "queries": ["扑克牌中的顺子是什么？", "你能解释一下扑克牌中的顺子吗？", "顺子在扑克牌中是如何运作的？"],
        "responses": [
            "扑克牌中的顺子是指五张连续的牌，不同的花色，例如5-6-7-8-9。",
            "在扑克牌中，顺子是指五张顺序的牌，例如7-8-9-10-J，但不一定是同一花色。",
            "顺子在扑克牌中是指五张连续的牌，例如4-5-6-7-8，花色可以不同。"
        ]
    },
    {
        "type": 12,
        "queries": ["扑克牌中的同花是什么？", "你能解释一下扑克牌中的同花吗？", "同花在扑克牌中是如何运作的？"],
        "responses": [
            "扑克牌中的同花是指五张相同花色的牌，不要求顺序，例如A-5-7-10-K的红桃。",
            "在扑克牌中，同花是指五张相同花色的牌，例如2-4-6-8-J的黑桃。",
            "同花在扑克牌中是指五张相同花色的牌，例如3-7-9-Q-K的方片。"
        ]
    },
    {
        "type": 12,
        "queries": ["扑克牌中的葫芦是什么？", "你能解释一下扑克牌中的葫芦吗？", "葫芦在扑克牌中是如何运作的？"],
        "responses": [
            "扑克牌中的葫芦是指三张同等级的牌和两张另一等级的牌，例如3-3-3-6-6。",
            "在扑克牌中，葫芦由三张相同等级的牌和一对两张的牌组成，例如Q-Q-Q-7-7。",
            "葫芦在扑克牌中包括三张同等级的牌和一对另一等级的牌，例如9-9-9-4-4。"
        ]
    },
    {
        "type": 12,
        "queries": ["扑克牌中的四条是什么？", "你能解释一下扑克牌中的四条吗？", "四条在扑克牌中是如何运作的？"],
        "responses": [
            "扑克牌中的四条是指四张同等级的牌，例如8-8-8-8-2。",
            "在扑克牌中，四条由四张相同等级的牌组成，例如A-A-A-A-3。",
            "四条在扑克牌中是指四张相同等级的牌，例如7-7-7-7-5。"
        ]
    },
    {
        "type": 12,
        "queries": ["扑克牌中的同花顺是什么？", "你能解释一下扑克牌中的同花顺吗？", "同花顺在扑克牌中是如何运作的？"],
        "responses": [
            "扑克牌中的同花顺是指五张连续的同花色牌，例如4-5-6-7-8的红桃。",
            "在扑克牌中，同花顺由五张顺序的相同花色的牌组成，例如9-10-J-Q-K的黑桃。",
            "同花顺是指五张连续且相同花色的牌，例如2-3-4-5-6的梅花。"
        ]
    },
    {
        "type": 12,
        "queries": ["扑克牌中的皇家同花顺是什么？", "你能解释一下扑克牌中的皇家同花顺吗？", "皇家同花顺在扑克牌中是如何运作的？"],
        "responses": [
            "扑克牌中的皇家同花顺是指最高牌的同花顺：10-J-Q-K-A，都是相同的花色。",
            "在扑克牌中，皇家同花顺是最强的手牌，由同一花色的10、J、Q、K和A组成。",
            "皇家同花顺是指同一花色的10-J-Q-K-A，是扑克牌中排名最高的手牌。"
        ]
    },
    {
        "type": 12,
        "queries": ["扑克牌中的摊牌是什么？", "你能解释一下扑克牌中的摊牌吗？", "摊牌在扑克牌中是如何运作的？"],
        "responses": [
            "摊牌在扑克牌中发生在一手牌结束时，玩家展示他们的牌以根据手牌排名确定获胜者。",
            "在扑克牌中，摊牌是玩家在最终下注回合后展示他们的手牌以决定获胜者。",
            "摊牌是扑克牌中的最终阶段，玩家展示他们的牌以确定谁拥有最佳手牌。"
        ]
    },
    {
        "type": 12,
        "queries": ["扑克牌中的奖池是什么？", "你能解释一下扑克牌中的奖池吗？", "奖池在扑克牌中是如何运作的？"],
        "responses": [
            "奖池在扑克牌中是指在一手牌中所有玩家下注的总金额或筹码，最终由拥有最佳手牌的玩家或在所有其他玩家弃牌后的最后一位玩家赢得。",
            "在扑克牌中，奖池是所有下注的总和，由拥有最高排名手牌的玩家或最后一个没有弃牌的玩家赢得。",
            "奖池在扑克牌中是指所有玩家在一手牌中下注的总金额，最终由拥有最佳手牌的玩家或最后一个留在游戏中的玩家赢得。"
        ]
    },
    {
        "type": 12,
        "queries": ["扑克牌中的下注是什么？", "你能解释一下扑克牌中的下注吗？", "下注在扑克牌中是如何运作的？"],
        "responses": [
            "在扑克牌中，下注是将筹码或钱放入奖池中，这可以增加奖池的大小或迫使其他玩家弃牌。",
            "在扑克牌中，下注涉及将筹码放入奖池中，可能是为了启动下注回合或提高现有回合的赌注。",
            "下注在扑克牌中是指将筹码或钱放入奖池中，以启动下注、增加赌注或施压对手弃牌。"
        ]
    },
    {
        "type": 12,
        "queries": ["扑克牌中的跟注是什么？", "你能解释一下扑克牌中的跟注吗？", "跟注在扑克牌中是如何运作的？"],
        "responses": [
            "跟注在扑克牌中是指匹配当前下注的金额，以继续参与手牌，而不加注或弃牌。",
            "在扑克牌中，跟注是指投入与之前下注相同数量的筹码以继续参与游戏。",
            "在扑克牌中，跟注是指等同于当前下注的筹码以保持在手牌中，而不增加赌注。"
        ]
    },
    {
        "type": 12,
        "queries": ["扑克牌中的加注是什么？", "你能解释一下扑克牌中的加注吗？", "加注在扑克牌中是如何运作的？"],
        "responses": [
            "加注在扑克牌中是指增加当前下注金额，这迫使其他玩家匹配新的下注金额以继续手牌或弃牌。",
            "在扑克牌中，加注意味着在当前下注的基础上增加筹码，从而提高赌注，挑战其他玩家跟注或弃牌。",
            "加注在扑克牌中是指提高之前玩家的下注金额，要求其他玩家匹配新金额以继续参与手牌。"
        ]
    },
    {
        "type": 12,
        "queries": ["扑克牌中的弃牌是什么？", "你能解释一下扑克牌中的弃牌吗？", "弃牌在扑克牌中是如何运作的？"],
        "responses": [
            "弃牌在扑克牌中是指丢弃手牌，放弃赢得奖池的机会，实际上退出当前的下注回合。",
            "在扑克牌中，弃牌是选择不继续参与手牌，放弃任何对奖池的要求，通过丢弃牌来退出。",
            "在扑克牌中，弃牌是指退出手牌并失去已经下注的筹码或钱，结束你在该回合中的参与。"
        ]
    },

    // Race

    {
        "type": 13,
        "queries": ["比赛部分是什么？", "你能解释一下比赛部分吗？", "比赛部分是如何运作的？", "比赛"],
        "responses": [
            "比赛部分允许用户对各种品种和健康状况的兔子进行投注。你可以选择一只兔子，并对它进行赢得比赛或在特定位置（1、2或3）的位置下注。",
            "在比赛部分，你可以根据兔子的品种和健康情况进行投注。你可以选择一只兔子，并指定一个位置（1、2或3）进行下注。",
            "比赛部分涉及对具有不同特征的兔子进行投注。你选择一只兔子，进行下注，并预测它的名次。"
        ]
    },
    {
        "type": 13,
        "queries": ["我如何在比赛部分下注？", "你能解释一下如何对兔子下注吗？", "在比赛部分下注的过程是什么？"],
        "responses": [
            "要进行投注，选择一个参与比赛的兔子，从列表中选择一个位置（1、2或3），然后确认你的投注。如果未选择位置，则你的投注默认为1名。",
            "在比赛部分，选择一个当前参与的兔子，决定是否选择特定位置或默认1名，然后确认你的投注。",
            "要进行投注，选择一只参与比赛的兔子，选择一个位置（如果需要），然后确认。如果未指定位置，投注将自动放在兔子赢得1名上。"
        ]
    },
    {
        "type": 13,
        "queries": ["比赛部分有哪些兔子品种？", "你能列出可供投注的兔子品种吗？", "我可以在比赛部分投注哪些兔子品种？"],
        "responses": [
            "比赛部分包括多种兔子品种，如美国毛绒兔、比利时兔、荷兰兔和弗莱明巨兔等。",
            "你可以在比赛部分投注多种兔子品种，如美国毛绒兔、荷兰兔、英式长耳兔等。",
            "可供投注的兔子包括比利时兔、荷兰垂耳兔和迷你瑞克斯等品种。完整的列表请参见比赛部分。"
        ]
    },
    {
        "type": 13,
        "queries": ["兔子的健康状况如何确定？", "你能解释一下兔子的健康如何影响比赛吗？", "兔子的健康对比赛有什么影响？"],
        "responses": [
            "兔子的健康状况是随机分配的，会影响其在比赛中的表现。健康的兔子通常表现更好，延迟更少。",
            "在比赛部分，兔子的健康是随机决定的，并影响其速度和比赛延迟。健康状况更好的兔子通常表现更快。",
            "兔子的健康影响其比赛能力。健康的兔子通常速度更快，延迟较少。"
        ]
    },
    {
        "type": 13,
        "queries": ["兔子的速度如何计算？", "你能解释一下比赛部分的兔子速度吗？", "兔子的速度在比赛部分是如何确定的？"],
        "responses": [
            "兔子的速度在一个随机范围内计算，并根据健康状况进行调整。每只兔子都有一个最大和最小速度范围，影响其比赛表现。",
            "兔子的速度在一个随机范围内设定，并受到健康状况的影响。每只兔子都有一个独特的速度范围，这会影响其比赛时间。",
            "兔子的速度是随机决定的，受健康状况的影响。每只兔子都有一个定义的速度范围。"
        ]
    },
    {
        "type": 13,
        "queries": ["如果我没有选择下注位置会发生什么？", "你能解释一下比赛部分的默认投注吗？", "如果我没有选择位置，默认的位置是什么？"],
        "responses": [
            "如果你没有选择下注位置，投注将自动放在兔子赢得1名的位置上。",
            "默认投注设定为1名，如果未选择特定位置。如果没有选择位置，投注将自动放在兔子赢得比赛上。",
            "当你没有指定下注位置时，默认位置为1名。你的投注将放在兔子赢得比赛上。"
        ]
    },
    {
        "type": 13,
        "queries": ["比赛多久举行一次？", "你能告诉我比赛的频率吗？", "我什么时候可以对比赛进行投注？"],
        "responses": [
            "比赛定期举行。请查看比赛部分以获取即将举行的比赛时间表和投注时间。",
            "比赛的频率各不相同，请参阅比赛部分以获取最新的比赛时间表和投注时间。",
            "比赛按照计划进行。比赛部分将提供有关即将比赛时间和投注机会的最新信息。"
        ]
    },
    {
        "type": 13,
        "queries": ["比赛结果如何确定？", "你能解释一下比赛结果是如何决定的吗？", "比赛的赢家是如何确定的？"],
        "responses": [
            "比赛结果基于参与兔子的随机计算速度和延迟来决定。表现最佳的兔子获胜。",
            "比赛的结果基于计算的速度和延迟。表现最佳的兔子被宣布为赢家。",
            "赢家由每只兔子的最终速度和延迟决定。表现最具竞争力的兔子赢得比赛。"
        ]
    },
    {
        "type": 13,
        "queries": ["我能看到参与兔子的图片吗？", "在哪里可以查看比赛部分的兔子图片？", "可以查看兔子的图片来进行投注吗？"],
        "responses": [
            "是的，比赛部分提供了参与兔子的图片，帮助你做出明智的投注决策。",
            "你可以在比赛部分查看所有参与兔子的图片。这有助于你决定投注哪只兔子。",
            "兔子的图片在比赛部分提供，以帮助你做出投注选择。查看这些图片可以了解每只兔子的详细信息。"
        ]
    },
    {
        "type": 13,
        "queries": ["当前比赛中有哪些兔子品种？", "你能提供一个当前比赛中参与的兔子品种列表吗？", "当前比赛中的兔子品种有哪些？"],
        "responses": [
            "当前比赛的参与品种列表可以在比赛部分找到。请查看那里以获取更新的品种信息。",
            "比赛部分提供了当前比赛中参与的品种列表。你可以在投注前查看哪些兔子在比赛中。",
            "当前比赛的详细信息，包括参与的兔子品种，可以在比赛部分查看。检查以获取更新的比赛品种信息。"
        ]
    },
    {
        "type": 13,
        "queries": ["每只兔子的赔率是多少？", "你能解释一下比赛部分兔子的赔率是如何设定的吗？", "我如何知道每只兔子的赔率？"],
        "responses": [
            "每只兔子的赔率基于其健康和速度确定。这些赔率在比赛部分显示，帮助你做出投注决策。",
            "赔率根据每只兔子的健康状况和表现指标设定。你可以在比赛部分查看这些赔率，在下注前进行参考。",
            "你可以在比赛部分查看每只兔子的赔率，这些赔率基于兔子的健康状况和比赛表现。"
        ]
    },
    {
        "type": 13,
        "queries": ["兔子的健康状况在比赛部分有什么重要性？", "你能解释一下兔子的健康如何影响比赛吗？", "兔子的健康如何影响比赛结果？"],
        "responses": [
            "兔子的健康影响其速度和比赛中的延迟。健康的兔子通常表现更好，延迟更少。",
            "在比赛部分，兔子的健康是影响其速度和比赛延迟的关键因素。健康的兔子通常有优势。",
            "健康状况影响兔子在比赛中的表现。健康的兔子通常速度更快，延迟较少。"
        ]
    },
    {
        "type": 13,
        "queries": ["我可以对不同健康状况的兔子进行投注吗？", "是否可以根据健康状况选择兔子？", "健康状况如何影响投注？"],
        "responses": [
            "是的，你可以对不同健康状况的兔子进行投注。健康状况影响兔子的表现，因此在投注时需要考虑。",
            "投注包括选择具有不同健康状况的兔子。它们的健康状态影响其比赛能力，因此在投注时应予以考虑。",
            "具有各种健康状况的兔子可以进行投注。健康状况影响它们的比赛表现，因此在下注时要考虑这一因素。"
        ]
    },
    {
        "type": 13,
        "queries": ["如果我下注的兔子没有参与比赛会发生什么？", "如果我的兔子不比赛，我会得到退款吗？", "如果我选择的兔子未参与比赛，我的投注会如何处理？"],
        "responses": [
            "你不能对未参与的兔子进行投注，因为只有参与的兔子才会显示在列表中。",
            "只有参与的兔子才可供投注。你只能对将在比赛中的兔子进行投注。",
            "只能对当前参与比赛的兔子进行投注。如果兔子未在列表中，表示它未参与比赛，你不能对其进行投注。"
        ]
    },

    // Keno

    {
        "type": 14,
        "queries": ["什么是基诺？", "你能解释一下基诺吗？", "如何玩基诺？", "基诺"],
        "responses": [
            "基诺是一种类似于彩票的游戏，玩家选择号码，根据他们选择的号码与抽取的号码匹配的数量来赢得奖金。",
            "在基诺中，玩家从一个设定的范围中选择号码，然后通过随机抽取来确定哪些号码中奖。匹配的号码越多，奖金越高。",
            "基诺涉及从一个号码池中选择号码，然后等待抽取结果，看你的号码是否与抽取的号码匹配。"
        ]
    },
    {
        "type": 14,
        "queries": ["如何在基诺中获胜？", "基诺的获胜策略是什么？", "你能解释一下如何在基诺中获胜吗？"],
        "responses": [
            "在基诺中获胜依赖于将你选择的号码与抽取的号码匹配的数量。匹配的号码越多，奖金越大。",
            "要在基诺中获胜，选择号码并希望它们与抽取的号码匹配。奖金会根据你匹配的号码数量而有所不同。",
            "在基诺中，获胜是通过让你选择的号码与抽取的号码匹配来实现的。匹配的数量越多，奖金越高。"
        ]
    },
    {
        "type": 14,
        "queries": ["基诺中的赔率是多少？", "你能解释一下基诺的中奖赔率吗？", "基诺的赔率是如何工作的？"],
        "responses": [
            "基诺中的赔率取决于你选择的号码数量以及抽取的号码数量。通常，匹配更多号码的赔率较低。",
            "基诺的赔率因你选择的号码数量和抽取的总号码数量而异。选择的号码越多，匹配所有号码的难度越大。",
            "在基诺中，赔率由你想要匹配的号码数量和总的抽取号码数量决定。选择的号码越多，获胜的机会越低。"
        ]
    },
    {
        "type": 14,
        "queries": ["什么是基诺抽奖？", "你能解释一下基诺的抽奖过程吗？", "基诺的抽奖是如何进行的？"],
        "responses": [
            "基诺抽奖是从一个号码池中随机选择一组号码的过程，玩家根据他们选择的号码与抽取的号码的匹配情况来获胜。",
            "在基诺抽奖中，一组中奖号码是随机选出的，玩家通过将他们选择的号码与抽取的号码进行匹配来获胜。",
            "基诺抽奖涉及从号码池中随机选择号码。结果决定了哪些号码是中奖号码，以及玩家根据匹配情况获得多少奖金。"
        ]
    },
    {
        "type": 14,
        "queries": ["在基诺中你选择多少个号码？", "基诺中的号码范围是什么？", "你能解释一下基诺中的号码选择吗？"],
        "responses": [
            "在基诺中，玩家通常从80个号码中选择1到20个号码。具体的选择数量取决于游戏的规则。",
            "基诺中选择的号码数量可以有所不同，但玩家通常从80个号码中选择1到20个号码。",
            "你可以在基诺中从80个号码中选择最多20个号码，选择的数量会影响你的潜在奖金。"
        ]
    },
    {
        "type": 14,
        "queries": ["什么是基诺票？", "你能解释一下基诺票吗？", "基诺票是如何工作的？"],
        "responses": [
            "基诺票是用于记录你选择的号码和下注金额的表单。它包括你选择的号码和你下注的金额。",
            "在基诺中，票据用于标记你选择的号码并下注。它作为你在抽奖中选择的号码的记录。",
            "基诺票显示了你选择的号码和你下注的金额。它用于参与游戏并领取任何奖金。"
        ]
    },
    {
        "type": 14,
        "queries": ["基诺的奖金是什么？", "你能解释一下基诺的奖金吗？", "基诺中的奖金是如何计算的？"],
        "responses": [
            "基诺的奖金由你选择的号码与抽取的号码匹配的数量决定。匹配的号码越多，奖金越高。",
            "在基诺中，奖金基于你选择的号码和抽取的号码之间的匹配数量，匹配越多奖金越大。",
            "基诺中的奖金取决于你选择的号码与抽取的号码之间的匹配数量，不同的匹配数量对应不同的奖金等级。"
        ]
    },
    {
        "type": 14,
        "queries": ["什么是基诺游戏板？", "你能解释一下基诺游戏板吗？", "基诺游戏板是如何工作的？"],
        "responses": [
            "基诺游戏板显示了80个号码，玩家可以从中选择自己的号码。它用于跟踪选择的号码和抽取的号码。",
            "在基诺中，游戏板由80个号码组成，玩家从中选择自己的号码。它有助于管理和跟踪游戏。",
            "基诺游戏板显示了所有80个可供选择的号码。玩家在这个板上标记他们选择的号码，以参与抽奖。"
        ]
    },
    {
        "type": 14,
        "queries": ["可以在线玩基诺吗？", "基诺可以在线玩吗？", "我如何在线玩基诺？"],
        "responses": [
            "是的，你可以在各种在线赌场和游戏平台上玩基诺。只需选择你的号码并参与抽奖即可。",
            "基诺可以通过许多游戏平台在线进行。你可以在家里选择你的号码并参与游戏。",
            "要在线玩基诺，访问提供基诺的在线赌场，选择你的号码，并进入抽奖，根据匹配情况赢得奖金。"
        ]
    },
    {
        "type": 14,
        "queries": ["什么是基诺奖金？", "你能解释一下基诺奖金吗？", "基诺奖金是如何工作的？"],
        "responses": [
            "基诺奖金是赌场提供的特别优惠或额外奖金，通常是为了玩基诺或达到某些里程碑或赢得特定金额。",
            "在基诺中，奖金可能包括额外的积分、免费游戏或由赌场提供的增加的奖金，作为促销活动或奖励的一部分。",
            "基诺奖金可能因赌场而异，包括额外的游戏、提高的奖金或基于你的游戏或成就的促销奖励。"
        ]
    },
    {
        "type": 14,
        "queries": ["什么是基诺大奖？", "你能解释一下基诺大奖吗？", "基诺大奖是如何工作的？"],
        "responses": [
            "基诺大奖是指匹配所有选择的号码时获得的大奖。大奖金额取决于游戏的规则和下注金额。",
            "在基诺中，匹配所有选择的号码可以赢得大奖，这是游戏中最高的奖金，取决于匹配的号码数量和游戏规则。",
            "基诺大奖是指匹配所有选定号码时获得的重要奖金。大奖的大小取决于游戏和参与者的总数量。"
        ]
    },
    {
        "type": 14,
        "queries": ["什么是基诺抽奖？", "你能解释一下基诺抽奖吗？", "基诺抽奖是如何进行的？"],
        "responses": [
            "基诺抽奖是从号码池中随机选择号码的过程，确定中奖号码，玩家使用这些号码来检查他们的票据。",
            "在基诺中，抽奖涉及从号码池中随机选择号码，玩家将他们选择的号码与抽取的号码进行比较，以确定中奖情况。",
            "基诺抽奖过程包括随机选择号码，玩家根据他们的号码与抽取的号码的匹配情况来获胜。"
        ]
    },
    {
        "type": 14,
        "queries": ["如何选择基诺中的号码？", "你能解释一下基诺中的号码选择吗？", "基诺中的号码选择过程是什么？"],
        "responses": [
            "在基诺中，选择号码是从游戏板上的可用号码中选择，根据你的策略或偏好进行选择并下注。",
            "在基诺中，你从一个号码池中选择号码，通常在1到20之间，具体取决于游戏规则，然后等待抽奖结果，看你的号码是否匹配。",
            "选择基诺中的号码涉及从游戏板上的号码范围中挑选，并希望这些号码与游戏中的抽取号码匹配。"
        ]
    },
    {
        "type": 14,
        "queries": ["什么是基诺号码池？", "你能解释一下基诺号码池吗？", "基诺号码池是如何工作的？"],
        "responses": [
            "基诺号码池包含80个号码，玩家可以从中选择他们的号码。抽取的号码是从这个池中随机选择的。",
            "在基诺中，号码池包括80个号码，玩家从中选择进行下注。中奖号码是从这个池中随机抽取的。",
            "基诺号码池是由80个号码组成。玩家从这个池中选择他们的号码，中奖号码从中随机抽取。"
        ]
    },
    {
        "type": 14,
        "queries": ["什么是基诺奖金表？", "你能解释一下基诺奖金表吗？", "基诺中的奖金是如何计算的？"],
        "responses": [
            "基诺奖金表显示了不同匹配数量的奖金金额。它帮助玩家了解根据匹配的号码数量他们可能获得的奖金。",
            "在基诺中，奖金表列出了各种匹配数量的奖励，为你提供了根据你选择的号码和抽取的号码之间的匹配数量可能赢得的奖金。",
            "基诺奖金表详细说明了匹配不同数量的选项的奖金金额，帮助你确定根据抽奖结果可能获得的奖金。"
        ]
    },
    {
        "type": 14,
        "queries": ["我可以用不同的号码玩基诺吗？", "可以选择不同的号码玩基诺吗？", "你可以在基诺中变更你的号码选择吗？"],
        "responses": [
            "是的，你可以在每场游戏中选择不同的号码。你可以根据你的策略或偏好为每次新的抽奖选择不同的号码。",
            "在基诺中，你可以在每次游戏时变更你的号码选择。游戏之间更换号码没有限制。",
            "你可以在每场基诺游戏中选择不同的号码，允许你根据策略或期望的结果调整你的选择。"
        ]
    },
    {
        "type": 14,
        "queries": ["基诺中的下注是如何工作的？", "你能解释一下基诺中的下注吗？", "基诺中的下注过程是什么？"],
        "responses": [
            "在基诺中，下注涉及选择你的号码并进行投注。你的投注金额决定了根据你匹配的号码来计算的潜在奖金。",
            "基诺的下注过程是选择你的号码并决定投注金额。投注的大小会影响根据匹配的号码数量的潜在奖金。",
            "要在基诺中下注，选择你的号码并进行投注。你的奖金取决于你选择的号码和下注金额，较高的投注可能带来更高的奖金。"
        ]
    },
    {
        "type": 14,
        "queries": ["如果我在基诺中匹配所有号码会发生什么？", "你能解释一下如果我匹配所有号码会有什么结果吗？", "匹配所有号码在基诺中会有什么结果？"],
        "responses": [
            "在基诺中匹配所有号码通常会获得大奖，这是游戏中最高的奖金。具体奖金取决于游戏规则和下注金额。",
            "如果你在基诺中匹配所有选择的号码，通常会赢得最高奖项或大奖，这是游戏中最高的奖金。",
            "匹配所有号码在基诺中通常意味着赢得大奖，这是游戏中可能获得的最高奖金，取决于总下注金额。"
        ]
    },
    {
        "type": 14,
        "queries": ["我可以使用基诺策略吗？", "哪些策略适用于基诺？", "我如何提高在基诺中的获胜机会？"],
        "responses": [
            "虽然基诺主要是一个靠运气的游戏，但一些玩家会使用如根据模式或频率选择号码等策略。然而，没有保证获胜的方法。",
            "在基诺中，由于游戏的随机性质，没有策略可以保证获胜。一些玩家会根据个人模式或统计频率选择号码。",
            "基诺策略包括根据个人信念或历史数据选择号码，但由于游戏是随机的，没有确保获胜的方法。"
        ]
    },
]

export const knowledgeBankZh = ()=>{
    return array
}