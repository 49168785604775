import React from 'react'
import { Col, Row } from 'react-bootstrap'

function KenoRu(){
    return <Row>
    <Col lg={2} />
    <Col lg={8}>
        <Row>
            <Col sm={12}><h3>Введение</h3></Col>
            <Col sm={12}>
                <p>Кено — это лотерейная игра, которая играется во многих казино и онлайн-платформах.</p>
                <p>Цель игры состоит в том, чтобы выбрать числа из пула и сравнить их с числами, которые выбрал Кено-автомат.</p>
            </Col>
            <Col sm={12}><h3>Билет Кено</h3></Col>
            <Col sm={12}>
                <p>Чтобы играть в Кено, сначала получите билет Кено. На билете указаны числа от 1 до 80.</p>
                <p>Обычно вы можете выбрать, сколько чисел вы хотите играть (обычно от 1 до 20), и ваш выигрыш будет зависеть от того, сколько чисел вы выбрали.</p>
            </Col>
            <Col sm={12}><h3>Выбор чисел</h3></Col>
            <Col sm={12}>
                <p>На билете Кено отметьте или выберите числа, которые вы хотите играть, окружив или выделив их.</p>
                <p>Количество выбранных вами чисел зависит от конкретных правил игры в Кено, которую вы играете.</p>
            </Col>
            <Col sm={12}><h3>Выбор ставки</h3></Col>
            <Col sm={12}>
                <p>Определитесь с суммой денег, которую вы хотите поставить на свой билет Кено.</p>
                <p>Выплата за совпадение чисел будет зависеть от суммы вашей ставки.</p>
            </Col>
            <Col sm={12}><h3>Количество розыгрышей</h3></Col>
            <Col sm={12}>
                <p>Определите, сколько последовательных игр или розыгрышей вы хотите сыграть с одним и тем же билетом. Вы обычно можете выбрать, чтобы сыграть одну игру или несколько игр подряд.</p>
            </Col>
            <Col sm={12}><h3>Совпадение чисел и выплаты</h3></Col>
            <Col sm={12}>
                <p>По мере того как выбираются числа, сравните их с числами, которые вы выбрали на своем билете Кено.</p>
                <p>Чем больше чисел вы совпадаете, тем выше будет ваш выигрыш, в зависимости от конкретной таблицы выплат для игры, которую вы играете.</p>
            </Col>
        </Row>
    </Col>
    <Col lg={2} />
</Row>
}

export default KenoRu