import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { changePage, changeGame, changeGamePage } from '../../../reducers/page'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { translate } from '../../../translations/translate'

function TermsConditionsRu(props){
    const {settings} = props
    const {lang} = settings
    let dispatch = useDispatch()

    function handleContact(){
        dispatch(changePage('Contact'))
        dispatch(changeGame(null))
        dispatch(changeGamePage(null))
    }

    return <Row id="terms_conditions" className="other_page_container">
        <Col lg={2} />
        <Col lg={8}>
            <p>Пожалуйста, внимательно прочитайте эти Правила и Условия перед использованием услуг нашего казино.</p>
        
            <h3>Принятие Условий</h3>
            <p>Пользуясь или получая доступ к Сервису, вы соглашаетесь соблюдать эти Условия. Если вы не согласны с какой-либо частью Условий, то не имеете права получать доступ к Сервису.</p>
            
            <h3>Право на Участие</h3>
            <p>Для использования нашего Сервиса вам должно быть не менее 18 лет или законный возраст для азартных игр в вашей юрисдикции, в зависимости от того, что выше. Используя Сервис, вы заявляете и гарантируете, что соответствуете установленным критериям.</p>
            
            <h3>Регистрация Аккаунта</h3>
            <p>Для доступа к определенным функциям Сервиса вам может потребоваться создать аккаунт. Вы несете ответственность за сохранность учетных данных своего аккаунта и за все действия, совершаемые под вашим аккаунтом.</p>
            
            <h3>Запрещенные Действия</h3>
            <p>Вы соглашаетесь не заниматься ни одним из следующих запрещенных действий:</p>
            <ul>
                <li>Нарушение любых применимых законов или правил</li>
                <li>Выдача себя за другого человека или организацию или ложное указание или иное искажение своей принадлежности к человеку или организации</li>
                <li>Вмешательство или нарушение работы Сервиса или серверов или сетей, связанных с Сервисом</li>
                <li>Совершение любых видов мошенничества или обманных действий</li>
            </ul>

            <h3>Интеллектуальная Собственность</h3>
            <p>Сервис и его оригинальный контент, функции и функциональность являются и останутся исключительной собственностью BunnyBet и его лицензиаров. Сервис защищен авторскими правами, товарными знаками и другими законами.</p>

            <h3>Внесение Депозитов и Вывод</h3>
            <p>Внесение депозитов и вывод денег подлежит нашим указанным методам оплаты, условиям и временам обработки. Вы несете ответственность за предоставление точной и актуальной информации об оплате.</p>

            <h3>Ответственная Игра</h3>
            <p>Мы продвигаем ответственную игру и предоставляем инструменты и ресурсы, чтобы помочь игрокам управлять своими азартными занятиями. Мы призываем вас устанавливать лимиты депозита, делать перерывы и обращаться за поддержкой, если это необходимо. Игра должна рассматриваться как развлечение, а не как способ заработка денег.</p>

            <h3>Ограничение Ответственности</h3>
            <p>В максимальной степени, допустимой законом, BunnyBet и его аффилированные лица, директора, сотрудники, агенты и поставщики услуг не несут ответственности за прямые, косвенные, случайные, специальные, последующие или взыскательные убытки, включая упущенную выгоду, возникшие в результате или в связи с использованием Сервиса.</p>

            <h3>Индемнизация</h3>
            <p>Вы соглашаетесь компенсировать и защищать BunnyBet и его аффилированные лица, директоров, сотрудников, агентов и поставщиков услуг от любых претензий, требований, ущерба, обязательств и расходов (включая разумные адвокатские издержки), возникших или связанных с вашим использованием Сервиса.</p>

            <h3>Применяемое Право</h3>
            <p>Эти Условия будут регулироваться и толковаться в соответствии с законами страны, в которой вы находитесь. Любые споры, возникающие в связи с этими Условиями, подлежат исключительному юрисдикции судов вашей страны.</p>

            <h3>Изменения в Правилах и Условиях</h3>
            <p>Мы оставляем за собой право в любое время вносить изменения или заменять эти Условия. Самая актуальная версия Условий будет опубликована на нашем веб-сайте. Ваше продолжение использования Сервиса после внесения таких изменений означает ваше согласие с новыми Условиями.</p>
            
            <h3>Свяжитесь с Нами</h3>
            <p>Если у вас возникли вопросы или замечания по поводу этих Правил и Условий, пожалуйста, свяжитесь с нами.</p>
            <br></br>

            <p>Используя наш Сервис, вы подтверждаете, что ознакомились, поняли и согласны соблюдать эти Правила и Условия.</p>
        
            <p id="about_contact" onClick={()=>handleContact()}><FontAwesomeIcon icon={faPaperPlane} />{translate({lang: lang, info: "contact"})}</p>
        </Col>
        <Col lg={2} />
    </Row>
}
export default TermsConditionsRu