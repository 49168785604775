var admin_email = "oanapopescu93@gmail.com"
var word_bank = {
    salon: "Salon",
    about: "Despre",
    questions: "Întrebări",
    terms_cond: "Termeni și condiții",
    policy_privacy: "Politici de confidențialitate",
    contact: "Contact",
    career: "Carieră",
    info: "Info",
    back: "Înapoi",
    bet_type: "Tip de pariu",
    and: "și",
    or: "sau",
    i_agree_to: "Sunt de acord cu",

    // games
    games: "Jocuri",
    roulette: "Rouletă",
    blackjack: "Blackjack",
    slots: "Slots",
    craps: "Craps",
    race: "Curse",
    keno: "Keno",
    poker: "Poker",
    european: "European",
    american: "American",
    reel_3: "3 Role",
    reel_5: "5 Role",
    poker_texas_holdem: "Texas hold'em",
    poker_5_card_draw: "5 Card Draw",

    error: "Eroare",
    all_rights_reserved: "Toate drepturile rezervate",  
    no_career: "Nu există joburi disponibile. Puteți totuși să ne trimiteți CV-ul dumneavoastră.",  

    cookies_modal_title: "Notificări de cookies",
    cookies_modal_text: "Pentru a vă oferi cele mai relevante informații și pentru o performanță optimă a sistemului, noi folosim cookies pentru a colecta informații din activitatea dumneavoastră.",

    sign_in: "Logare",
    sign_up: "Înregistrare",
    signin_error: "User-ul nu există",
    signup_error: "A apărut o eroare la înregistrare. Vă rugăm să trimiteți această eroare la " + admin_email + " sau încercați din nou mai târziu.",
    email_yes_user_no_error: "Acest email a fost deja folosit",
    email_yes_user_yes_error: "User-ul există deja",
    user: "Utilizator",
    password: "Parolă",
    sign_in_email_empty: "Email-ul este gol",
    sign_in_user_empty: "Numele utilizatorului este gol",
    sign_in_pass_empty: "Parola este goală",
    sign_in_email_error: "Email-ul este incorect",
    sign_in_pass_error: "Parola este incorectă",
    signin_forgot_password: "Am uitat parola",  
    subtitle: "Îndrăznește să prinzi iepurele", 
    country: "Țară",
    city: "Oraș",  
    your_streak: "Câte zile la rând ai jucat",

    exit_salon: "Ieși din salon",
    salon_subtitle: "Bine ai venit în salon",
    exit_game: "Ieși din joc",

    donation: "Donații",
    crypto: "Cripto",
    paypal: "Paypal",
    bank: "Bancă",
    crypto_donation_title: "Donați prin Criptomonedă",
    crypto_donation_text: "Vă rugăm să trimiteți donația dvs. în criptomonedă la următoarele adrese. Odată ce donația este trimisă, ar putea dura ceva timp până când este confirmată în rețeaua blockchain.",
    paypal_donation_title: "Donați prin PayPal",
    paypal_donation_text: "Vă rugăm să utilizați următoarele linkuri pentru a face o donație prin PayPal. Odată ce donația este finalizată, ar putea dura ceva timp pentru a fi procesată.",
    bank_donation_title: "Donați prin Transfer Bancar",
    bank_donation_text: "Vă rugăm să trimiteți donația dvs. la următorul cont bancar. Odată ce donația este transferată, ar putea dura ceva timp până când este reflectată în cont.",
    donation_footer_text: "Vă mulțumim pentru generozitatea dvs.!",
    donation_ukraine: "Donație pentru Ucraina",
    donate_with_nowpayments: "Donează cu NOWPayments",

    all: "Tot",
    other: "Altceva",
    title: "Titlu",
    location: "Locație",
    requirements: "Cerințe",
    responsabilities: "Responsabilități",  
    marketing: "Marketing",       

    under_construction: "În construcție",

    no_data: "Nu avem informații disponibile",
    no_bets: "Nu ai plasat niciun pariu",
    no_user: "Nu există utilizator",
    no_money: "Nu ai suficienți morcovi. Poți să cumperi morcovi din Market.",
    no_payment_methods: "Nu există metoda de plată încă",
    no_cart: "Coșul este gol",
    no_history: "Nu ai istoric",
    no_jobs: "Nu există job-uri disponibile. Puteți să ne trimiteți CV-ul dumneavoastră pe email.",
    no_participation_today: "Nu participa astăzi",
    no_order: "Nu există comenzi",
    no_selections: "Selectează măcar un Keno Spot pentru a începe jocul",
    no_match: "Nicio potrivire",
    keno_too_many_selected: "Prea multe puncte selectate",

    isMinor_title: "Verificare vârstă",
    isMinor_text: "Ai peste 18 ani?",
    yes: "Da",
    no: "Nu",
    isMinor_sign: "Nu poți să accesezi acest site pentru că ai sub 18 ani.",

    forgot_password_title: "Ți-ai uitat parola?",
    forgot_password_text: "Introdu adresa ta de email cu care te-ai înregistrat. Noi îți vom trimite parola nouă pe această adresă.",
    send: "Trimite",
    email_send_mailtrap: "E-mailul a fost trimis. Verificați contul dvs. Mailtrap.",
    email_send: "E-mailul a fost trimis.",
    email_no_send: "Emailul nu a fost trimis.",
    subject: "Subiect",
    message: "Mesaj",
    sending: "Trimite...",

    incorrect_email: "Emailul este incorect",
    empty_input_subject: "Câmpul Subiect este gol",
    empty_input_about: "Câmpul despre este gol",
    empty_input_message: "Câmpul Mesaj este gol",
    empty_input_agree: "Nu ai fost de acord cu Termenii și condițiile și Politicile de confidențialitate",
    account_issues: "Probleme cu contul", 
    payments_and_withdrawals: "Plăți și retrageri", 
    game_issues: "Probleme cu jocurile", 
    hiring: "Angajare", 

    market: "Market",
    settings: "Setări",
    how_to_play: "Cum să joci",
    logout: "Logout",
    joined_the_chat: "s-a alăturat chat-ului",
    left_the_chat: "a părăsit chat-ul",
    buy: "Cumpără",
    play: "Joacă",
    play_whack_a_rabbit: "Joacă Whack a rabbit pentru a câștiga morcovi!",

    language: "Limba",
    date_calendar: "Data calendaristică",
    currency: "Monedă",
    theme: "Temă",
    red: "Roșu",
    orange: "Portocaliu",
    yellow: "Galben",
    green: "Verde",
    blue: "Albastru",
    brown: "Maro",
    purple: "Violet",
    black: "Negru",
    grey: "Gri",
    white: "Alb",

    dashboard: "Dashboard",
    choose_profile_pic: "Alege imaginea de profil",
    animal: "Animal",
    carrots: "Morcovi",
    change_username: "Schimbă nume utilizator",
    change_password: "Schimbă parola",
    buy_carrots: "Cumpără morcovi",
    choose: "Alege",
    change: "Schimbă",
    profile_grey: "Pentru a avea această poză trebuie să ai peste 1000 de morcovi",
    empty_input_change_username: "Numele de utilizator nu poate fi gol",
    password_rule01: "Cel puțin o literă majusculă",
    password_rule02: "Cel puțin o literă minusculă",
    password_rule03: "Cel puțin o cifră",
    password_rule04: "Cel puțin un caracter special",
    password_rule05: "Minim opt caractere în lungime",

    //cart"
    value: "Valoare",
    price: "Preț",
    qty: "Cantitate",
    total_price: "Preț total",
    cart: "Coș",
    cart_empty: "Coșul este gol",
    order_summary: "Sumar comandă",
    subtotal: "Subtotal",
    shipping: "Transport",
    promo_code: "Cod de reducere",
    promo_discount: "Redusere",
    total: "Total",
    checkout: "Finalizează comandă",
    apply_coupon: "Aplică cupon",
    remove_all: "Șterge tot",
    continue_shopping: "Continuă cumpărăturile",
    coupon_not_valid: "Coupon-ul tău nu e valid",

    //checkout
    customer_info: "Date client",
    name: "Nume",
    email: "Email",
    phone: "Telefon",
    payment_info: "Plata",
    cash_on_delivery: "Plata ramburs",
    pay_card: "Card credit/debit",
    pay_paypal: "Paypal",
    pay_crypto: "Crypto",
    card_number: "Număr card",
    month: "Lună",
    year: "An",
    cvv: "CVV",
    bitcoin_wallet: "Adresa Bitcoin",
    error_charge: "Ne pare rău, dar nu putem onora comanda din motive tehnice. Vom repara eroarea cât de curând posibil.",
    amount_too_low: "Suma este prea mică.",
    createSource_error: "Eroare la atașarea cardului la client.",
    addNewCard_error: "Eroare la crearea unui token de card.",
    createNewCustomer_error: "Eroare la crearea unui nou client.",
    submit: "Finalizează comandă",
    pay: "Plată",
    min_amount: "Suma minimă",
    fiat_equivalent: "Echivalent în valută",
    your_amount_in_fiat_equivalent: "Suma dumneavoastră în echivalent fiat",
    amount_too_small_transaction: "Suma este prea mică pentru a efectua o tranzacție",
    payment_details: "Detalii de plată",
    payment_methode: "Metodă de plată",
    january: "Ianuarie",
    february: "Februarie",
    march: "Martie",
    april: "Aprilie",
    may: "Mai",
    june: "Iunie",
    july: "Iulie",
    august: "August",
    september: "Septembrie",
    october: "Octombrie",
    november: "Noiembrie",
    december: "Decembrie",
    fill_field: "Vă rugăm să completați acest câmp",
    validate_message_name: "Acesta nu este un nume",
    validate_message_email: "Acesta nu este un email valid",
    validate_message_phone: "Acesta nu este un număr de telefon valid",
    validate_message_country: "Acesta nu este o țară validă",
    validate_message_city: "Acesta nu este un oraș valid",
    validate_message_cardNumber: "Acesta nu este un număr de card valid",
    validate_message_month: "Acesta nu este o lună validă",
    validate_message_year: "Acesta nu este un an valid",
    validate_message_cvv: "Acesta nu este un CVV valid",
    validate_message_bitcoinAddress: "Acesta nu este o adresă Bitcoin validă",
    validate_message_iban: "Aceasta nu este un IBAN",
    continue: "Continuă",
    save: "Salvează",
    payment_success: "Plată reușită",
    payment_success_text: "Ați finalizat plata",
    payment_cancel: "Plata anulată",
    payment_cancel_text: "Ați anulat plata",
    order_info: "Informații despre comandă",
    payment_id: "ID plată",
    date: "Dată",
    description: "Descriere",
    customer_id: "ID client",
    items: "Articole",
    amount: "Sumă",
    requires_payment_method: "Metodă de plată necesară",
    requires_confirmation: "Necesită confirmare",
    requires_action: "Necesită acțiune",
    processing: "Procesare",
    requires_capture: "Necesită captură",
    canceled: "Anulat",
    succeeded: "Reușit",

    // order
    order: "Comanda",
    orders: "Comenzi",  
    order_message: "Comanda dumneavoastră a fost făcută cu succes!",
    order_details: "Detalii comandă",
    customer: "Client",
    payment_method: "Metoda de plată",
    status: "Status",
    status_fullfilled: "Finalizat",
    status_unfullfilled: "Nefinalizat",
    order_link: "Link chitanță",
    order_description: "Descriere comandă",
    order_date: "Data comandă",
    postal_code: "Cod poștal",
    id: "ID",
    method: "Metodă",

    // withdraw
    withdrawal: "Retragere",
    withdraw: "Retrage",
    withdrawals: "Retrageri",
    no_withdrawal: "Fără retrageri",
    not_enough_money_withdrawal: "Nu ai suficienți bani pentru a face o retragere.",
    withdraw_instructions: "Withdraw instructions will come here",
    withdraw_success: "Retragere reușită",
    withdraw_failed: "Retragere eșuată",

    // blackjack
    start: "Începe",
    hit: "Cere o carte",
    stand: "Oprește-te",
    double_down: "Dublează",
    surrender: "Predă-te",
    not_current_player: "Nu este rândul tău",
    hit_explanation: "Luați o altă carte pentru a vă mări mâna.",
    stand_explanation: "Păstrați mâna actuală și terminați tura.",
    double_down_explanation: "Dublați pariul, primiți o altă carte.",
    surrender_explanation: "Renunțați la pariul dvs. pentru a vă termina mâna.",
    table_1: "Masa 01", 
    table_2: "Masa 02", 
    table_3: "Masa 03", 
    table_4: "Masa 04", 
    table_5: "Masa 05", 
    table_6: "Masa 06", 
    table_7: "Masa 07", 
    table_8: "Masa 08", 

    // race
    breed: "Rasă",
    delay: "Întârziere",
    health: "Sănătate",
    bet: "Pariu",
    place: "Loc",
    place_01: "Locul 1",
    place_02: "Locul 2",
    place_03: "Locul 3",

    // keno
    keno_instructions: "Alege până la 10 Keno Spots sau alege Quick Pick (va alege maxim 10 Keno Spots aleatoare)",
    price_per_game: "Preț per joc",
    no_of_games: "Nr. de jocuri",
    quick_pick: "Quick pick",
    keno_prizes: "Premii Keno",
    keno_numbers_played: "Câte nr. ai jucat",
    keno_numbers_matched: "Câte nr. ai ghicit",
    keno_win: "Premiu",
    your_numbers: "Numerele tale",
    lucky_numbers: "Numerele norocoase",

    // slots
    slots_prizes: "Premii la sloturi",

    // poker
    total_pot: "Pot total",
    check: "Check",
    check_explanation: "Nu paria, rămâi în rundă.",
    fold: "Renunță",
    fold_explanation: "Renunță la mâna ta acum.",
    call: "Call",
    call_explanation: "Egalează cea mai mare miză curentă.",
    raise: "Plusare",
    raise_explanation: "Crește miza curentă.",
    showdown: "Confruntare",
    showdown_explanation: "Arată mâinile pentru a determina câștigătorul.",
    draw: "Schimbă",
    draw_explanation: "Schimbă cărțile cu altele noi.",
    cancel: "Anulează",
    cancel_explanation: "Nu dorești să schimbi cărțile.",
    bet_explanation: "Plasează o sumă de pariu inițială.",
    invalid_raise: "Plusare invalidă",
    not_enough_money: "Fonduri insuficiente",

    // results
    results: "Rezultate",
    result: "Rezultat",
    game: "Joc",
    game_type: "Tip joc",
    you_win: "Ai castigat",
    you_lose: "Ai pierdut",

    welcome: "Bine ai venit",
    welcome_gift: "Cadiu de bun-venit",
    welcome_text: "100 de morcovi gratis!",
    prize: "Premiu",
    prizes: "Premii",
    our_casino: "casinoul nostru",
    
    //401
    page_not_found_text: "Ne pare rău, pagina pe care o căutați nu există. Este posibil să fi fost mutată sau ștearsă, sau ați introdus URL-ul incorect.",

    // ChatBot
    anonymous: "Anonim",
    greetings01: "Bună",
    greetings02: "Sunt aici pentru a răspunde la toate întrebările tale despre BunnyBet.",
    error_chatbot: "Nu înțeleg întrebarea ta.",
}

export const wordsRo = (info)=>{
    return word_bank[info]
}