var admin_email = "oanapopescu93@gmail.com"
var word_bank = {
    salon: "Salone",
    about: "Informazioni",
    questions: "Domande",
    terms_cond: "Termini e condizioni",
    policy_privacy: "Politica sulla privacy",
    contact: "Contatti",
    career: "Carriera",
    info: "Info",
    back: "Indietro",
    bet_type: "Tipo di scommessa",
    and: "e",
    or: "o",
    i_agree_to: "Sono d'accordo con",

    // games
    games: "Giochi",
    roulette: "Roulette",
    blackjack: "Blackjack",
    slots: "Slots",
    craps: "Craps",
    race: "Corsa",
    keno: "Keno",
    poker: "Poker",
    european: "Europeo",
    american: "Americano",
    reel_3: "3 Rulli",
    reel_5: "5 Rulli",
    poker_texas_holdem: "Texas hold'em",
    poker_5_card_draw: "5 Card Draw",

    error: "Errore",
    all_rights_reserved: "Tutti i diritti riservati",
    no_career: "Nessun lavoro disponibile. Ma puoi inviarci il tuo CV.",

    cookies_modal_title: "Notifica sui cookie",
    cookies_modal_text: "Per offrirti le informazioni più pertinenti e per una prestazione ottimale del sistema, utilizziamo i cookie che raccolgono informazioni dalla tua attività di gioco.",

    sign_in: "Accedi",
    sign_up: "Registrati",
    signin_error: "L'utente non esiste",
    signup_error: "C'è stato un errore durante la registrazione. Si prega di inviare questo errore a " + admin_email + " o riprovare più tardi.",
    email_yes_user_no_error: "Questa email è già stata utilizzata",
    email_yes_user_yes_error: "L'utente esiste già",
    user: "Utente",
    password: "Password",
    sign_in_email_empty: "Email vuota",
    sign_in_user_empty: "Utente vuoto",
    sign_in_pass_empty: "Password vuota",
    sign_in_email_error: "Email non corretta",
    sign_in_pass_error: "Password non corretta",
    signin_forgot_password: "Ho dimenticato la password",
    subtitle: "Osate catturare il coniglio",
    country: "Paese",
    city: "Città",
    your_streak: "Per quanti giorni di fila hai giocato",

    exit_salon: "Esci dal salone",
    salon_subtitle: "Benvenuto nel salone",
    exit_game: "Esci dal gioco",

    donation: "Donazioni",
    crypto: "Cripto",
    paypal: "Paypal",
    bank: "Banca",
    crypto_donation_title: "Dona tramite Criptovaluta",
    crypto_donation_text: "Si prega di inviare la tua donazione in criptovaluta ai seguenti indirizzi. Una volta inviata la donazione, potrebbe richiedere del tempo per essere confermata sulla rete blockchain.",
    paypal_donation_title: "Dona tramite PayPal",
    paypal_donation_text: "Si prega di utilizzare i seguenti link per effettuare una donazione tramite PayPal. Una volta completata la donazione, potrebbe richiedere del tempo per essere elaborata.",
    bank_donation_title: "Dona tramite Bonifico Bancario",
    bank_donation_text: "Si prega di inviare la tua donazione al seguente conto bancario. Una volta trasferita la donazione, potrebbe richiedere del tempo per essere riflessa sul conto.",
    donation_footer_text: "Grazie per la tua generosità!",
    donation_ukraine: "Donazione per l'Ucraina",
    donate_with_nowpayments: "Dona con NOWPayments",

    all: "Tutto",
    other: "Altro",
    title: "Titolo",
    location: "Posizione",
    requirements: "Requisiti",
    responsabilities: "Responsabilità",
    marketing: "Marketing", 

    under_construction: "In costruzione",

    no_data: "Nessuna informazione",
    no_bets: "Non hai effettuato nessuna scommessa",
    no_user: "Nessun utente trovato",
    no_money: "Non hai abbastanza carote. Puoi acquistarle dal mercato.",
    no_payment_methods: "Ancora nessun metodo di pagamento",
    no_cart: "Carrello vuoto",
    no_history: "Nessuna cronologia",
    no_jobs: "Ancora nessun lavoro disponibile. Ma puoi inviarci il tuo CV.",
    no_participation_today: "Nessuna partecipazione oggi",
    no_order: "Nessun ordine",
    no_selections: "Seleziona almeno un punto Keno per iniziare il gioco",
    no_match: "Nessuna corrispondenza",
    keno_too_many_selected: "Troppi punti selezionati",

    isMinor_title: "Verifica dell'età",
    isMinor_text: "Hai più di 18 anni?",
    yes: "Sì",
    no: "No",
    isMinor_sign: "Non puoi entrare perché sei minorenne.",

    forgot_password_title: "Hai dimenticato la password",
    forgot_password_text: "Inserisci l'indirizzo email utilizzato per la registrazione. Ti invieremo la tua nuova password a quell'indirizzo.",
    send: "Invia",
    email_send_mailtrap: "Email inviata. Controlla il tuo account Mailtrap.",
    email_send: "Email inviata.",
    email_no_send: "Email non inviata",
    subject: "Oggetto",
    message: "Messaggio",
    sending: "Invio in corso...",

    incorrect_email: "L'email non è corretta",
    empty_input_subject: "Il campo oggetto è vuoto",
    empty_input_about: "Il campo informazioni è vuoto",
    empty_input_message: "Il campo messaggio è vuoto",
    empty_input_agree: "Non hai accettato i Termini e condizioni e la Politica sulla privacy",
    account_issues: "Problemi dell'account", 
    payments_and_withdrawals: "Pagamenti e prelievi", 
    game_issues: "Problemi di gioco", 
    hiring: "Assunzione", 

    market: "Mercato",
    settings: "Impostazioni",
    how_to_play: "Come giocare",
    logout: "Esci",
    joined_the_chat: "si è unito alla chat",
    left_the_chat: "ha lasciato la chat",
    buy: "Acquista",
    play: "Gioca",
    play_whack_a_rabbit: "Gioca a Whack a Rabbit per ottenere carote gratuite!",

    language: "Lingua",
    date_calendar: "Data",
    currency: "Valuta",
    theme: "Tema",
    red: "Rosso",
    orange: "Arancione",
    yellow: "Giallo",
    green: "Verde",
    blue: "Blu",
    brown: "Marrone",
    purple: "Viola",
    black: "Nero",
    grey: "Grigio",
    white: "Bianco",

    dashboard: "Dashboard",
    choose_profile_pic: "Scegli immagine del profilo",
    animal: "Animale",
    carrots: "Carote",
    change_username: "Cambia nome utente",
    change_password: "Cambia password",
    buy_carrots: "Acquista carote",
    choose: "Scegli",
    change: "Modifica",
    profile_grey: "Per avere questa immagine devi avere più di 1000 carote",
    empty_input_change_username: "Il nome utente non può essere vuoto",
    password_rule01: "Almeno una lettera maiuscola",
    password_rule02: "Almeno una lettera minuscola",
    password_rule03: "Almeno una cifra",
    password_rule04: "Almeno un carattere speciale",
    password_rule05: "Lunghezza minima di otto caratteri",

    //cart
    value: "Valore",
    price: "Prezzo",
    qty: "Quantità",
    total_price: "Prezzo totale",
    cart: "Carrello",
    cart_empty: "Carrello vuoto",
    order_summary: "Riepilogo ordine",
    subtotal: "Subtotale",
    shipping: "Spedizione",
    promo_code: "Codice sconto",
    promo_discount: "Sconto",
    total: "Totale",
    checkout: "Check-out",
    apply_coupon: "Applica coupon",
    remove_all: "Rimuovi tutto",
    continue_shopping: "Continua lo shopping",
    coupon_not_valid: "Il tuo coupon non è valido",

    //checkout"
    customer_info: "Informazioni cliente",
    name: "Nome",
    email: "Email",
    phone: "Telefono",
    payment_info: "Informazioni di pagamento",
    cash_on_delivery: "Pagamento alla consegna",
    pay_card: "Carta di credito o debito",
    pay_paypal: "PayPal",
    pay_crypto: "Criptovaluta",
    card_number: "Numero della carta",
    month: "Mese",
    year: "Anno",
    cvv: "CVV",
    bitcoin_wallet: "Indirizzo Bitcoin",
    error_charge: "Ci scusiamo, ma non possiamo addebitarti a causa di un errore da parte nostra. Ripareremo questo errore il prima possibile.",
    amount_too_low: "L'importo è troppo basso.",
    createSource_error: "Errore nell'associazione della carta al cliente.",
    addNewCard_error: "Errore nella creazione di un token di carta.",
    createNewCustomer_error: "Errore nella creazione di un nuovo cliente.",
    submit: "Invia",
    pay: "Pagamento",
    min_amount: "Importo minimo",
    fiat_equivalent: "Equivalente in valuta fiduciaria",
    your_amount_in_fiat_equivalent: "Il tuo importo in equivalente fiat",
    amount_too_small_transaction: "L'importo è troppo basso per effettuare una transazione",
    payment_details: "Dettagli di pagamento",
    payment_methode: "Metodo di pagamento",
    january: "Gennaio",
    february: "Febbraio",
    march: "Marzo",
    april: "Aprile",
    may: "Maggio",
    june: "Giugno",
    july: "Luglio",
    august: "Agosto",
    september: "Settembre",
    october: "Ottobre",
    november: "Novembre",
    december: "Dicembre",
    fill_field: "Per favore compila questo campo",
    validate_message_name: "Questo non è un nome",
    validate_message_email: "Questa non è un'email valida",
    validate_message_phone: "Questo non è un numero di telefono valido",
    validate_message_country: "Questo non è un paese valido",
    validate_message_city: "Questa non è una città valida",
    validate_message_cardNumber: "Questo non è un numero di carta valido",
    validate_message_month: "Questo non è un mese valido",
    validate_message_year: "Questo non è un anno valido",
    validate_message_cvv: "Questo non è un CVV valido",
    validate_message_bitcoinAddress: "Questo non è un indirizzo Bitcoin valido",
    validate_message_iban: "Questo non è un IBAN",
    continue: "Continua",
    save: "Salva",
    payment_success: "Pagamento riuscito",
    payment_success_text: "Hai completato il tuo pagamento",
    payment_cancel: "Pagamento annullato",
    payment_cancel_text: "Hai annullato il tuo pagamento",
    order_info: "Informazioni sull'ordine",
    payment_id: "ID di pagamento",
    date: "Data",
    description: "Descrizione",
    customer_id: "ID cliente",
    items: "Articoli",
    amount: "Importo",
    requires_payment_method: "Metodo di pagamento richiesto",
    requires_confirmation: "Richiede conferma",
    requires_action: "Azione richiesta",
    processing: "Elaborazione",
    requires_capture: "Richiede cattura",
    canceled: "Annullato",
    succeeded: "Riuscito",

    // order
    order: "Ordine",
    orders: "Ordini", 
    order_message: "Il tuo ordine è stato effettuato con successo!",
    order_details: "Dettagli dell'ordine",
    customer: "Cliente",
    payment_method: "Metodo di pagamento",
    status: "Stato",
    status_fullfilled: "Evaso",
    status_unfullfilled: "Non evaso",
    order_link: "Link della ricevuta",
    order_description: "Descrizione dell'ordine",
    order_date: "Data dell'ordine",
    postal_code: "Codice postale",
    id: "ID",
    method: "Metodo",

    // withdraw
    withdrawal: "Prelievo",
    withdraw: "Prelevare",
    withdrawals: "Prelievi",
    no_withdrawal: "Nessun prelievo",
    not_enough_money_withdrawal: "Non hai abbastanza soldi per fare un prelievo.",
    withdraw_instructions: "Withdraw instructions will come here",
    withdraw_success: "Prelievo riuscito",
    withdraw_failed: "Prelievo fallito",

    // blackjack
    start: "Inizia",
    hit: "Pesca",
    stand: "Stai",
    double_down: "Raddoppia",
    surrender: "Resa",
    not_current_player: "Non è il tuo turno",
    hit_explanation: "Prendi un'altra carta per aumentare la tua mano.",
    stand_explanation: "Tieni la tua mano attuale e termina il tuo turno.",
    double_down_explanation: "Raddoppia la tua puntata, ricevi un'altra carta.",
    surrender_explanation: "Rinuncia alla tua puntata per terminare la tua mano.",
    table_1: "Tavolo 01", 
    table_2: "Tavolo 02", 
    table_3: "Tavolo 03", 
    table_4: "Tavolo 04", 
    table_5: "Tavolo 05", 
    table_6: "Tavolo 06", 
    table_7: "Tavolo 07", 
    table_8: "Tavolo 08", 

    // race
    breed: "Razza",
    delay: "Ritardo",
    health: "Salute",
    bet: "Scommessa",
    place: "Posizione",
    place_01: "1° posto",
    place_02: "2° posto",
    place_03: "3° posto",

    // Keno
    keno_instructions: "Seleziona fino a 10 punti Keno o scegli Selezione Veloce (verranno scelti casualmente massimo 10 punti Keno per te)",
    price_per_game: "Prezzo per partita",
    no_of_games: "Numero di partite",
    quick_pick: "Selezione veloce",
    keno_prizes: "Premi Keno",
    keno_numbers_played: "Numero di numeri giocati",
    keno_numbers_matched: "Numero di numeri indovinati",
    keno_win: "Vincita",
    your_numbers: "I tuoi numeri",
    lucky_numbers: "Numeri fortunati",

    // slots
    slots_prizes: "Premi delle slot",

    // poker
    total_pot: "Pozzo totale",
    check: "Passa",
    check_explanation: "Non puntare, rimanere nel giro.",
    fold: "Fold",
    fold_explanation: "Abbandona la mano adesso.",
    call: "Chiama",
    call_explanation: "Pareggia la scommessa più alta attuale.",
    raise: "Rilancia",
    raise_explanation: "Aumenta l'importo della scommessa attuale.",
    showdown: "Showdown",
    showdown_explanation: "Mostra le carte per determinare il vincitore.",
    draw: "Cambia",
    draw_explanation: "Scambia le carte con delle nuove.",
    cancel: "Annulla",
    cancel_explanation: "Non vuoi scambiare le carte.",
    bet_explanation: "Piazza un importo di scommessa iniziale.",
    invalid_raise: "Rilancio non valido",
    not_enough_money: "Non abbastanza denaro",

    // results
    results: "Risultati",
    result: "Risultato",
    game: "Gioco",
    game_type: "Tipo di gioco",
    you_win: "Hai vinto",
    you_lose: "Hai perso",

    welcome: "Benvenuto",
    welcome_gift: "Regalo di benvenuto",
    welcome_text: "100 carote gratis!",
    prize: "Premio",
    prizes: "Premi",
    our_casino: "il nostro casinò",
    
    //401
    page_not_found_text: "Spiacente, la pagina che stai cercando non esiste. Potrebbe essere stata spostata o eliminata, oppure potresti aver digitato l'URL in modo errato.",

    // ChatBot
    anonymous: "Anonimo",
    greetings01: "Ciao",
    greetings02: "Sono qui per rispondere a tutte le tue domande su BunnyBet.",
    error_chatbot: "Non capisco la tua domanda.",
}

export const wordsIt = (info)=>{
    return word_bank[info]
}