import React from 'react'
import { Col, Row } from 'react-bootstrap'

function RaceRu(){
    return <Row>
        <Col lg={2} />
        <Col lg={8}>
            <Row>
                <Col sm={12}><h3>Введение</h3></Col>
                <Col sm={12}>
                    <p>Спортивные ставки включают в себя размещение ставок на результаты скачек.</p>
                    <p>Цель - предсказать, какое животное победит или займет призовое место в определенной гонке.</p>
                </Col>
                <Col sm={12}><h3>Типы ставок</h3></Col>
                <Col sm={12}>
                    <ul>
                        <li>Победа: Ставка на конкретное животное, чтобы оно финишировало первым.</li>
                        <li>Место: Ставка на то, что животное займет одно из двух или трех первых мест, в зависимости от конкретных правил.</li>
                        <li>Комбинированная ставка: Ставка, объединяющая ставки на победу и место. Если ваш выбор выигрывает, вы получаете и ставку на победу, и на место. Если ваш выбор занимает место, вы получаете только ставку на место.</li>
                    </ul>
                </Col>
                <Col sm={12}><h3>Понимание коэффициентов</h3></Col>
                <Col sm={12}>
                    <p>Коэффициенты представляют собой выплату, которую вы можете ожидать, если ваша ставка успешна.</p>
                    <p>Коэффициенты могут быть представлены в различных форматах, таких как дробные (например, 5/1), десятичные (например, 6.0) или американские (например, +500).</p>
                </Col>
            </Row>
        </Col>
        <Col lg={2} />
    </Row>
}
export default RaceRu