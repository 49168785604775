import React from 'react'
import { Col, Row } from 'react-bootstrap'

function RouletteRu(){
    return  <Row>
        <Col lg={2} />
        <Col lg={8}>
            <Row>
                <Col sm={12}><h3>Введение</h3></Col>
                <Col sm={12}>
                    <p>Рулетка - популярная казино игра, которая играется на вращающемся колесе.</p>
                    <p>Цель игры - предсказать, куда упадет маленький шар на колесе, тем самым делая ставки на разные числа или группы чисел.</p>
                </Col>
                <Col sm={12}><h3>Рулеточное Колесо</h3></Col>
                <Col sm={12}>
                    <p>Рулеточное колесо состоит из пронумерованных карманов от 0 до 36.</p>
                    <p>Числа чередуются красным и черным, при этом карман 0 окрашен в зеленый цвет.</p>
                    <p>Европейская версия рулетки имеет один карман 0, в то время как американская версия имеет дополнительный карман 00.</p>
                </Col>
                <Col sm={12}><h3>Рулеточный Стол</h3></Col>
                <Col sm={12}>
                    <p>Рулеточный стол - это место, где игроки делают свои ставки.</p>
                    <p>На нем находится сетка, представляющая числа на колесе, а также различные дополнительные варианты ставок.</p>
                    <p>Стол делится на две основные секции: внутренние ставки и внешние ставки.</p>
                </Col>
                <Col sm={12}><h3>Внутренние Ставки</h3></Col>
                <Col sm={12}><p>Ставка на одно число, размещая фишку непосредственно на это число</p></Col>
                <Col sm={12}><h3>Внешние Ставки</h3></Col>
                <Col sm={12}>
                    <p>Внешние ставки размещаются на более крупные группы чисел или характеристики чисел.</p> 
                    <p>Вот некоторые распространенные внешние ставки:</p>
                    <ul>
                        <li>Красное/Черное: Ставка на то, упадет ли шар на красное или черное число.</li>
                        <li>Четное/Нечетное: Ставка на то, упадет ли шар на четное или нечетное число.</li>
                        <li>Малое/Большое: Ставка на то, упадет ли шар на малое (1-18) или большое (19-36) число.</li>
                        <li>Ставка на дюжину: Ставка на группу из 12 чисел, размещая фишку на разделах "1-я 12", "2-я 12" или "3-я 12" на столе.</li>
                    </ul>
                </Col>
                <Col sm={12}><h3>Вращение Колеса</h3></Col>
                <Col sm={12}>
                    <p>После этапа ставок крупье (работник казино, ответственный за игру) вращает рулетку в одном направлении и выпускает маленький шар в противоположном направлении. По мере замедления колеса шар в конечном итоге останавливается в одном из пронумерованных карманов.</p>
                </Col>
                <Col sm={12}><h3>Выигрыш и Проигрыш</h3></Col>
                <Col sm={12}>
                    <p>Если шар упадает на число или группу чисел, на которые вы делали ставку, вы получаете соответствующий выигрыш в соответствии с коэффициентами этой конкретной ставки.</p>
                </Col>
                <Col sm={12}><h3>Выплаты</h3></Col>
                <Col sm={12}>
                    <p>Выплаты варьируются в зависимости от типа сделанной ставки. Например, прямые ставки имеют более высокие выплаты, поскольку они более рискованные.</p>
                </Col>
            </Row>
        </Col>
        <Col lg={2} />
    </Row>
}

export default RouletteRu